import * as THREE from 'three'

// Constants:
const totalSegments = 1024
const zero = new THREE.Vector3(0, 0, 0)
const unitY = new THREE.Vector3(0, 1, 0)
const unitZ = new THREE.Vector3(0, 0, 1)

export default function (container, camera) {
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  const { height, width } = container.getBoundingClientRect()
  const { style } = canvas
  canvas.width = width
  canvas.height = height
  canvas.className = 'petri-ribbon'
  style['user-select'] = 'none'
  style['pointer-events'] = 'none'
  style.position = 'absolute'
  style.width = `${width}px`
  style.height = `${height}px`
  style.top = container.offsetTop
  style.left = container.offsetLeft
  return {
    container,
    camera,
    canvas,
    context,
    node: false,
    slice: false,
    setNode: function (threejsobj) {
      this.node = threejsobj
    },
    setSlice: function (threejsobj) {
      this.slice = threejsobj
    },
    updatePosition: function () {
      const { node, slice } = this
      if (node && slice) {
        const { start, end } = slice
        const {
          height: _height,
          width: _width
        } = this.canvas.getBoundingClientRect()

        // Projected coordinates of node center:
        const scaleContainer = node.parent
        const parentNode = scaleContainer.parent
        const parentRadius = scaleContainer.parent.radius
        const parentWorldScale = new THREE.Vector3()
        const parentWorldPosition = new THREE.Vector3()
        parentWorldScale.setFromMatrixScale(scaleContainer.matrixWorld)
        parentWorldPosition.setFromMatrixPosition(scaleContainer.matrixWorld)
        const nodePosition = node.position
          .clone()
          .multiplyScalar(parentWorldScale.x)
          .add(parentWorldPosition)
        const nodeCoords = this.get2DCoords(nodePosition, this.camera)

        // Calculate world coordinates of center of slice (mideAngle):
        const indexPos = (end + start) / 2
        const posAngle = (indexPos / totalSegments) * 2 * Math.PI
        const slicePosition = unitY
          .clone()
          .multiplyScalar(parentRadius * 1.02)
          .applyAxisAngle(unitZ, -1 * posAngle)

        // Calculate world coordinates of start of slice:
        const startAngle = (start / totalSegments) * 2 * Math.PI
        const startPosition = unitY
          .clone()
          .multiplyScalar(parentRadius * 1.02)
          .applyAxisAngle(unitZ, -1 * startAngle)

        // Calculate world coordinates of end of slice:
        const endAngle = (end / totalSegments) * 2 * Math.PI
        const endPosition = unitY
          .clone()
          .multiplyScalar(parentRadius * 1.02)
          .applyAxisAngle(unitZ, -1 * endAngle)

        const center = zero.clone()
        const radiusHelper = new THREE.Vector3(parentRadius * 1.02, 0, 0)

        // If the node isn't the root node (parent node has a scaleContainer), account for offsets/scaling:
        const parentScaleContainer = parentNode.parent
        if (parentScaleContainer) {
          const worldScale = new THREE.Vector3().setFromMatrixScale(
            parentScaleContainer.matrixWorld
          )
          const worldPosition = new THREE.Vector3().setFromMatrixPosition(
            parentScaleContainer.matrixWorld
          )
          slicePosition
            .add(parentNode.position)
            .multiplyScalar(worldScale.x)
            .add(worldPosition)
          startPosition
            .add(parentNode.position)
            .multiplyScalar(worldScale.x)
            .add(worldPosition)
          endPosition
            .add(parentNode.position)
            .multiplyScalar(worldScale.x)
            .add(worldPosition)
          center
            .add(parentNode.position)
            .multiplyScalar(worldScale.x)
            .add(worldPosition)
          radiusHelper
            .add(parentNode.position)
            .multiplyScalar(worldScale.x)
            .add(worldPosition)
        }

        // Projected coordinates of start of slice:
        const startCoords = this.get2DCoords(startPosition, this.camera)

        // Node radius:
        const r = (node.scale.x / 2) * parentWorldScale.x
        const ribCenter = slicePosition
          .clone()
          .sub(nodePosition)
          .divideScalar(2)
          .add(nodePosition)

        // Projected tangent point to start of slice:
        const vecDS = new THREE.Vector3(
          startPosition.x - nodePosition.x,
          startPosition.y - nodePosition.y,
          0
        )
        const dS = vecDS.length()
        const phiS = Math.PI / 2 - Math.asin(r / dS)
        const unitVecD = vecDS.normalize()
        const tanPositionS = unitVecD
          .applyAxisAngle(unitZ, phiS)
          .multiplyScalar(r)
          .add(new THREE.Vector3(nodePosition.x, nodePosition.y, 0))
        // Lerp start:
        const startTangentCenter = startPosition
          .clone()
          .sub(tanPositionS)
          .divideScalar(2)
          .add(tanPositionS)
        const lerpPointS = ribCenter
          .clone()
          .sub(startTangentCenter)
          .divideScalar(2)
          .add(startTangentCenter)
        const lerpCoordsS = this.get2DCoords(lerpPointS, this.camera)
        // Lerp end:
        const tanCoordsS = this.get2DCoords(tanPositionS, this.camera)

        // Projected tangent point to end of slice:
        const vecDE = new THREE.Vector3(
          endPosition.x - nodePosition.x,
          endPosition.y - nodePosition.y,
          0
        )
        const dE = vecDE.length()
        const phiE = Math.PI / 2 - Math.asin(r / dE)
        const unitVecDE = vecDE.normalize()
        const tanPositionE = unitVecDE
          .applyAxisAngle(unitZ, -1 * phiE)
          .multiplyScalar(r)
          .add(new THREE.Vector3(nodePosition.x, nodePosition.y, 0))
        // Lerp start:
        const endTangentCenter = endPosition
          .clone()
          .sub(tanPositionE)
          .divideScalar(2)
          .add(tanPositionE)
        const lerpPointE = ribCenter
          .clone()
          .sub(endTangentCenter)
          .divideScalar(2)
          .add(endTangentCenter)
        const lerpCoordsE = this.get2DCoords(lerpPointE, this.camera)
        // Lerp end:
        const tanCoordsE = this.get2DCoords(tanPositionE, this.camera)

        // Center and radius of parent node:
        const centerHelper = this.get2DCoords(zero, this.camera)
        const centerCoords = this.get2DCoords(center, this.camera)
        const rds = this.get2DCoords(radiusHelper, this.camera) // radius coordinates of parent with offset origin
        const RDS = rds.x - centerCoords.x // parent radius length in px coordinates space

        // Draw ribbon with all calculated projection points:
        this.context.clearRect(0, 0, _width, _height)

        // // For Debugging:
        // const sliceCoords = this.get2DCoords(startPosition, camera)
        // context.beginPath()
        // context.moveTo(nodeCoords.x, nodeCoords.y)
        // context.lineTo(sliceCoords.x, sliceCoords.y)
        // context.strokeStyle = 'red'
        // context.stroke()

        this.context.beginPath()
        this.context.moveTo(tanCoordsS.x, tanCoordsS.y)
        this.context.quadraticCurveTo(
          lerpCoordsS.x,
          lerpCoordsS.y,
          startCoords.x,
          startCoords.y
        )
        this.context.arc(
          centerCoords.x,
          centerCoords.y,
          RDS,
          startAngle - Math.PI / 2,
          endAngle - Math.PI / 2
        )
        this.context.quadraticCurveTo(
          lerpCoordsE.x,
          lerpCoordsE.y,
          tanCoordsE.x,
          tanCoordsE.y
        )
        const projectedNodeRadiusVec = this.get2DCoords(
          new THREE.Vector3(r, 0, 0),
          this.camera
        )
        const projectedNodeRadius = projectedNodeRadiusVec.x - centerHelper.x
        this.context.arc(
          nodeCoords.x,
          nodeCoords.y,
          projectedNodeRadius,
          0,
          2 * Math.PI
        )
        this.context.fillStyle = 'rgba(255, 255, 255, 0.15)'
        this.context.fill()
      }
    },
    updateCanvas: function () {
      const { style: _style } = this.canvas
      const {
        height: _height,
        width: _width
      } = this.container.getBoundingClientRect()
      this.canvas.width = _width
      this.canvas.height = _height
      _style.width = `${_width}px`
      _style.height = `${_height}px`
      _style.top = this.container.offsetTop
      _style.left = this.container.offsetLeft
    },
    clear: function () {
      const {
        height: _height,
        width: _width
      } = this.canvas.getBoundingClientRect()
      this.context.clearRect(0, 0, _width, _height)
      this.node = null
      this.slice = null
    },
    get2DCoords: function (position, _camera) {
      const vector = position.clone().project(_camera)
      const {
        height: _height,
        width: _width
      } = this.container.getBoundingClientRect()
      vector.x = ((vector.x + 1) / 2) * _width
      vector.y = (-(vector.y - 1) / 2) * _height
      return vector
    },
    destroy: function () {
      this.container = null
      this.camera = null
      this.canvas = null
      this.context = null
      this.node = null
      this.slice = null
      this.nodePos = null
      this.slicePos = null
    }
  }
}
