import * as React from 'react'
import Petri from './Petri'
import { AppContext } from 'Context'
import TweetDialog from 'components/TweetDialog/TweetDialog'

const PetriContainer = () => {
  const { isQueryInProgress, petri, refs, screenSize } = React.useContext(
    AppContext
  )

  const [hidden, setHidden] = React.useState(true)
  const [tweet, setTweet] = React.useState({})

  React.useEffect(() => {
    if (petri.clear) {
      setHidden(true)
    }
  }, [petri.clear])

  const onRecordClick = React.useCallback((record) => {
    setTweet(record)
  }, [])

  const onRecordZoom = React.useCallback((record) => {
    setHidden(!Boolean(record))
  }, [])

  const changingBreadcrumb = React.useCallback(
    (action, currentTimeRange = { start: null, end: null }) => {
      petri.actions.updateBreadcrumb(action, currentTimeRange)
    },
    [petri.actions]
  )

  const onTimeSliderDrag = React.useCallback((type) => {
    if (refs.panelRef?.current?._scrollableContent) {
      const controlsPanel = refs.panelRef.current
      const prevIsPanelOpen =
        controlsPanel.props['data-prev-is-panel-open'].current
      if (type === 'dragging' && controlsPanel.isActive) {
        controlsPanel.dismiss()
      }
      if (type === 'dragEnd' && !controlsPanel.isActive && prevIsPanelOpen) {
        controlsPanel.props.onDismiss()
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <Petri
        aggregation={petri.aggregations}
        breadcrumb={petri.breadcrumb}
        changingBreadcrumb={changingBreadcrumb}
        clear={petri.clear}
        currentTimeRange={petri.currentTimeRange}
        isMobile={screenSize === 'xs'}
        isNormalized={petri.isNormalized}
        isQueryInProgress={isQueryInProgress}
        layers={petri.layers}
        onLastLayer={petri.onLastLayer}
        onRecordClick={onRecordClick}
        onRecordZoom={onRecordZoom}
        onTimeSliderDrag={onTimeSliderDrag}
        population={petri.population}
        timeRange={petri.timeRange}
      />
      <TweetDialog
        hidden={hidden}
        tweet={tweet}
        onDismiss={() => setHidden(true)}
      />
    </>
  )
}

export default PetriContainer
