import * as THREE from 'three'
import { coreLabel } from '../core-3d'
import assetManager from './manager.js'
import * as Types from '../Types'
import twitterLogo from './resources/Twitter_Logo_WhiteOnBlue.svg'

const isDebug = false

export default {
  init,
  get,
  bind,
  show,
  hide,
  attach,
  detach,
  update,
  remove,
  animate,
  setSelected,
  getSelected
}

const container = new THREE.Group()
const labelContainer = new THREE.Group()
labelContainer.position.setZ(9)

const typeName = 'record'

let labels = {}

const cubeDirection = [
  new THREE.Vector3(1, -1, 0),
  new THREE.Vector3(1, 0, -1),
  new THREE.Vector3(0, 1, -1),
  new THREE.Vector3(-1, 1, 0),
  new THREE.Vector3(-1, 0, 1),
  new THREE.Vector3(0, -1, 1)
]

const icosahedronColor = 0xffffff
// const icosahedronColor = 0x4dacff

const icosahedronHoverColor = 0xaaeeff

const totalNodes = 91

const framesToExpand = 10
// const framesToExpand = 20

let dispatchHandler = null

// let frozen = false

let selectedNode = null

function init(dispatchCallback) {
  dispatchHandler = dispatchCallback
  return assetManager.load('Octagon')
  // return assetManager.load('Icosahedron')
}

function get() {
  if (selectedNode) {
    return container.children.filter((el) => el !== selectedNode)
  }
  return container.children
}

function show() {
  isDebug && console.log('show')

  container.visible = true
  labelContainer.visible = true
  container.children.forEach((el) => (el.hittable = true))
}

function hide() {
  isDebug && console.log('hide')

  container.visible = false
  labelContainer.visible = false
  container.children.forEach((el) => {
    el.hittable = false
  })
}

function attach(object) {
  isDebug && console.log('attach', object)
  object.add(container)
  object.add(labelContainer)
}

function detach(object) {
  isDebug && console.log('detach', object)

  object.remove(container)
  object.remove(labelContainer)
}

function bind(data) {
  isDebug && console.log('bind')

  removeLabels(labelContainer)

  if (data.length > 0) {
    // Total leaf nodes to draw:
    let total = Math.min(totalNodes, data.length)
    // Find leaf node positions:
    let zero = new THREE.Vector3(0, 0, 0)
    let cubicPositions = icosahedronPosition(zero, total)
    // Find spiral grid population center start position:
    let center = new THREE.Vector3(0, 0, 0)
    cubicPositions.forEach((vec) => {
      center.add(vec)
      center.divideScalar(total)
    })
    // Determine number of hexagonal rings, used to determine leaf node to parent relative scale:
    let ringCount = spiralCountToRings(total)
    let scale = (1 / ringCount) * 0.5 // reducing scale to make it fit in parent

    // Draw the icosahedral leaf nodes at their respective positions:
    cubicPositions.forEach((pos, ind) => {
      let nodeMesh = container.children[ind]
      let positionSubtractCenter = new THREE.Vector3()
      positionSubtractCenter.subVectors(pos, center)
      if (typeof nodeMesh === 'undefined') {
        // Create and add node to container if it doesn't exist:
        nodeMesh = addNode(positionSubtractCenter, scale, data[ind].data)
        container.add(nodeMesh)
      } else {
        // Else update the node and make visible in case it isn't:
        nodeMesh = updateNode(nodeMesh, positionSubtractCenter, scale)
        nodeMesh.visible = true
      }

      // Set node info (name, size, type, start, etc.):
      nodeMesh.nodeName = data[ind].id

      nodeMesh.nodeData = Object.assign({}, data[ind])
    })

    // Hide all extra nodes:
    if (container.children.length > cubicPositions.length) {
      for (let j = cubicPositions.length; j < container.children.length; j++) {
        container.children[j].visible = false
        container.children[j].hittable = false
      }
    }
  } else {
    hide()
  }

  attachLabels(data, container, labelContainer)
}

function update(data) {
  if (data.length === container.children.length) {
    let hasNewData = false

    for (let index = 0; data.length; index++) {
      const newDataDoesNotExistInScene =
        data[index].id !== container.children[index].nodeName

      if (newDataDoesNotExistInScene) {
        hasNewData = true
      }

      const noNewData = index === data.length - 1 && !hasNewData

      if (hasNewData) {
        break
      } else if (noNewData) {
        return
      }
    }
  }

  // If there is data, update layer:
  if (data.length > 0) {
    container.children.forEach((child) => {
      const newDataDoesNotMatchChild = data.every(
        (record) => record.id !== child.nodeName
      )
      if (newDataDoesNotMatchChild) {
        child.visible = false
        child.hittable = false

        container.children = container.children.filter(
          (val) => val.nodeName !== child.nodeName
        )

        if (labels[child.nodeName]) {
          delete labels[child.nodeName]
        }
        labelContainer.children = labelContainer.children.filter(
          (label) => label.labelName !== child.nodeName
        )
      }
    })

    // Total leaf nodes to draw:
    let total = Math.min(totalNodes, data.length)
    // Find leaf node positions:
    let zero = new THREE.Vector3(0, 0, 0)
    let cubicPositions = icosahedronPosition(zero, total)
    // Find spiral grid population center start position:
    let center = new THREE.Vector3(0, 0, 0)
    cubicPositions.forEach((vec) => {
      center.add(vec)
      center.divideScalar(total)
    })
    // Determine number of hexagonal rings, used to determine leaf node to parent relative scale:
    let ringCount = spiralCountToRings(total)
    let scale = (1 / ringCount) * 0.5 // reducing scale to make it fit in parent
    // Current number of child nodes:
    let currentNodes = container.children
    let nodeCount = currentNodes.length
    // Draw the icosahedral leaf nodes at their respective positions:
    for (let i = 0; i < cubicPositions.length; ++i) {
      let nodeMesh = null
      let positionSubtractCenter = new THREE.Vector3()
      positionSubtractCenter.subVectors(cubicPositions[i], center)
      if (i < nodeCount) {
        // Update the nodes that exist:
        nodeMesh = currentNodes[i]
        nodeMesh.defaultScale = scale
        nodeMesh.scale.setScalar(scale)
        let cubeOffset = cubeToOffset(positionSubtractCenter)
        let cartesian = offsetToCartesian(cubeOffset)
        cartesian.multiplyScalar(scale)
        nodeMesh.position.set(cartesian.x, cartesian.y, 0)
      } else {
        // Draw new if a node doesn't yet exist:
        nodeMesh = addNode(positionSubtractCenter, scale)
        container.add(nodeMesh)
      }
      // Set node info (name, size, type, start, etc.):
      nodeMesh.nodeName = data[i].id
      nodeMesh.nodeData = Object.assign({}, data[i])
      // Make sure node is visible:
      nodeMesh.visible = true
    }
    // Attach labels to the currently shown nodes:
    attachLabels(data, container, labelContainer)
    // If no data, hide layer:
  } else if (container.children.length) {
    removeLabels(labelContainer)
    removeChildren()
  }
}

function remove() {
  isDebug && console.log('remove')

  container.children.forEach((child) => {
    // TODO: Will need to move dispose of geometry, material, and texture to assetManager since
    // they are shared and will get recreated on the next render if it's still used elsewhere:
    // https://github.com/mrdoob/three.js/issues/5175
    child.dispose()
    child.geometry.dispose()
    child.material.dispose()
  })
  container.children.length = 0
}

function removeChildren() {
  container.children.forEach((child) => {
    child.visible = false
    child.hittable = false
  })
  container.children = []
  container.children.length = 0
}

function animate() {
  let currentScale
  // Icosahedral leaf node initial expansion animation (uses frame locking...):
  // Boolean scope animation flag:
  // let expanded = true
  container.children.forEach((child) => {
    if (child.expandFrames > 0) {
      child.scale.addScalar(child.expandPerFrame)
      child.expandFrames--
      currentScale = child.scale
      // If a child still needs expanding, scope animation is not frozen:
      // expanded = false
    } else {
      currentScale = child.scale
      child.scale.setScalar(child.defaultScale)
    }
  })
  // If all children have expanded, animation is frozen, user interaction is allowed:
  // if (expanded) {
  //   frozen = true
  // }

  labelContainer.children.forEach((label) => {
    if (currentScale) {
      label.scale.copy(currentScale)
      label.scale.divideScalar(4)
    }
  })

  // Keep the selected node highlighted:
  if (selectedNode) {
    selectedNode.highlight()
  }
}

function setSelected(obj) {
  isDebug && console.log('setSelected', obj)

  // If there was a previously selected node, unhighlight it:
  if (selectedNode) {
    selectedNode.unhighlight()
    const selectedNodeLabel = getSelectedLabel()
    selectedNodeLabel.visible = true
  }
  // If obj is a record node, highlight it:
  if (obj && typeof obj === 'object' && obj.name === typeName) {
    selectedNode = obj
    const selectedNodeLabel = getSelectedLabel()
    selectedNodeLabel.visible = false
    selectedNode.highlight()
  } else {
    selectedNode = null
  }
}

function getSelected() {
  if (selectedNode) {
    return selectedNode
  }
  return null
}

function getSelectedLabel() {
  if (selectedNode) {
    return labels[selectedNode.nodeName]
  }
  return null
}

// Private:
function addNode(cubicPosition, scale, data) {
  // Determine leaf node grid position:
  let cubeOffset = cubeToOffset(cubicPosition)
  let cartesian = offsetToCartesian(cubeOffset)

  // Build Node Object3D:
  // let geo = assetManager.get('IcosahedronGeometry')
  // let mat = assetManager.get('IcosahedronMaterial').clone()

  // let geo = assetManager.get('OctagonGeometry')
  // let mat = assetManager.get('OctagonMaterial').clone()
  let geo = new THREE.CircleGeometry(0.494, 6, 11)
  let mat = new THREE.MeshBasicMaterial({ color: 0xffffff })

  mat.map = new THREE.TextureLoader().load(twitterLogo)

  let nodeMesh = new THREE.Mesh(geo, mat)

  nodeMesh.name = typeName

  // Non-three.js parameter:
  nodeMesh.hittable = true
  nodeMesh.shouldAnimate = true
  nodeMesh.animatingCount = 0
  nodeMesh.expandFrames = framesToExpand
  nodeMesh.expandPerFrame = scale / framesToExpand

  // Set up three.js params:
  // Set up initial scaling:
  nodeMesh.scale.setScalar(0.01)
  // nodeMesh.rotation.set(Math.PI/3, Math.PI/3, Math.PI/3)

  // Slider scaling properties:
  nodeMesh.shouldScale = true
  nodeMesh.defaultScale = scale

  // Slider scaling methods:
  nodeMesh.updateScale = () => {}
  nodeMesh.resetScale = () => {
    if (this.shouldScale) {
      this.scale.setScalar(this.defaultScale)
      // Scale label:
      // this.label.scale.copy(this.scale).divideScalar(4)
      this.visible = true
    }
  }
  nodeMesh.onClick = function () {
    if (dispatchHandler && typeof dispatchHandler === 'function') {
      dispatchHandler({
        type: Types.RECORD_LAYER_ON_CLICK,
        payload: this.nodeData
      })
    }
  }
  // Select/deselect handlers:
  nodeMesh.onSelect = function () {
    if (dispatchHandler && typeof dispatchHandler === 'function') {
      dispatchHandler({
        type: Types.RECORD_LAYER_ON_HOVER,
        payload: this.nodeData
      })
    }
    this.highlight()
  }
  nodeMesh.onDeselect = function () {
    if (dispatchHandler && typeof dispatchHandler === 'function') {
      dispatchHandler({
        type: Types.RECORD_LAYER_OFF_HOVER,
        payload: this.nodeData
      })
    }
    this.unhighlight()
  }
  nodeMesh.highlight = function () {
    this.material.color.set(icosahedronHoverColor)
  }
  nodeMesh.unhighlight = function () {
    this.material.color.set(icosahedronColor)
  }

  // Place leaf node in proper grid position:
  // cartesian.multiplyScalar(scale * 1.1)
  cartesian.multiplyScalar(scale)
  nodeMesh.position.set(cartesian.x, cartesian.y, 0)

  return nodeMesh
}

function updateNode(nodeMesh, cubicPosition, scale) {
  isDebug && console.log('updateNode')
  // Determine leaf node grid position:
  let cubeOffset = cubeToOffset(cubicPosition)
  let cartesian = offsetToCartesian(cubeOffset)

  // Non-three.js parameter:
  nodeMesh.shouldAnimate = true
  nodeMesh.animatingCount = 0
  nodeMesh.expandFrames = framesToExpand
  nodeMesh.expandPerFrame = scale / framesToExpand

  // Set up three.js params:
  nodeMesh.scale.setScalar(0.01)

  // Slider scaling properties:
  nodeMesh.shouldScale = true
  nodeMesh.defaultScale = scale

  // Place leaf node in proper grid position:
  cartesian.multiplyScalar(scale)
  nodeMesh.position.set(cartesian.x, cartesian.y, 0)

  return nodeMesh
}

function attachLabels(data, _container, _labelContainer) {
  isDebug && console.log('attachLabels')
  if (_labelContainer.children.length > _container.children.length) {
    removeLabels(_labelContainer)
  }
  data.forEach((datum, ind) => {
    if (ind < totalNodes) {
      let label = labels[datum.id]
      const child = _container.children[ind]

      child.visible = false
      child.hittable = false

      if (typeof label === 'undefined') {
        coreLabel.addLabel({
          id: datum.id,
          text: datum.text,
          fontSize: 64,
          fillColor: '#FFFFFF'
        })
        label = coreLabel.getLabel(datum.id)
        label.labelName = datum.id
        labels[datum.id] = label
        label.visible = false
      }
      let newScale = new THREE.Vector3(1, 1, 1)
      newScale.copy(child.scale)
      label.scale.copy(newScale.divideScalar(3))
      label.position.copy(child.position)
      label.quaternion.copy(child.quaternion)

      // Add record image
      const loader = new THREE.TextureLoader()
      const onLoad = (imageBitmap) => {
        const texture = imageBitmap
        texture.minFilter = THREE.LinearFilter
        child.material = new THREE.MeshBasicMaterial({
          map: texture,
          color: 0xffffff
        })
        child.visible = true
        child.hittable = true
        label.visible = true
      }
      loader.load(
        datum.data?.profileImageUrl || twitterLogo,
        onLoad,
        undefined,
        () => loader.load(twitterLogo, onLoad)
      )

      // Add label to parent labelContainer:
      _labelContainer.add(label)
    }
  })

  _labelContainer.scale.copy(_container.scale)
  _labelContainer.position.copy(_container.position)

  return _labelContainer
}

function removeLabels(_labelContainer) {
  labels = {}
  _labelContainer.children.forEach((child) => {
    child.visible = false
  })
  _labelContainer.children = []
  _labelContainer.children.length = 0
}

// Icosahedron helper functions:
function icosahedronPosition(center, limit) {
  // Determine icosahedral position based on population count and center position:
  let results = []
  results.push(center)
  let count = 1
  let ring = 1
  while (count < limit) {
    let positions = cubeRing(center, ring++, limit - count)
    positions.forEach((position) => {
      results.push(position)
    })
    count += positions.length
  }
  return results
}

function cubeRing(center, radius, max) {
  let results = []
  let cube = new THREE.Vector3()
  let scaledDirection = new THREE.Vector3()
  scaledDirection.copy(cubeDirection[4])
  scaledDirection.multiplyScalar(radius)
  cube.addVectors(center, scaledDirection)
  for (let i = 0; i < 6; ++i) {
    for (let j = 0; j < radius; ++j) {
      if (max === 0) {
        return results
      }
      results.push(cube)
      cube = cubeNeighbor(cube, i)
      max--
    }
  }
  return results
}

function cubeNeighbor(cube, direction) {
  let neighbor = new THREE.Vector3()
  neighbor.addVectors(cube, cubeDirection[direction])
  return neighbor
}

function spiralCountToRings(count) {
  // Determine number of sprials given number of icosahedrons:
  if (count === 0) {
    return 0
  }
  let n = 1
  let delta = 6
  count--
  while (count > 0) {
    count -= delta
    delta += 6
    n++
  }
  return n
}

function cubeToOffset(cubicPosition) {
  return new THREE.Vector3(
    cubicPosition.x + (cubicPosition.z + ((cubicPosition.z | 0) & 1)) / 2,
    cubicPosition.z,
    0
  )
}

function offsetToCartesian(offset) {
  let x = ((offset.y | 0) & 1) === 0 ? offset.x : offset.x - 0.5
  x *= 0.87
  // let y = offset.y * (0.75 / (Math.sqrt(3)/2))
  let y = offset.y * (0.53 / (Math.sqrt(2) / 2))
  return new THREE.Vector3(x, y, 0)
}
