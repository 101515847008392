import { buildNewSprite } from './Petri.utils'
import THREE from 'lib/threejs'
import histogramTicks from './assets/histogramticks.svg'

// Constants:
const isDebug = false
// const pinsCount = 100
const pinsCount = 125
const parentRadius = 0.5
const paddingPercent = 0.06
const diameter = parentRadius * (1 + 2 * paddingPercent)
const histogramContainer = new THREE.Group()
histogramContainer.position.setZ(5)
histogramContainer.name = 'histogram'
histogramContainer.renderOrder = 1

// References and data-related parameters:
let max = 0
let histogramData = null
let histogramTexture = null
let parentObject = null
let pinsExpanded = false
let bucketsToExpand = 0

export default {
  init,
  bind,
  show,
  hide,
  attach,
  detach,
  create,
  animate,
  reset,
  setUp
  // update
}

function init() {
  return loadImage(histogramTicks).then((img) => {
    // Create the texture:
    const width = img.width
    const height = img.height
    const canvasElement = document.createElement('canvas')
    const ctx = canvasElement.getContext('2d')
    canvasElement.width = width
    canvasElement.height = height
    ctx.rect(0, 0, width, height)
    ctx.fillStyle = ctx.createPattern(img, 'no-repeat')
    ctx.fill()
    histogramTexture = new THREE.Texture(canvasElement, THREE.UVMapping)
    histogramTexture.repeat.set(1 / width, 1 / height)
    histogramTexture.needsUpdate = true
    new THREE.Mesh(
      new THREE.PlaneGeometry(1, 1),
      new THREE.MeshBasicMaterial({
        map: histogramTexture,
        polygonOffset: true,
        polygonOffsetUnits: 1,
        polygonOffsetFactor: -1
      })
    )
    // Initialize histogram sprites:
    reset()
  })
}

function bind(data) {
  isDebug && console.log('bind')
  histogramData = data
}

function show() {
  histogramContainer.visible = true
}

function hide() {
  histogramContainer.visible = false
}

function attach(threeJsObj) {
  parentObject = threeJsObj
  parentObject.add(histogramContainer)
}

function detach() {
  if (parentObject) {
    parentObject.remove(histogramContainer)
    parentObject = null
  }
}

function create() {
  isDebug && console.log('create')
  max = histogramData.reduce((acc, val) => (acc > val ? acc : val), 0)
  bucketsToExpand = 0
  pinsExpanded = false
  // Create individual histograms:
  histogramData.forEach((val, index) => {
    drawBucket(index, val)
  })
}

function animate() {
  // If all buckets haven't yet expanded:
  if (!pinsExpanded && parentObject) {
    const buckets = histogramContainer.children
    bucketsToExpand += 5
    const toExpand = buckets.filter((el, ind) => ind < bucketsToExpand)
    // Then expand them:
    toExpand.forEach((bucket) => {
      bucket.visible = true
      if (!bucket.pinsExpanded) {
        if (bucket.numPins < bucket.maxPins) {
          bucket.numPins++
        } else if (bucket.numPins === bucket.maxPins) {
          bucket.pinsExpanded = true
        }
        adjustBucketHeight(
          bucket,
          bucket.mappedValue,
          bucket.numPins / bucket.maxPins
        )
      }
    })
    // Flag as true once all histograms are full sized:
    pinsExpanded = buckets.reduce((acc, val) => val.pinsExpanded && acc, true)
  }
}

function reset() {
  isDebug && console.log('reset')
  let totalPins = 0
  max = 30
  while (totalPins <= pinsCount) {
    drawBucket(totalPins, 1)
    totalPins++
  }
}

function setUp(parentNode, data) {
  if (parentNode && data.length) {
    attach(parentNode)
    show()
    bind(data)
    create()
  }
}

// Private:
function drawBucket(index, value) {
  // const scalar = 45
  const scalar = 37.5

  let bucketSprite = histogramContainer.children[index]
  // Create histogram if it doesn't exist
  if (!bucketSprite) {
    // Create, scale, and position histogram:
    bucketSprite = buildNewSprite(histogramTexture, 0, 128, 128, 128, 0)
    // Angular position:
    const angle = (index / pinsCount) * 2 * Math.PI
    const location = new THREE.Vector3(
      Math.sin(angle),
      Math.cos(angle),
      0
    ).multiplyScalar(diameter)

    bucketSprite.position.copy(location)
    bucketSprite.rotation.z = -1 * angle
    const newSpriteScale = new THREE.Vector3(1, 1, 1)
    // newSpriteScale.divideScalar(30)
    newSpriteScale.divideScalar(scalar)
    // newSpriteScale.divideScalar(15) // looks better but needs to scale to 50 histograms
    bucketSprite.scale.copy(newSpriteScale)
    histogramContainer.add(bucketSprite)
  }
  bucketSprite.visible = false
  // Relative height of histogram:
  let mappedValue = value / max
  // If relative mappedValue is to low it will not render the histogram marker
  // 0.03 is the lowest visible value
  if (mappedValue < 0.03 && value !== 0) {
    mappedValue = 0.03
  }

  bucketSprite.mappedValue = mappedValue
  // bucketSprite.mappedValue = value / max
  // Initial number of pins (out of 30):
  bucketSprite.numPins = 0
  // Total number of pins for histogram:
  // bucketSprite.maxPins = Math.floor(bucketSprite.mappedValue * 30)
  bucketSprite.maxPins = Math.floor(bucketSprite.mappedValue * scalar)
  // Set expanded to false:
  bucketSprite.pinsExpanded = false
}

function adjustBucketHeight(bucket, height, ratio) {
  bucket.geometry.vertices[0].y = height * ratio - 1 / 2
  bucket.geometry.vertices[3].y = height * ratio - 1 / 2
  bucket.geometry.faceVertexUvs[0][0][0].y = 128 * height * ratio
  bucket.geometry.faceVertexUvs[0][1][1].y = 128 * height * ratio
  bucket.geometry.faceVertexUvs[0][1][2].y = 128 * height * ratio
  bucket.geometry.verticesNeedUpdate = true
  bucket.geometry.uvsNeedUpdate = true
}

function loadImage(url) {
  let image = new Image()
  image.src = url
  return new Promise((resolve, reject) => {
    image.onload = () => {
      resolve(image)
    }
    image.onerror = (err) => {
      reject(err)
    }
  })
}
