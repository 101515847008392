import React from 'react'
import Body from 'components/Body'
import Data from './Data'
import Header from 'components/Header'
import Footer from 'components/Footer'
import { Customizer } from 'office-ui-fabric-react'
import { customizations } from 'styles/fabric'
import './styles/styles.css'

function App() {
  return (
    <Customizer {...customizations}>
      <Data>
        <Header />
        <Body />
        <Footer />
      </Data>
    </Customizer>
  )
}

export default App
