import * as React from 'react'
import { mergeStyleSets } from '@uifabric/styling'
import Petri from 'components/Petri/Petri.container'
import TutorialContainer from 'components/Tutorial/TutorialContainer'

const styles = mergeStyleSets({
  body: {
    height: '100%'
  }
})

const Body = () => {
  return (
    <div className={styles.body}>
      <Petri />
      <TutorialContainer />
    </div>
  )
}

export default Body
