import * as THREE from 'three'
// import CORE3D from './index'

// Not sure if hoverStyles needs to stay here

// eslint-disable-next-line no-unused-vars
// let hoverStyles = {}
let pickedObject = null
let position = new THREE.Vector2(-1, -1)
let rayCaster = new THREE.Raycaster()

function setPosition(event, size, external) {
  let x = position.x
  let y = position.y

  if (typeof event.touches !== 'undefined' && event.touches.length > 0) {
    x = (event.touches[0].pageX / size.width) * 2 - 1
    y = -(event.touches[0].pageY / size.height) * 2 + 1
  } else if (
    typeof event.offsetX !== 'undefined' &&
    typeof event.offsetY !== 'undefined'
  ) {
    x = (event.offsetX / size.width) * 2 - 1
    y = -(event.offsetY / size.height) * 2 + 1
  }

  // If this is being called by a method external to petri, hard code the position to be WAY outside of even the
  // largest sphere:
  if (external) {
    x = 1000.0
    y = 1000.0
  }

  position = new THREE.Vector2(x, y)
}

function getPosition() {
  return position
}

function getPickedObject() {
  return pickedObject
}

// function setHoverStyles(styles) {
//   // TODO: Need some sane way to know which hover texture goes with which mesh:
//   const hoverTexture = CORE3D.textures()['sphereHoverTexture']
//
//   // The following defines the material properties that will change on the
//   // object when the mouse is hovering over that object:
//   const defaultHoverStyles = {
//     shininess: 15,
//     envMap: hoverTexture
//   }
//   if (typeof styles === 'object') {
//     hoverStyles = Object.assign(defaultHoverStyles, styles)
//   } else {
//     hoverStyles = defaultHoverStyles
//   }
// }

function runHitTest(camera, intersectList) {
  rayCaster.setFromCamera(position, camera)
  const intersects = rayCaster.intersectObjects(intersectList)

  let hitObjects = []
  let hitInfo = {}
  Object.keys(intersects).forEach((key) => {
    const hit = intersects[key].object

    // TODO: Add "hit at this level" test of some kind
    if (typeof hit.hittable !== 'undefined' && hit.hittable) {
      hitInfo.object = hit
      hitInfo.distance = intersects[key].distance
      hitObjects.push(hitInfo)
    }
    hitInfo = {}
  })
  if (hitObjects.length === 0) {
    objectDeselect(pickedObject)
    pickedObject = null
  } else {
    // Only worry about first (closest) hit:
    if (pickedObject !== hitObjects[0].object) {
      objectDeselect(pickedObject)
      pickedObject = hitObjects[0].object
      objectSelect(pickedObject)
    }
  }
}

function objectSelect(theObject) {
  if (theObject) {
    if (typeof theObject.onSelect !== 'undefined') {
      theObject.onSelect()
    }
  }
}

function objectDeselect(theObject) {
  if (theObject) {
    if (typeof theObject.onDeselect !== 'undefined') {
      theObject.onDeselect()
    }
  }
}

export default {
  setPosition,
  getPosition,
  getPickedObject,
  // setHoverStyles,
  runHitTest,
  objectSelect,
  objectDeselect
}
