import React from 'react'
import PropTypes from 'prop-types'
import { mergeStyleSets } from '@uifabric/styling'
import { transition } from 'styles/constants'
import { IconButton } from 'office-ui-fabric-react'

const styles = mergeStyleSets({
  resetButton: {
    transition: transition.opacity,
    display: 'none'
  },
  resetButtonContainer: {
    position: 'absolute',
    height: 28,
    width: 28,
    top: 0,
    left: 0,
    zIndex: 10002
  },
  timeRangeLabel: {
    height: 28,
    display: 'flex',
    pointerEvents: 'none',
    userSelect: 'none',
    backgroundColor: 'var(--darkOverlay)',
    borderRadius: '16px 16px 16px 16px',
    padding: '7px 8px',
    position: 'fixed',
    transition: `${transition.transformShortest}, ${transition.opacityShort}, ${transition.padding}`,
    maxWidth: '50%',
    zIndex: 10000,
    backdropFilter: 'blur(2px)'
  },
  timeRangeText: {
    pointerEvents: 'none',
    fontFamily: 'Roboto',
    fontSize: 12,
    WebkitFontSmoothing: 'antialiased',
    textAlign: 'center',
    maxWidth: '50%',
    color: 'var(--white)',
    whiteSpace: 'nowrap'
  }
})
const TimeRange = (props) => {
  const style = {
    opacity: props.resetButtonDisabled ? 0 : 1
  }

  return (
    <>
      <ResetButton
        componentRef={props.resetButtonRef}
        onClick={props.resetTimeRange}
        show={props.resetButtonDisabled}
      />
      <div
        ref={props.midResetButtonContainerRef}
        className={styles.resetButtonContainer}
      />
      <TimeLabel componentRef={props.startRef} style={style} />
      <TimeLabel componentRef={props.endRef} style={style} />
    </>
  )
}

TimeRange.defaultProps = {
  filterField: ''
}

TimeRange.propTypes = {
  startRef: PropTypes.any,
  endRef: PropTypes.any,
  filterField: PropTypes.string,
  resetButtonDisabled: PropTypes.bool,
  resetTimeRange: PropTypes.func
}

export default TimeRange

const TimeLabel = (props) => {
  return (
    <div
      ref={props.componentRef}
      className={styles.timeRangeLabel}
      style={props.style}
    >
      <div className={styles.resetButtonContainer} />
      <span className={styles.timeRangeText} />
    </div>
  )
}

const ResetButton = (props) => {
  return (
    <div
      className={styles.resetButton}
      ref={props.componentRef}
      style={{
        opacity: props.show ? 0 : 1
      }}
    >
      <IconButton
        iconProps={{ iconName: 'Refresh' }}
        onClick={props.onClick}
        title="Reset Time"
        ariaLabel="Reset Time"
        styles={{
          root: {
            backgroundColor: 'var(--orange)',
            borderRadius: '50%',
            color: 'white'
          }
        }}
      />
    </div>
  )
}
