import MultiBackend, { DndProvider } from 'react-dnd-multi-backend'
import HTML5toTouch from 'react-dnd-multi-backend/dist/esm/HTML5toTouch' // or any other pipeline
import * as React from 'react'
import { AppContext, initialState, configurations } from 'Context'
import * as PetriTypes from 'components/Petri/lib/Types'
import * as api from 'api'
import * as Types from 'lib/types'
import { getScreenSize } from 'lib/utils'

type Props = {
  children: React.ReactNodeArray
}
class Data extends React.Component<Props, Types.Context> {
  constructor(props: Props) {
    super(props)
    this.state = {
      ...initialState
    }
  }
  async componentDidMount() {
    window.addEventListener('resize', this.setScreenSize)
    this.setScreenSize()
    await this.getPetriData({})
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setScreenSize)
  }

  getPetriData = async ({
    breadcrumb = this.state.petri.breadcrumb,
    layers = this.state.petri.layers,
    timeFilter = this.state.petri.timeFilter,
    currentTimeRange = { start: null, end: null },
    clear = false
  }) => {
    const { state } = this
    const petri = await api.getPetriData({
      breadcrumb,
      layers,
      timeFilter,
      currentTimeRange,
      esIndex: state.configuration.esIndex,
      initialTimeRange: state.configuration.initialTimeRange
    })

    this.setState({
      isQueryInProgress: false,
      petri: {
        ...this.state.petri,
        ...petri,
        breadcrumb,
        layers,
        timeFilter,
        clear
      }
    })
  }

  updateBreadcrumb = async (
    action: any,
    currentTimeRange: Types.CurrentTimeRange
  ) => {
    const { state } = this
    let breadcrumb = [...state.petri.breadcrumb]

    if (action.type === PetriTypes.BREADCRUMB_POP) {
      breadcrumb.pop()
    } else if (action.type === PetriTypes.BREADCRUMB_REPLACE) {
      breadcrumb.pop()
      breadcrumb.push(action.payload.key)
    } else if (action.type === PetriTypes.BREADCRUMB_JUMP) {
      const index = action.payload
      breadcrumb.splice(index + 1, breadcrumb.length - index - 1)
    } else {
      breadcrumb.push(action.payload.key)
    }

    // @ts-ignore
    return this.getPetriData({ breadcrumb, currentTimeRange })
  }

  updateNormalization = async (isNormalized: boolean) => {
    this.setState({ petri: { ...this.state.petri, isNormalized } })
  }

  updateLayers = async (layers: string[]) => {
    const { state } = this
    let breadcrumb = state.petri.breadcrumb
    if (state.petri.breadcrumb.length > 1) {
      breadcrumb = ['root']
      return this.setState(
        {
          isQueryInProgress: true,
          petri: {
            ...state.petri,
            currentTimeRange: { start: null, end: null },
            clear: true,
            breadcrumb
          }
        },
        () => this.setLayers(layers)
      )
    } else if (layers.length === 0 || state.petri.onLastLayer) {
      return this.setState(
        {
          isQueryInProgress: true,
          petri: {
            ...state.petri,
            currentTimeRange: { start: null, end: null },
            clear: true
          }
        },
        () => this.setLayers(layers)
      )
    } else {
      this.setState({ isQueryInProgress: true })

      return this.setLayers(layers)
    }
  }

  updateTimeFilter = async (timeFilter: string) => {
    const { state } = this
    let breadcrumb = [...state.petri.breadcrumb]
    if (breadcrumb.length > 1) {
      breadcrumb = ['root']
    }
    return this.setState(
      {
        isQueryInProgress: true,
        petri: { ...state.petri, breadcrumb, clear: true }
      },
      () => this.getPetriData({ timeFilter })
    )
  }

  updateCurrentTimeRange = async (currentTimeRange: Types.CurrentTimeRange) => {
    this.setState({ petri: { ...this.state.petri, currentTimeRange } })
  }

  setLayers = (layers: string[]) => {
    const updatedLayers = ['root']
    if (layers.length) {
      updatedLayers.push(...layers)
    }
    return this.getPetriData({ layers: updatedLayers, clear: false })
  }

  setScreenSize = () => {
    const width = window.innerWidth
    const height = window.innerHeight
    const currentWindowDimensions = this.state.windowDimensions
    let currentScreenSize = getScreenSize({ currentWindowDimensions })

    if (currentScreenSize !== this.state.screenSize) {
      this.setState({ screenSize: currentScreenSize })
    }
    if (
      width !== currentWindowDimensions.width ||
      height !== currentWindowDimensions.height
    ) {
      this.setState({ windowDimensions: { height, width } })
    }
  }

  updateConfiguration = (key: string) => {
    const configuration = configurations[key]

    if (configuration && key !== this.state.configuration.key) {
      this.setState({
        ...this.state,
        configuration,
        layerValues: configuration.layerValues,
        petri: {
          ...this.state.petri,
          clear: true,
          layers: configuration.defaultLayers,
          timeFilter: configuration.isLive ? 'Breaking News' : 'All News'
        }
      })
    }
  }

  render() {
    return (
      <AppContext.Provider
        value={{
          ...this.state,
          petri: {
            ...this.state.petri,
            actions: {
              updateBreadcrumb: this.updateBreadcrumb,
              updateLayers: this.updateLayers,
              updateNormalization: this.updateNormalization,
              updateTimeFilter: this.updateTimeFilter,
              updateCurrentTimeRange: this.updateCurrentTimeRange
            }
          },
          actions: {
            performQuery: this.getPetriData,
            updateConfiguration: this.updateConfiguration
          }
        }}
      >
        {/*@ts-ignore */}
        <DndProvider backend={MultiBackend} options={HTML5toTouch}>
          {this.props.children}
        </DndProvider>
      </AppContext.Provider>
    )
  }
}

export default Data
