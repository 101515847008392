import * as React from 'react'
import Linkify from 'react-linkify'
import { IconButton, mergeStyleSets, Text } from 'office-ui-fabric-react'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import { formatDate } from 'lib/utils'
import { boxShadow, transition, duration } from 'styles/constants'
import Img from 'utilComponents/Img'
import TwitterLogo from 'components/Petri/lib/recordlayer/resources/Twitter_Logo_WhiteOnBlue.svg'

type Props = {
  hidden: boolean
  tweet: any
  onDismiss: any
}

const classes = mergeStyleSets({
  avatar: {
    height: 48,
    width: 48,
    borderRadius: '50%',
    borderStyle: 'solid',
    borderColor: 'white',
    borderWidth: 2,
    boxShadow: boxShadow.elevation4,
    marginRight: 8
  },
  content: {
    marginTop: 8
  },
  dialog: {
    boxShadow: boxShadow.elevation64,
    position: 'fixed',
    transition: `${transition.opacity}`,
    zIndex: 10000,
    width: 350,
    padding: 16,
    backgroundColor: 'var(--surfaceElements)'
  },
  previewImage: {
    width: '100%',
    marginBottom: 8
  },
  title: {
    marginRight: 48
  },
  date: {
    marginTop: 4,
    fontSize: 'small',
    display: 'block'
  },
  topButtons: {
    position: 'absolute',
    top: 16,
    right: 16,
    display: 'flex'
  }
})
const TweetDialog = (props: Props) => {
  const dialogRef = React.useRef<HTMLDivElement>(null)

  React.useLayoutEffect(() => {
    if (dialogRef.current) {
      if (props.hidden) {
        dialogRef.current.style.pointerEvents = 'none'
        dialogRef.current.style.transitionDuration = '0ms'
        dialogRef.current.style.opacity = String(0)
      } else {
        dialogRef.current.style.top = `calc(50% - ${
          dialogRef.current.clientHeight / 2
        }px)`
        dialogRef.current.style.left = `calc(50% - ${
          dialogRef.current.clientWidth / 2
        }px)`
        dialogRef.current.style.pointerEvents = 'all'
        dialogRef.current.style.transitionDuration = `${duration.shortest}ms`
        dialogRef.current.style.opacity = String(1)
      }
    }
  }, [props.hidden])
  return (
    <>
      <ClickAwayListener onClickAway={props.onDismiss}>
        <div className={classes.dialog} ref={dialogRef}>
          <div className={classes.topButtons}>
            {props.tweet?.tweetUrl && (
              <a
                title="Open link in new tab"
                rel="noopener noreferrer"
                href={props.tweet.tweetUrl}
                className={`fa fa-twitter`}
                target={'_blank'}
                style={{
                  marginRight: 4,
                  padding: 4,
                  height: 24,
                  width: 24,
                  fontSize: 14
                }}
              >
                {''}
              </a>
            )}
            <IconButton
              iconProps={{ iconName: 'ChromeClose' }}
              onClick={props.onDismiss}
            />
          </div>
          <div
            className={classes.title}
            style={{ display: 'flex', alignItems: 'flex-start' }}
          >
            <a
              title="Open profile in new tab"
              rel="noopener noreferrer"
              href={props.tweet?.profileUrlAlt}
              target={'_blank'}
            >
              <Img
                alt={props.tweet.username}
                className={classes.avatar}
                fallbackSrc={TwitterLogo}
                src={
                  props.tweet?.profileImageUrl
                    ? props.tweet.profileImageUrl
                    : TwitterLogo
                }
              />
            </a>
            <div>
              <Text variant={'large'} className={classes.title}>
                {props.tweet.username}
              </Text>
              <Text variant={'smallPlus'} className={classes.date}>
                {formatDate(props.tweet.createdAt)}
              </Text>
            </div>
          </div>
          <div className={classes.content}>
            {props.tweet.previewImageUrl && (
              <Img
                className={classes.previewImage}
                alt={props.tweet.username}
                src={props.tweet.previewImageUrl}
              />
            )}
            <Text variant={'mediumPlus'}>
              <Linkify componentDecorator={componentDecorator}>
                {props.tweet.text}
              </Linkify>
            </Text>
          </div>
        </div>
      </ClickAwayListener>
    </>
  )
}

const componentDecorator = (href: string, text: string, key: number) => (
  <a
    href={href}
    key={key}
    rel="noopener noreferrer"
    target="_blank"
    style={{ color: 'rgba(255, 128, 0, 1)' }}
  >
    {' '}
    {text}{' '}
  </a>
)

export default TweetDialog
