import React, { useEffect, useState } from 'react'
import { AppContext } from 'Context'
import { mergeStyleSets } from '@uifabric/styling'
import {
  Dropdown,
  Icon,
  IconButton,
  Modal,
  Text,
  Toggle
} from 'office-ui-fabric-react'
import bubbleImg from '../components/Petri/lib/population/resources/bubble.png'
import shiftSVG from '../Shift_button.svg'
import { Controls } from 'components/Controls'
import { mediaQuery, transition } from 'styles/constants'
import { Card } from './Card'
// import TutorialButton from 'components/Tutorial/TutorialButton'

const dropdownStyle = {
  root: { display: 'inline-flex', alignItems: 'center', marginRight: 8 },
  dropdown: { width: 180 }
}

const styles = mergeStyleSets({
  header: {
    padding: '16px 62px 8px 62px',
    zIndex: 1000,
    position: 'fixed',
    top: 0,
    minHeight: 48,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    transition: transition.transformShort,
    // pointerEvents: 'none',
    [mediaQuery.isXs]: {
      padding: '16px 62px 8px 16px',
      alignItems: 'flex-start'
    }
  },
  subtitle: {
    marginTop: 8,
    [mediaQuery.isXs]: {
      marginTop: 4
    }
  },
  title: {
    marginTop: 4
  },
  tutorialImg: {
    width: '100%'
  },
  leftContainer: {
    position: 'fixed',
    top: 16,
    right: 16,
    zIndex: 1002,
    display: 'flex'
  },
  tutorial: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    padding: 10,
    '& > *:not(:last-child)': {
      marginBottom: 20
    }
  },
  tutorialHeader: {
    fontSize: '1.2em',
    textDecoration: 'bold',
    marginBottom: 20
  },
  tutorialCancel: {
    position: 'absolute',
    top: 5,
    right: 5
  },
  sameLine: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    wordBreak: 'break-word',
    whiteSpace: 'normal'
  },
  datasetList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20
  },
  statusLive: {
    color: 'red',
    display: 'flex',
    alignItems: 'center',
    marginTop: 5
  }
})

const Header = () => {
  const { petri, screenSize, configuration } = React.useContext(AppContext)

  const isMobile = screenSize === 'xs'

  const hideHeaderFooter = React.useMemo(
    () => petri.breadcrumb.length > 1 || petri.onLastLayer,
    [petri.breadcrumb, petri.onLastLayer]
  )

  const [isTutorialOpen, setIsTutorialOpen] = useState(false)

  useEffect(() => {
    const hasVisited = localStorage.getItem('hasVisited')
    if (!hasVisited) {
      setIsTutorialOpen(true)
      localStorage.setItem('hasVisited', 'true')
    }
  }, [])

  return (
    <>
      <Controls isMobile={isMobile} />

      <header
        className={styles.header}
        style={{
          transform: `translateY(${hideHeaderFooter ? '-100' : '0'}px)`
        }}
      >
        <Text
          className={styles.title}
          variant={isMobile ? 'mediumPlus' : 'large'}
        >
          {configuration.title}
        </Text>

        {configuration.subtext && configuration.isLive && (
          <Text className={styles.subtitle} variant={'smallPlus'}>
            {configuration.subtext}
          </Text>
        )}
      </header>

      <div className={styles.leftContainer}>
        {/*<TutorialButton hidden={hideHeaderFooter} />*/}
        <IconButton
          iconProps={{
            iconName: 'StatusCircleQuestionMark',
            style: { fontSize: 24, lineHeight: '12px' }
          }}
          ariaLabel="Open info modal"
          onClick={() => setIsTutorialOpen(true)}
          styles={{ root: { color: 'var(--white)' } }}
        />
      </div>

      <Modal
        isOpen={isTutorialOpen}
        onDismiss={() => setIsTutorialOpen(false)}
        styles={{
          layer: {
            zIndex: 1000002
          },
          scrollableContent: {
            background: 'var(--black)',
            color: 'var(--white)',
            padding: 16
          }
        }}
      >
        <Tutorial onClose={() => setIsTutorialOpen(false)} />
      </Modal>
    </>
  )
}

export default Header

const Tutorial = ({ onClose }: { onClose: () => void }) => {
  return (
    <div className={styles.tutorial}>
      <IconButton
        iconProps={{ iconName: 'Cancel' }}
        ariaLabel="Close popup modal"
        onClick={onClose}
        className={styles.tutorialCancel}
      />

      <Text>
        Click the
        <Icon style={{ margin: '0 5px' }} iconName="CollapseMenu" />
        to open the menu
      </Text>

      <Text>
        <div style={{ display: 'inline-flex', alignItems: 'center' }}>
          <Dropdown
            disabled
            options={[]}
            placeholder="Add a new group"
            styles={dropdownStyle}
          />
          <Icon style={{ margin: '0 5px' }} iconName="Add" />
        </div>
        Use this dropdown to add new layers
      </Text>

      <Text>
        <Dropdown
          disabled
          options={[]}
          placeholder="National Sentiment"
          styles={dropdownStyle}
        />
        Use this dropdown to select a preset to start with
      </Text>

      <Text>
        <div style={{ display: 'inline-block', width: 150, marginRight: 8 }}>
          <Card
            id={''}
            text="Topic"
            index={0}
            moveCard={() => {}}
            onClickDelete={() => {}}
            onDrag={() => {}}
          />
        </div>
        Drag and drop to reorder layers
      </Text>

      <Text>
        <Dropdown
          disabled
          options={[]}
          placeholder="Select a time filter"
          styles={dropdownStyle}
        />
        Use this dropdown to select the timeframe tweets are being pulled from
      </Text>

      <Text>
        Click outside of the bubble{' '}
        <img
          style={{
            width: 30,
            height: 30,
            margin: '0 5px',
            verticalAlign: 'middle'
          }}
          src={bubbleImg}
          alt="bubble"
        />{' '}
        to zoom out (go back one step)
      </Text>

      <Text>
        Use the{' '}
        <img
          src={shiftSVG}
          style={{
            width: 60,
            height: 50,
            margin: '0 5px',
            verticalAlign: 'middle'
          }}
          alt="shift"
        />{' '}
        on PC or the{' '}
        <Toggle
          checked={false}
          label={'Scaled'}
          inlineLabel
          onText={'True'}
          offText={'false'}
          onChange={() => {}}
        />
        Toggle on mobile to set all bubbles on the current layer to the same
        size.
      </Text>
    </div>
  )
}
