/**
 * Core3d.Loader Service
 * @namespace Services
 */
import {
  loadGeometry,
  loadMaterial,
  loadImage,
  loadTexture,
  loadObject
} from '../Petri.utils'

export default coreLoader()

function coreLoader() {
  const methods = {
    load // returns a promise
  }

  // List of map texture types for THREE.js materials:
  // const maps = [
  //   'map',
  //   'aoMap',
  //   'envMap',
  //   'bumpMap',
  //   'alphaMap',
  //   'lightMap',
  //   'normalMap',
  //   'emissiveMap',
  //   'specularMap',
  //   'metalnessMap',
  //   'roughnessMap',
  //   'displacementMap'
  // ]

  // THREE.js loaders and manager:
  // const manager = new THREE.LoadingManager()
  // manager.onProgress = (item, loaded, total) => {
  //   // console.log(item, loaded, total)
  // }
  // const objectLoader = new OBJLoader()
  // const textureLoader = new THREE.TextureLoader()
  // const materialLoader = new THREE.MaterialLoader()

  // Promisfied loaders:
  const loaders = {
    geometry: loadGeometry,
    material: loadMaterial,
    texture: loadTexture,
    object: loadObject,
    image: loadImage
  }

  return methods

  function load(json) {
    if (typeof loaders[json.type] !== 'undefined') {
      return loaders[json.type](json.data)
    }
    console.error('Loader is not available for this json object.')
  }
}

//
//   // Private:
//   //===========
//   // Geometry loader:
//   function loadGeometry(data) {
//     let geometry
//
//     switch (data.type) {
//       case 'PlaneGeometry':
//         geometry = new THREE.PlaneGeometry(data.width || 1, data.height || 1, data.widthSegments || 1,
// data.heightSegments || 1) break case 'SphereGeometry': geometry = new THREE.SphereGeometry(data.radius || 1,
// data.widthSegments || 50, data.heightSegments || 50) break default: geometry = new THREE.BoxGeometry( data.width ||
// 1, data.height || 1, data.depth || 1, data.widthSegments || 1, data.heightSegments || 1, data.depthSegments || 1 )
// break }  return new Promise((resolve, reject) => { if (typeof geometry !== 'undefined') { resolve(geometry) } else {
// console.error('Failed to load geometry', data.name) reject() } }) }  //=========== // Material loader: function
// loadMaterial(data) { // This will be fixed in version 74 of Three.js: if (typeof data.uuid === 'undefined') {
// data.uuid = THREE.Math.generateUUID() } // TODO: Make this validate that the textures are loaded? Some three.js
// warning... // Creates a placeholder string property for us to reference textures to be used later on by texture
// name: let textures = {} maps.forEach(function(map) { if (data[map]) { textures[data[map]] = data[map] } })
// materialLoader.setTextures(textures)  const material = materialLoader.parse(data)  return new Promise((resolve, reject) => { if (typeof material !== 'undefined') { resolve(material) } else { console.error('Failed to load material', data.name) reject() } }) }  //=========== // Texture loader: function loadTexture(data) { return new Promise((resolve, reject) => { textureLoader.load( data.url, function(texture) { // Attach a texture mapping if defined: if (typeof data.mapping !== 'undefined') { texture.mapping = THREE[data.mapping] } resolve(texture) }, function(xhr) { if (xhr.lengthComputable) { const percentComplete = (xhr.loaded / xhr.total) * 100 console.log(`${Math.round(percentComplete, 2)}% downloaded`) } }, reject ) }) }  //=========== // Object loader: function loadObject(data) { return new Promise((resolve, reject) => { objectLoader.load( data.url, resolve, function(xhr) { if (xhr.lengthComputable) { const percentComplete = (xhr.loaded / xhr.total) * 100 console.log(`${Math.round(percentComplete, 2)}% downloaded`) } }, reject ) }) }  //=========== // Image loader: function loadImage(data) { let image = new Image() image.src = data.url return new Promise((resolve, reject) => { image.onload = function() { resolve(image) } image.onerror = function() { reject() } }) } }
