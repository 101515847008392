import firebase from 'firebase/app'
import 'firebase/functions'
import { formatDate } from 'lib/utils'
import * as Types from 'lib/types'

const isDebug = false

// in local dev, use staging config by default for now,
const firebaseConfig = process.env.REACT_APP_FIREBASE_CONFIG
  ? JSON.parse(process.env.REACT_APP_FIREBASE_CONFIG as string)
  : {
      apiKey: 'AIzaSyBdjL0T2LNC84Pv9w98xwFab2aw5euBriU',
      authDomain: 'staging-election-2020.firebaseapp.com',
      databaseURL: 'https://staging-election-2020.firebaseio.com',
      projectId: 'staging-election-2020',
      storageBucket: 'staging-election-2020.appspot.com',
      messagingSenderId: '227049663869',
      appId: '1:227049663869:web:2c056e273e386d6f46f014'
    }

firebase.initializeApp(firebaseConfig)

const functions = firebase.functions()

if (isDebug) {
  const functionsEmulatorUrl = 'http://localhost:5001'
  functions.useFunctionsEmulator(functionsEmulatorUrl)
  console.log(`Firebase Functions Emulation is set to ${functionsEmulatorUrl}`)
}

type getPetriDataType = {
  breadcrumb?: string[]
  layers?: string[]
  esIndex?: string
  timeFilter?: Types.TimeFilter
  currentTimeRange?: Types.CurrentTimeRange
  initialTimeRange?: Types.CurrentTimeRange
}

export const getPetriData = async ({
  breadcrumb,
  layers,
  timeFilter,
  esIndex,
  currentTimeRange,
  initialTimeRange
}: getPetriDataType) => {
  const data = {
    index: esIndex,
    breadcrumb,
    layers,
    timeFilter,
    currentTimeRange,
    initialTimeRange
  }

  try {
    const response = await functions.httpsCallable('performQuery')(
      JSON.stringify(data)
    )

    if (isDebug) {
      if (response.data?.timeRange?.start) {
        console.log(
          'Time Start:',
          formatDate(response.data.timeRange.start),
          '-',
          'END:',
          formatDate(response.data.timeRange.end)
        )
      }

      if (response.data.onLastLayer) {
        const datesList: number[] = []
        // @ts-ignore
        response.data.population.forEach((hit) => {
          if (!isNaN(hit.data.createdAt)) {
            datesList.push(hit.data.createdAt)
          }
        })
        console.log(
          'start:',
          formatDate(Math.min(...datesList)),
          'end:',
          formatDate(Math.max(...datesList))
        )
      }
    }

    return {
      aggregations: response.data?.aggregations || [],
      onLastLayer: response.data?.onLastLayer || false,
      population: response.data?.population || [],
      timeRange: response.data?.timeRange || {}
    }
  } catch (e) {
    console.error(e)
    return {
      aggregations: [],
      onLastLayer: false,
      population: [],
      timeRange: {}
    }
  }
}
