import {
  IconButton,
  IPanel,
  mergeStyleSets,
  Panel,
  PanelType
} from 'office-ui-fabric-react'

import React, { useContext, useEffect, useState } from 'react'
import { CardContainer } from './CardContainer'
import BottomSheet from 'utilComponents/BottomSheet'
import { transition, boxShadow } from 'styles/constants'
import { AppContext } from 'Context'

export interface Item {
  id: number
  text: string
}

type Props = {
  isMobile: boolean
}

const styles = mergeStyleSets({
  controls: {
    zIndex: 1001
  },
  panelButton: {
    zIndex: 1000001,
    position: 'fixed',
    top: 16,
    left: 16,
    transition: transition.transform,
    boxShadow: boxShadow.elevation16
  }
})

export const Controls = (props: Props) => {
  const panelRef = React.useRef<IPanel>({
    dismiss(ev: React.KeyboardEvent<HTMLElement> | undefined): void {},
    open(): void {}
  })
  const prevIsPanelOpen = React.useRef(false)

  const { screenSize, refs, windowDimensions } = useContext(AppContext)
  const isMobile = React.useMemo(
    () => screenSize === 'xs' || windowDimensions.width < 600,
    // eslint-disable-next-line
    [screenSize]
  )
  const [isOpen, setIsOpen] = useState(!isMobile)

  useEffect(() => {
    if (!refs.panelRef) {
      refs.panelRef = panelRef
    }

    prevIsPanelOpen.current = isOpen
    // eslint-disable-next-line
  }, [])

  return (
    <>
      <IconButton
        className={styles.panelButton}
        iconProps={{
          iconName: isOpen ? 'Cancel' : 'CollapseMenu'
        }}
        onClick={() => {
          prevIsPanelOpen.current = !isOpen
          setIsOpen(!isOpen)
        }}
        style={
          props.isMobile
            ? {
                top: windowDimensions.height - 100,
                padding: 4
              }
            : {}
        }
        styles={{
          root: {
            color: 'var(--white)',
            backgroundColor: props.isMobile
              ? 'var(--neutralLight)'
              : 'transparent',
            borderRadius: props.isMobile ? '50%' : 2
          }
        }}
      />
      {props.isMobile ? (
        <BottomSheet open={isOpen}>
          <CardContainer />
        </BottomSheet>
      ) : (
        <Panel
          componentRef={panelRef}
          data-prev-is-panel-open={prevIsPanelOpen}
          isOpen={isOpen}
          hasCloseButton={false}
          onDismiss={() => setIsOpen(!isOpen)}
          type={PanelType.smallFixedNear}
          customWidth={'330px'}
          closeButtonAriaLabel="Close"
          isBlocking={false}
          styles={{
            main: {
              top: 32,
              background: 'transparent',
              boxShadow: 'none',
              maxHeight: 'fit-content',
              width: 300
            },
            closeButton: { color: 'var(--white)' }
          }}
          isHiddenOnDismiss
          layerProps={{ eventBubblingEnabled: true }}
        >
          <CardContainer />
        </Panel>
      )}
    </>
  )
}
