/**
 * Core3d.Sprite Service
 * @namespace Services
 */
import * as THREE from 'three'

export default coreSprite()

function coreSprite() {
  let MORE = math()

  return {
    create,
    createSheet
  }

  function create() {
    // build a new sprite here:
    let canvasElement = document.createElement('canvas')
    let ctx = canvasElement.getContext('2d')

    // Since all sprites end up being squares in Three.js, we should just
    // make a sane, relatively high resolution sprite sized canvas:
    const squareSize = 256 //THREE.Math.nextPowerOfTwo(245)-1 was 64
    canvasElement.width = squareSize
    canvasElement.height = squareSize

    const halfSize = squareSize / 2
    const halfSpriteHeight = halfSize
    const halfSpriteWidth = halfSize

    return {
      getCanvas,
      setImage,
      setRotation,
      setValue,
      setBackground,
      setText,

      fitContents
    }

    function getCanvas() {
      return canvasElement
    }

    function setBackground(color) {
      // Background of the nice square sprite:
      ctx.fillStyle = color
      ctx.fillRect(0, 0, squareSize, squareSize)
    }

    function setText(parameters) {
      // Configure any sprite options:
      // TODO: maybe use the options object pattern here instead?

      if (typeof parameters === 'undefined') {
        parameters = {}
      }

      // Configure sprite text parameters themselves
      const fontSize = parameters.hasOwnProperty('fontSize')
        ? parameters.fontSize
        : 10
      const fontFace = parameters.hasOwnProperty('fontFace')
        ? parameters.fontFace
        : 'Arial'
      const fillColor = parameters.hasOwnProperty('fillColor')
        ? parameters.fillColor
        : 'rgba(255,0,0,1.0)'
      const spriteText = parameters.hasOwnProperty('text')
        ? parameters.text
        : 'Hello world!'

      // Setup the canvas text attributes so we can measure the text width
      ctx.font = `Bold ${fontSize}px ${fontFace}`
      // console.log('Font parameters: ', ctx.font)

      // center text on the sprite:
      //var textHeight = ctx.measureText( spriteText ).height
      // TODO: Figure out how to measure text height more accurately. Canvas does not provide this natively.
      const fontHeightScale = 0.8 // This is a hack. Maybe fabric is better...

      const textWidth = ctx.measureText(spriteText).width
      const halfTextHeight = (fontSize * fontHeightScale) / 2 // Hack around canvas lack of height measurement
      const halfTextWidth = textWidth / 2
      console.log('size: ', textWidth)

      // populate text using sizes we just figured out:
      ctx.fillStyle = fillColor
      ctx.fillText(
        spriteText,
        halfSpriteWidth - halfTextWidth,
        halfSpriteHeight + halfTextHeight
      )
    }

    function setImage(url, callback) {
      // Work needs to be done here...
      let img = new Image()
      img.src = url

      // Load image fully into canvasElement:
      img.onload = () => {
        canvasElement.width = img.width
        canvasElement.height = img.height

        ctx.rect(0, 0, img.width, img.height)
        ctx.fillStyle = ctx.createPattern(img, 'no-repeat')
        ctx.fill()
        callback()
      }
    }

    function setRotation(angle) {
      ctx.translate(halfSize, halfSize)
      ctx.rotate(angle * MORE.TORAD)
      ctx.translate(-halfSize, -halfSize)
    }

    function setValue(color, value) {
      // value should be between 0 and 1

      // figure out our maximum circle size so we can rotate the sprite
      // without clipping whatever we draw. That means using halfSize as our
      // maximum radius. That means our graphic must fit inside that circle
      // at all rotations. That limits our bars width. So let's say our bar
      // is 1/4 of max radius:
      const barWidth = squareSize * 0.25

      // And let's say our barHeight is related to the maximum value that
      // will fit inside of the circle depending on our bar's scale.
      //
      const Csq = Math.pow(squareSize, 2)
      const Asq = Math.pow(barWidth, 2)
      const maxBarHeight = Math.sqrt(Csq - Asq)

      const barHeight = maxBarHeight * value

      ctx.fillStyle = color
      ctx.fillRect(
        0,
        halfSpriteHeight - barWidth / 2,
        barHeight,
        barWidth // halfSpriteHeight// +
      )
    }

    function fitContents() {}
  } // create

  function createSheet() {
    // TODO: Add more canvas pages if one is filled up
    // adding pages logic currently inside petri-label factory

    // TODO: Destroy sheet or sheets function

    // Build a new sprite sheet:
    let canvasElement = document.createElement('canvas')
    let ctx = canvasElement.getContext('2d')

    // Initialize sprite sheet at 2048 x 2048:
    canvasElement.width = 2048
    canvasElement.height = 2048

    // Object to get UV-coordinates of text from canvasElement sprite sheet:
    let spriteUVs = {}

    // Initial horizontal offset:
    let textWidthOffset = 2
    // Initial vertical offset:
    let textHeightOffset = 0
    // Initial largest font size:
    let largestFontSize = 1

    let full = false

    let texture = new THREE.Texture(canvasElement, THREE.UVMapping)

    texture.repeat.set(1 / 2048, 1 / 2048)

    return {
      getTexture,
      getSheet,
      addText,
      getUVs,
      isFull

      // buildNewSprite?
    }

    function getTexture() {
      return texture
    }

    function getSheet() {
      return canvasElement
    }

    function addText(parameters) {
      // Configure any sprite options:
      // TODO: maybe use the options object pattern here instead?

      if (typeof parameters === 'undefined') {
        parameters = {}
      }

      // Configure sprite text parameters themselves:
      const spriteText = parameters.hasOwnProperty('text')
        ? parameters.text
        : 'Hello world!'
      const spriteId = parameters.hasOwnProperty('id') ? parameters.id : 'id_1'
      const fontSize = parameters.hasOwnProperty('fontSize')
        ? parameters.fontSize
        : 10
      const fontFace = parameters.hasOwnProperty('fontFace')
        ? parameters.fontFace
        : 'Arial'
      const fillColor = parameters.hasOwnProperty('fillColor')
        ? parameters.fillColor
        : 'rgba(255,0,0,1.0)'

      // Setup the canvas text attributes so we can measure the text width:
      ctx.font = `Bold ${fontSize}px ${fontFace}`
      const textWidth = ctx.measureText(spriteText).width

      // console.log('textWidth', textWidth, spriteText)

      //**** fontSize* (4/3) is a COMPLETE HACK! to add relative padding horizontally and vertically around text ****//

      // Add text to new line if over 2048 (will need to eventually check if text itself is going to be > 2048):
      if (textWidthOffset + textWidth + fontSize / 3 > 2046) {
        // set textHeightOffset for the new line:
        textHeightOffset += largestFontSize * (4 / 3)

        // reset left textWidthOffset:
        textWidthOffset = 2

        // set largestFontSize to new line first text entry fontSize:
        largestFontSize = fontSize
      }

      // If adding more text exceeds sprite sheet height:
      if (textHeightOffset + fontSize > 2048) {
        // Text cannot be added to current sheet:
        full = true
        return
      }

      // Set largestFontSize if less than current fontSize:
      if (largestFontSize < fontSize) {
        largestFontSize = fontSize
      }

      // Need to set text attributes again after changing canvasElement context:
      ctx.font = `Bold ${fontSize}px ${fontFace}`

      // Add the addtional text:
      ctx.fillStyle = fillColor
      ctx.fillText(
        spriteText,
        textWidthOffset + fontSize / 6,
        textHeightOffset + fontSize
      )

      // Sprite sheet look up entry:
      spriteUVs[spriteId] = {
        s: textWidthOffset,
        t: textHeightOffset,
        u: textWidthOffset + textWidth + fontSize / 3,
        v:
          textHeightOffset + fontSize * (4 / 3) > 2048
            ? 2048
            : textHeightOffset + fontSize * (4 / 3)
      }

      // Offset with additonal fontSize relative as padding:
      textWidthOffset += textWidth + fontSize / 3

      texture.needsUpdate = true
    }

    function getUVs(id) {
      return spriteUVs[id]
    }

    function isFull() {
      return full
    }
  } // createSheet

  function math() {
    let mathHelpers = {
      TORAD: Math.PI / 180, // radians = degrees * (pi/180)
      TODEG: 180 / Math.PI // degrees = radians * (180/pi)
    }
    return mathHelpers
  }
}
