import React, {
  useState,
  useCallback,
  useEffect,
  useContext,
  useRef
} from 'react'
import { Card, IPopulationData, style as cardStyle } from './Card'
import update from 'immutability-helper'
import { AppContext, configurations } from 'Context'
import {
  Dropdown,
  IconButton,
  IDropdownOption,
  Toggle
} from 'office-ui-fabric-react'
import { mergeStyleSets } from '@uifabric/styling'
import { Preview, PreviewGeneratorArg } from 'react-dnd-multi-backend'
import isEqual from 'react-fast-compare'
import { IConfiguration } from 'lib/types'
import { capitalizeFirstLetter } from 'lib/utils'

const styles = mergeStyleSets({
  bottomControls: {
    display: 'flex',
    flexDirection: 'column',
    '& > *': {
      marginTop: 20
    }
  }
})

interface IPresetOption {
  id: string
  layers: string[]
}

const cardsStyle = {
  width: '100%'
}

export interface Item {
  id: string
  text: string
}

export interface ContainerState {
  cards: Item[]
}

const NUM_PERCENT_LINES = 4

export const CardContainer: React.FC = () => {
  const {
    layerValues,
    petri,
    screenSize,
    timeFilterOptions,
    sendEvent,
    configuration,
    actions: { updateConfiguration }
  } = useContext(AppContext)

  const presetOptions = configuration.presets

  const [currentLayers, setCurrentLayers] = React.useState(
    petri.layers.filter((layer) => layer !== 'root')
  )
  const [isNormalizationToggled, setIsNormalizationToggled] = useState(
    petri.isNormalized
  )
  const [dropdownValue, setDropdownValue] = useState<IDropdownOption>()
  const [presetValue, setPresetValue] = useState<IPresetOption>(
    presetOptions[0]
  )
  const [timeFilterValue, setTimeFilterValue] = useState<IDropdownOption>({
    key: petri.timeFilter,
    text: petri.timeFilter
  })

  useEffect(() => {
    const currentLayers = petri.layers.filter((layer) => layer !== 'root')
    const cards = currentLayers.map((layer) => ({ id: layer, text: layer }))
    setCurrentLayers(currentLayers)
    setCards(cards)
    setPresetValue(presetOptions[0])
    // eslint-disable-next-line
  }, [configuration, presetOptions])

  useEffect(() => {
    if (petri.timeFilter !== timeFilterValue.key) {
      setTimeFilterValue({
        key: petri.timeFilter,
        text: petri.timeFilter
      })
    }
    // eslint-disable-next-line
  }, [petri.timeFilter])

  const [cards, setCards] = useState<Item[]>(
    currentLayers.map((layer) => ({
      id: layer,
      text: layer
    }))
  )
  const [isDragging, setIsDragging] = useState(false)
  const [populationData, setPopulationData] = useState<IPopulationData[]>([])
  const isMobile = screenSize === 'xs'
  const configRef = useRef<IConfiguration>(configuration)

  useEffect(() => {
    // This sets the correct layer when the app state gets out of sync with the component state
    // This can happen when deleting layer cards in quick succession before the query has time to complete
    if (
      !isEqual(
        currentLayers,
        petri.layers.filter((layer) => layer !== 'root')
      ) &&
      configRef.current.key === configuration.key
    ) {
      petri.actions.updateLayers(currentLayers)
    } else if (configRef.current.key !== configuration.key) {
      petri.actions.updateLayers(configuration.presets[0].layers)
      configRef.current = configuration
    }
    // eslint-disable-next-line
  }, [petri.layers, configuration])

  useEffect(() => {
    if (!cards.length) return

    const popData: IPopulationData[] = petri.population
      .slice(0, NUM_PERCENT_LINES)
      .map((pop: any) => ({
        id: pop._id,
        percent: Math.floor(100 * pop.fraction) / 100
      }))

    setPopulationData(popData)
  }, [cards.length, petri.population])

  const getMapping = (text: string) => {
    const { layerMapping } = configuration

    if (layerMapping && layerMapping[text]) {
      return layerMapping[text]
    } else {
      return capitalizeFirstLetter(text)
    }
  }

  const updateLayers = useCallback(
    (cards: Item[]) => {
      const cardLayers = cards.map(({ id }) => id)
      const shouldUpdateLayers = !isEqual(currentLayers, cardLayers)

      if (shouldUpdateLayers && !isDragging) {
        setCurrentLayers(cardLayers)
        petri.actions.updateLayers(cardLayers)
      }
    },
    [isDragging, petri.actions, currentLayers]
  )

  useEffect(() => {
    if (!isDragging) {
      updateLayers(cards)
    }
  }, [updateLayers, isDragging, cards])

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragCard = cards[dragIndex]
      const newCards = update(cards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragCard]
        ]
      })

      setCards(newCards)
      sendEvent('rearrangeLayers', { layers: newCards.map((card) => card.id) })
    },
    [sendEvent, cards]
  )

  const onClickDelete = (id: string) => {
    const toDeleteIndex = cards.findIndex((card) => card.id === id)
    const newCards = [
      ...cards.slice(0, toDeleteIndex),
      ...cards.slice(toDeleteIndex + 1)
    ]

    setCards(newCards)
  }

  const renderCard = (card: Item, index: number) => {
    const text = getMapping(card.text)

    const latestCard = cards[petri.breadcrumb.length - 1]
    return (
      <Card
        key={card.id}
        index={index}
        id={card.id}
        text={text}
        moveCard={moveCard}
        onClickDelete={() => onClickDelete(card.id)}
        populationData={
          latestCard && latestCard.id === card.id ? populationData : undefined
        }
        onDragStart={() => setIsDragging(true)}
        onDragEnd={() => setIsDragging(false)}
      />
    )
  }

  const onClickAddGroup = () => {
    if (!dropdownValue) return

    const text = String(dropdownValue.key)

    if (!cards.find((card) => card.text === text)) {
      const newCards = cards.concat({ id: text, text })
      setCards(newCards)
      setDropdownValue(undefined)

      sendEvent('addLayer', { layer: text })
    }
  }

  const dropdownOptions = layerValues
    .filter((layer) => !cards.find((card) => card.id === layer))
    .map((val) => {
      const text = getMapping(val)

      return { key: val, text }
    })

  const onChangeGroupDropdown = (
    _: React.FormEvent,
    option: IDropdownOption | undefined
  ) => {
    if (option) {
      setDropdownValue(option)
    }
  }

  const onChangeTimeFilter = (
    _: React.FormEvent,
    option: IDropdownOption | undefined
  ) => {
    if (option) {
      setTimeFilterValue(option)
      petri.actions.updateTimeFilter(option.key)
      sendEvent('changeTimeFilter', { filter: option.key })
    }
  }

  const onChangePresetDropdown = (
    _: React.FormEvent,
    val: IDropdownOption | undefined
  ) => {
    if (val) {
      const option = presetOptions.find((option) => option.id === val.text)
      if (option) {
        setPresetValue(option)
        sendEvent('changePreset', { preset: option.id })

        const newCards = option.layers.map((layer) => ({
          id: layer,
          text: layer
        }))
        setCards(newCards)
      }
    }
  }

  const renderPreview = ({ item, style }: PreviewGeneratorArg) => {
    const text = getMapping(item.text)

    return (
      <div
        style={{
          ...style,
          ...cardStyle,
          zIndex: 10002,
          width: 'calc(100% - 10px)',
          paddingLeft: 10
        }}
      >
        {text}
      </div>
    )
  }

  const onChangeDatasetDropdown = (
    _: React.FormEvent,
    option: IDropdownOption | undefined
  ) => {
    if (option) {
      updateConfiguration(String(option.key))
    }
  }

  const datasetOptions = Object.values(configurations).map((config) => ({
    key: config.key,
    text: config.title
  }))

  return (
    <>
      <Dropdown
        options={datasetOptions}
        onChange={onChangeDatasetDropdown}
        responsiveMode={undefined}
        selectedKey={configuration.key}
        style={{ marginTop: isMobile ? 0 : 36, marginBottom: 20 }}
      />

      <div style={{ ...cardsStyle }}>
        {cards.map((card, i) => renderCard(card, i))}
        <Preview generator={renderPreview} />
      </div>

      <div className={styles.bottomControls}>
        <div
          style={{
            display: 'flex',
            width: '100%'
          }}
        >
          <Dropdown
            placeholder="Add a new group"
            options={dropdownOptions}
            responsiveMode={undefined}
            onChange={onChangeGroupDropdown}
            selectedKey={dropdownValue ? dropdownValue.key : null}
            styles={{ root: { width: '100%' } }}
          />
          <IconButton
            id={'addGroupDropdown'}
            disabled={!dropdownValue}
            iconProps={{
              iconName: 'Add'
            }}
            onClick={onClickAddGroup}
            styles={{
              root: {
                color: 'var(--white)',
                backgroundColor: 'var(--orange)',
                borderRadius: '50%',
                marginLeft: 4
              }
            }}
          />
        </div>

        <Dropdown
          options={presetOptions.map((option) => ({
            key: option.id,
            text: option.id
          }))}
          responsiveMode={undefined}
          selectedKey={presetValue.id}
          onChange={onChangePresetDropdown}
          label="Presets"
          style={{ width: 'calc(100% - 25px)' }}
        />

        {configuration.isLive && (
          <Dropdown
            options={timeFilterOptions.map((option) => ({
              key: option,
              text: option
            }))}
            responsiveMode={undefined}
            selectedKey={timeFilterValue?.key}
            onChange={onChangeTimeFilter}
            label="Time Filter"
            placeholder="Select a time filter"
            style={{ width: 'calc(100% - 25px)' }}
          />
        )}

        {isMobile && (
          <Toggle
            checked={!isNormalizationToggled}
            label={'Scaled'}
            inlineLabel
            onText={'True'}
            offText={'false'}
            onChange={() => {
              setIsNormalizationToggled(!isNormalizationToggled)
              petri.actions.updateNormalization(!isNormalizationToggled)
            }}
          />
        )}
      </div>
    </>
  )
}
