import { buildNewSprite } from 'components/Petri/lib/Petri.utils'
import coreSprite from './core-sprite'

// Sprite sheets array:
const sheets = [coreSprite.createSheet()]

// Storage for sprite objects:
let sprite = {}

const addLabel = (parameters) => {
  // Check if text has not been added to some sheet:
  if (typeof sprite[parameters.id] === 'undefined') {
    // Get the current sheet:
    let currentSheet = sheets[sheets.length - 1]
    // Add the text to current sprite sheet:
    currentSheet.addText(parameters)
    // If the current sprite sheet is full, create a new sheet
    // and add the text:
    if (currentSheet.isFull()) {
      currentSheet = addSheet()
      currentSheet.addText(parameters)
    }
    // Store reference to the sheet for given text:
    sprite[parameters.id] = {
      sheet: currentSheet
    }
  }
}

const getLabel = (id) => {
  // Get UV-coordinates:
  const uv = sprite[id].sheet.getUVs(id)
  // Create the sprite (lazy-load):
  return buildNewSprite(sprite[id].sheet.getTexture(), uv.s, uv.t, uv.u, uv.v)
}

// Create a new sprite sheet:
const addSheet = () => {
  const newSheet = coreSprite.createSheet()
  sheets.push(newSheet)
  return newSheet
}

export default {
  addLabel,
  getLabel
}
