import React from 'react'
import TutorialCard from 'components/Tutorial/TutorialCard'
import { useBoolean } from '@uifabric/react-hooks'
import { transition } from 'styles/constants'
import * as Types from 'lib/types'

type Props = {
  finishText: string
  isMobile: boolean
  startButtonId: string
  onStart: Function
  steps: Types.Steps
}

const Tutorial = (props: Props) => {
  const { finishText, onStart, startButtonId, steps } = props
  const startButton = React.useRef<HTMLButtonElement>(null)
  const [tutorialVisible, { toggle: toggleTutorialVisible }] = useBoolean(false)
  const [isFinished, { toggle: toggleIsFinished }] = useBoolean(false)
  const [currentStep, setCurrentStep] = React.useState(steps[0])

  const onDismiss = React.useCallback(() => {
    toggleTutorialVisible()
    if (isFinished) {
      toggleIsFinished()
      setCurrentStep(steps[0])
    }
  }, [isFinished, toggleIsFinished, steps, toggleTutorialVisible])

  const onAnswerClick = React.useCallback(
    (answer) => {
      if (currentStep.answer === answer) {
        const nextStep = steps[currentStep.index + 1]
        nextStep ? setCurrentStep(nextStep) : toggleIsFinished()
      }
    },
    [currentStep, setCurrentStep, steps, toggleIsFinished]
  )

  const setStartButtonContent = React.useCallback(
    ({ currentStep, isFinished }) => {
      if (startButton.current) {
        if (!isFinished) {
          // @ts-ignore
          const startButtonDescription = startButton.current.description
          // @ts-ignore
          const startButtonLabel = startButton.current.label

          if (
            startButtonLabel.innerText.toLowerCase() !==
            currentStep.headline.toLowerCase()
          ) {
            ;(props.isMobile
              ? startButtonLabel
              : startButtonDescription
            ).addEventListener('transitionend', () => {
              startButtonLabel.innerText = currentStep.headline

              if (!props.isMobile) {
                startButtonDescription.innerText = currentStep.content
                startButtonDescription.style.paddingTop = '8px'
                startButtonDescription.style.height = `${startButtonDescription.scrollHeight}px`
                startButtonDescription.style.opacity = '1'
              }

              startButtonLabel.style.opacity = '1'
            })

            if (!props.isMobile) {
              startButtonDescription.style.transition = transition.opacity
              startButtonDescription.style.opacity = '0'
            }

            startButtonLabel.style.transition = transition.opacity
            startButtonLabel.style.opacity = '0'
          }

          // @ts-ignore
        } else if (startButton.current?.main) {
          // @ts-ignore
          const startButtonMain = startButton.current.main
          startButtonMain.addEventListener(
            'transitionend',
            () => {
              // @ts-ignore
              startButtonMain.style.display = 'none'
            },
            { once: true }
          )
          startButtonMain.style.transition = transition.opacity
          startButtonMain.style.opacity = '0'
        }
      }
    },
    [props.isMobile]
  )

  const onStartButtonClick = React.useCallback(() => {
    setStartButtonContent({ currentStep })
    onStart && onStart()
    toggleTutorialVisible()
  }, [currentStep, onStart, toggleTutorialVisible, setStartButtonContent])

  React.useEffect(() => {
    if (!tutorialVisible) {
      setStartButtonContent({ currentStep, isFinished })
    }
    // eslint-disable-next-line
  }, [tutorialVisible, currentStep])

  React.useEffect(() => {
    // @ts-ignore
    const startButtonElement = document.getElementById(startButtonId)
    let startButtonDescription, startButtonLabel
    if (startButtonElement) {
      startButtonLabel = startButtonElement.children[0].children[0].children[0]
      startButtonDescription =
        startButtonElement.children[0].children[0].children[1]
    }
    // @ts-ignore
    startButton.current = {
      main: startButtonElement,
      description: startButtonDescription,
      label: startButtonLabel
    }
  }, [startButtonId])

  React.useEffect(() => {
    if (startButton.current) {
      // @ts-ignore
      if (startButton.current.main) {
        // @ts-ignore
        startButton.current.main.onclick = onStartButtonClick
      }
    }
  }, [onStartButtonClick])

  React.useEffect(() => {
    setCurrentStep(steps[currentStep.index])
    // eslint-disable-next-line
  }, [steps])

  return (
    <TutorialCard
      currentStep={currentStep}
      isFinished={isFinished}
      onDismiss={onDismiss}
      onAnswerClick={onAnswerClick}
      show={tutorialVisible}
      finishText={finishText}
    />
  )
}

export default Tutorial
