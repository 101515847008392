import * as React from 'react'
import { mergeStyleSets } from 'office-ui-fabric-react'
import { transition } from 'styles/constants'

type Props = {
  fallbackSrc?: any
  src: string | any
  alt?: string
  style?: object
  className?: string
}
const styles = mergeStyleSets({
  loading: {
    opacity: 0,
    filterBrightness: 0,
    filterSaturate: 20
  },
  loaded: {
    opacity: 1,
    filterBrightness: 100,
    filterSaturate: 100
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    transition: `${transition.filterSaturate}, ${transition.filterBrightness}, ${transition.opacity}`
  }
})
const Img = (props: Props) => {
  const [loaded, setLoaded] = React.useState(false)

  return (
    <img
      alt={props.alt}
      data-src={props.src}
      src={props.src}
      style={props.style}
      onLoad={() => setLoaded(true)}
      onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
        if (props.fallbackSrc) {
          event.currentTarget.src = props.fallbackSrc
        } else {
          event.currentTarget.style.display = 'none'
        }
      }}
      className={`${styles.image} ${loaded ? styles.loaded : styles.loading} ${
        props.className ? props.className : ''
      }`}
    />
  )
}

export default Img
