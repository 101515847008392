import React, { useState } from 'react'
import { AppContext } from 'Context'
import { mergeStyleSets } from '@uifabric/styling'
import logo from '../zoic-logo_sm.png'
import '../styles/Footer.css'
import { IconButton, Modal, Text } from 'office-ui-fabric-react'
import { transition, mediaQuery } from 'styles/constants'

const styles = mergeStyleSets({
  footer: {
    padding: '8px 16px',
    zIndex: 1000,
    position: 'fixed',
    maxWidth: '100vw',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    color: 'var(--white)',
    alignItems: 'center',
    transition: `${transition.transformShort}, ${transition.top}`,
    pointerEvents: 'none',
    [mediaQuery.isXs]: {
      height: 60
    }
  },
  link: {
    cursor: 'pointer',
    fontSize: '0.8em',
    display: 'flex',
    pointerEvents: 'all'
  },
  linkSeparator: {
    margin: '0 5px'
  },
  footerRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  modalContent: {
    display: 'flex',
    flexDirection: 'column'
  },
  socialButtons: {
    display: 'flex',
    marginBottom: 8,
    pointerEvents: 'all',
    '& > *:not(:last-child)': {
      marginRight: 4
    },
    [mediaQuery.isXs]: {
      marginBottom: 4
    }
  }
})
const message = 'Check out this LIVE data visualization on the 2020 US election: https://live.zoiclabs.io'.replace(
  / /g,
  '%20'
)

interface ILink {
  title: string
  text: string
}

const links: ILink[] = [
  {
    title: 'Terms of Use',
    text: `
Zoic Labs Terms of Service and Privacy Policy 

1. Terms 
By accessing the website at http://zoiclabs.com, you are agreeing to be bound by these terms of service, all applicable laws and regulations, and agree that you are responsible for compliance with any applicable local laws. If you do not agree with any of these terms, you are prohibited from using or accessing this site. The materials contained in this website are protected by applicable copyright and trademark law. 

2. Use License 
a) Permission is granted to view the content as presented on the website. This is the grant of a license, not a transfer of title, and under this license you may not: i. modify or copy the materials; ii. use the materials for any commercial purpose, or for any public display (commercial or non-commercial); iii. attempt to decompile or reverse engineer any software contained on Zoic Labs' website; iv. remove any copyright or other proprietary notations from the materials; or v. transfer the materials to another person or "mirror" the materials on any other server. 

b) This license shall automatically terminate if you violate any of these restrictions and may be terminated by Zoic Labs at any time. Upon terminating your viewing of these materials or upon the termination of this license, you must destroy any downloaded materials in your possession whether in electronic or printed format. 

3. Disclaimer 
a) The materials on Zoic Labs' website are provided on an 'as is' basis. Zoic Labs makes no warranties, expressed or implied, and hereby disclaims and negates all other warranties including, without limitation, implied warranties or conditions of merchantability, fitness for a particular purpose, or non-infringement of intellectual property or other violation of rights. 

b) Further, Zoic Labs does not warrant or make any representations concerning the accuracy, likely results, or reliability of the use of the materials on its website or otherwise relating to such materials or on any sites linked to this site. 

4. Limitations 
In no event shall Zoic Labs or its suppliers be liable for any damages (including, without limitation, damages for loss of data or profit, or due to business interruption) arising out of the use or inability to use the materials on Zoic Labs' website, even if Zoic Labs or a Zoic Labs authorized representative has been notified orally or in writing of the possibility of such damage. Because some jurisdictions do not allow limitations on implied warranties, or limitations of liability for consequential or incidental damages, these limitations may not apply to you. 

5. Accuracy of materials 
The materials appearing on Zoic Labs' website could include technical, typographical, or photographic errors. Zoic Labs does not warrant that any of the materials on its website are accurate, complete or current. Zoic Labs may make changes to the materials contained on its website at any time without notice. However Zoic Labs does not make any commitment to update the materials. 

6. Links 
Zoic Labs has not reviewed all of the sites linked to its website and is not responsible for the contents of any such linked site. The inclusion of any link does not imply endorsement by Zoic Labs of the site. Use of any such linked website is at the user's own risk. Such third party sites and content are subject to such website terms and conditions. 

7. Modifications 
Zoic Labs may revise these terms of service for its website at any time without notice. By using this website you are agreeing to be bound by the then current version of these terms of service. 

8. Governing Law 
These terms and conditions are governed by and construed in accordance with the laws of CA and you irrevocably submit to the exclusive jurisdiction of the courts in that State or location. 

9. Privacy Policy
COPPA Zoic Labs offers to its users a range of applications which are generally intended for users of all ages and their families. Our applications offer a variety of activities, including activities that may collect information from children. Below we summarize potential instances of collection and outline how and when we will provide parental notice and/or seek parental consent. In any instance that we collect personal information from a child, we will retain that information only so long as reasonably necessary to fulfill the activity request or allow the child to continue to participate in the activity, and ensure the security of our users and our services, or as required by law. In the event we discover we have collected information from a child in a manner inconsistent with COPPAs requirements, we will either delete the information or immediately seek the parent’s consent for that collection. 

10. Digital Millennium Copyright Act

If you are a copyright owner or an agent thereof and believe that any Content, including without limitation any User Content, infringes upon your copyrights, you may submit a notification pursuant to the Digital Millennium Copyright Act ("DMCA") by providing our Copyright Agent with the following information in writing (see 17 U.S.C 512(c)(3) for further detail): 

a) A physical or electronic signature of a person authorized to act on behalf of the owner of an exclusive right that is allegedly infringed; 

b) Identification of the copyrighted work claimed to have been infringed, or, if multiple copyrighted works at a single online site are covered by a single notification, a representative list of such works at that site; 

c) Identification of the material that is claimed to be infringing or to be the subject of infringing activity and that is to be removed or access to which is to be disabled and information reasonably sufficient to permit the service provider to locate the material; 

d) Information reasonably sufficient to permit the service provider to contact you, such as an address, telephone number, and, if available, an electronic mail; 

e) A statement that you have a good faith belief that use of the material in the manner complained of is not authorized by the copyright owner, its agent, or the law; 
f) A statement that the information in the notification is accurate, and under penalty of perjury, that you are authorized to act on behalf of the owner of an exclusive right that is allegedly infringed. The Company’s designated Copyright Agent to receive notifications of claimed infringement is: Wolf, Rifkin, Shapiro, Schulman & Rabkin, LLP, 11400 W. Olympic Blvd., 9th Floor, Los Angeles, California 90064, Attn: Michael Wolf, Esq.. For clarity, only DMCA notices should go to the Copyright Agent. You acknowledge that if you fail to comply with all of the requirements of this Section, your DMCA notice may not be valid. 

11. Notice for California Users. Under California Civil Code Section 1789.3, California users of the Website are entitled to the following specific consumer rights notice: The Complaint Assistance Unit of the Division of Consumer Services of the California Department of Consumer Affairs may be contacted in writing at 1625 North Market Blvd., Sacramento, CA 95834, or by telephone at (916) 445-1254 or (800) 952-5210. 

Your privacy is important to us. It is Zoic Labs' policy to respect your privacy regarding any information we may collect while operating our website. Accordingly, we have developed this privacy policy in order for you to understand how we collect, use, communicate, disclose and otherwise make use of personal information. We have outlined our privacy policy below. We will collect personal information by lawful and fair means and, where appropriate, with the knowledge or consent of the individual concerned. Before or at the time of collecting personal information, we will identify the purposes for which information is being collected. We will collect and use personal information solely for fulfilling those purposes specified by us and for other ancillary purposes, unless we obtain the consent of the individual concerned or as required by law. Personal data should be relevant to the purposes for which it is to be used, and, to the extent necessary for those purposes, should be accurate, complete, and up-to-date. We will protect personal information by using reasonable security safeguards against loss or theft, as well as unauthorized access, disclosure, copying, use or modification. We will make readily available to customers information about our policies and practices relating to the management of personal information. We will only retain personal information for as long as necessary for the fulfilment of those purposes. We are committed to conducting our business in accordance with these principles in order to ensure that the confidentiality of personal information is protected and maintained. Zoic Labs may change this privacy policy from time to time at Zoic Labs' sole discretion.



`
  },
  {
    title: 'About Us',
    text: `
Zoic Labs, established in 2013, is a software and advanced visualization company focused on big data, emerging technologies, and groundbreaking design. It provides software development, R&D, and UI/UX design related to virtual reality, augmented reality, analytics and visualization, and artificial intelligence for a diverse range of private companies, pharmaceutical and healthcare firms, and the U.S. Intelligence Community and Department of Defense.

Zoic Labs’ proprietary web-based data visualization platform (Cognitive®) ingests, aggregates, and displays massive disparate datasets in a 3D rendered “game environment.”  Sister company Zoic Studios has garnered three Emmy Awards and countless other awards and accolades for its work in movies, television, and multimedia.  Collectively ZOIC has 350+ employees, with offices in Los Angeles, Vancouver, and New York City. 
    `
  },
  {
    title: 'About the Data',
    text: `
LIVE by Zoic connects to the Twitter API which assigns values to each tweet based on their topic, domain, location, source, and username.

This application is designed to provide the user with candidate data (topic), to organize the type of user behind the tweet (domain), and categorize the type of user device (source.)

After Twitter defines each tweet, we use Amazon Comprehend to assign a sentiment based on Twitter’s definition of each tweet. Please note that tweets can contain multiple topics and domains so they may appear in multiple places with those layers active.
`
  }
]

const Footer = () => {
  const footerRef = React.useRef<HTMLDivElement>(null)
  const { petri, screenSize, windowDimensions } = React.useContext(AppContext)
  const isMobile = screenSize === 'xs'
  const hideHeaderFooter = React.useMemo(
    () => petri.breadcrumb.length > 1 || petri.onLastLayer,
    [petri.breadcrumb, petri.onLastLayer]
  )
  const [modalLink, setModalLink] = useState<ILink | null>(null)

  const onClickLogo = () => {
    window.open(`https://www.zoiclabs.com`)
  }

  const onClickFacebook = () => {
    window.open(
      `https://www.facebook.com/sharer/sharer.php?u=https://www.zoiclabs.com&quote=${message}&hashtag=LIVEbyZoic`,
      'pop',
      'width=600, height=400, scrollbars=no'
    )
  }

  const onClickLinkedIn = () => {
    window.open(
      `https://www.linkedin.com/sharing/share-offsite/?url=https://zoiclabs.com`
    )
  }
  return (
    <>
      <div
        ref={footerRef}
        className={styles.footer}
        style={{
          transform: `translateY(${hideHeaderFooter ? '100' : '0'}px)`,
          top: windowDimensions.height - (footerRef?.current?.clientHeight || 0)
        }}
      >
        <img
          alt="logo"
          style={{
            pointerEvents: 'all',
            cursor: 'pointer',
            height: isMobile ? 40 : 80
          }}
          src={logo}
          onClick={onClickLogo}
        />

        <div className={styles.footerRight}>
          <div className={styles.socialButtons}>
            <a
              className="fa fa-twitter"
              target="_blank"
              rel="noopener noreferrer"
              href={`https://twitter.com/intent/tweet?text=${message}&via=zoiclabs&hashtags=LIVEbyZoic`}
            >
              <span style={{ display: 'none' }}>
                Open twitter in new window
              </span>
            </a>

            <div className="fa fa-facebook" onClick={onClickFacebook} />
            <div className="fa fa-linkedin" onClick={onClickLinkedIn} />
          </div>

          {!isMobile && (
            <>
              <Text variant={'small'}>
                © 2020 Zoic Labs. All Rights Reserved. Patent No. US 10,318,545
                B1
              </Text>

              <div style={{ display: 'flex', marginTop: 4 }}>
                {links.map((link, index) => (
                  <Text
                    as={'a'}
                    key={link.title}
                    className={styles.link}
                    onClick={() => setModalLink(link)}
                    variant={'smallPlus'}
                  >
                    {link.title}
                    {index !== links.length - 1 && (
                      <div className={styles.linkSeparator}> | </div>
                    )}
                  </Text>
                ))}
              </div>
            </>
          )}
        </div>
      </div>

      <Modal
        isOpen={Boolean(modalLink)}
        onDismiss={() => setModalLink(null)}
        styles={{
          main: {
            width: 400,
            overflow: 'hidden',

            whiteSpace: 'pre-wrap'
          },
          scrollableContent: {
            // background: 'var(--black)',
            color: 'var(--white)',
            padding: 16,
            maxHeight: 500
          }
        }}
      >
        <div className={styles.modalContent}>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'flex-end'
            }}
          >
            <IconButton
              iconProps={{ iconName: 'Cancel' }}
              ariaLabel="Close popup modal"
              onClick={() => setModalLink(null)}
            />
          </div>
          {modalLink?.text}
        </div>
      </Modal>
    </>
  )
}

export default Footer
