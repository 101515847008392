import {
  loadGeometry,
  loadMaterial,
  loadImage,
  loadTexture,
  loadObject
} from '../../Petri.utils'

export default {
  load
}

function load(json) {
  if (typeof loaders[json.type] !== 'undefined') {
    return loaders[json.type](json.data)
  }
  console.error('Loader is not available for this json object.')
}

const loaders = {
  geometry: loadGeometry,
  material: loadMaterial,
  texture: loadTexture,
  object: loadObject,
  image: loadImage
}
