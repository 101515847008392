import React, { useRef } from 'react'
import { useDrag, useDrop, DropTargetMonitor } from 'react-dnd'
import { XYCoord } from 'dnd-core'
import { IconButton, mergeStyleSets, Text } from 'office-ui-fabric-react'
import { boxShadow, transition } from 'styles/constants'

const styles = mergeStyleSets({
  card: {
    boxShadow: boxShadow.elevation4,
    borderRadius: 2
  },
  percent: {
    height: 5,
    backgroundColor: 'rgba(255, 128, 0, 1)',
    boxShadow:
      'inset 0px 0px 5px rgba(255, 255, 255, 1), 0px 0px 15px rgba(255, 128, 0, 1)',
    width: 0,
    borderRadius: 10
  }
})

export const style = {
  border: '1px dashed gray',
  padding: 10,
  marginBottom: '.5rem',
  backgroundColor: 'var(--surfaceElements)',
  cursor: 'move',
  color: 'var(--white)',
  fontWeight: 'bold',
  display: 'flex',
  alignItems: 'flex-start',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  fontSize: '1.1em',
  position: 'relative'
} as React.CSSProperties

export interface IPopulationData {
  id: string
  percent: number
}

export interface CardProps {
  id: any
  text: string
  index: number
  moveCard: (dragIndex: number, hoverIndex: number) => void
  onClickDelete: () => void
  populationData?: IPopulationData[]
  onDrag?: (isDragging?: boolean) => void
  onDragStart?: () => void
  onDragEnd?: () => void
}

interface DragItem {
  index: number
  id: string
  type: string
}
export const Card: React.FC<CardProps> = ({
  id,
  text,
  index,
  moveCard,
  onClickDelete,
  populationData,
  onDragStart,
  onDragEnd
}) => {
  const ref = useRef<HTMLDivElement>(null)
  const [, drop] = useDrop({
    accept: 'card',
    hover(item: DragItem, monitor: DropTargetMonitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    }
  })

  const [{ isDragging }, drag] = useDrag({
    item: { type: 'card', id, index },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging()
    })
  })

  const opacity = isDragging ? 0 : 1

  drag(drop(ref))
  return (
    <div
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      ref={ref}
      style={{ ...style, opacity }}
      className={styles.card}
    >
      <Text variant={'mediumPlus'}>{text}</Text>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: 6,
          width: '100%'
        }}
      >
        {populationData?.map((pop) => {
          const percent = pop.percent * 100
          return (
            <div
              key={pop.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                marginBottom: 6,
                fontWeight: 100,
                fontSize: '0.9em'
              }}
            >
              <div
                title={pop.id}
                style={{
                  width: 110,
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  textAlign: 'left'
                }}
              >
                <Text variant={'medium'}> {pop.id}</Text>
              </div>

              <div
                style={{
                  display: 'flex',
                  width: 'calc(100% - 120px)',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  marginLeft: 10
                }}
              >
                <div
                  style={{
                    marginRight: 10,
                    width: 35,
                    transition: transition.opacity,
                    opacity: percent ? 1 : 0
                  }}
                >
                  <Text variant={'medium'}>{Math.floor(percent)}%</Text>
                </div>
                <div
                  className={styles.percent}
                  style={{ width: `${pop.percent * 100}%` }}
                />
              </div>
            </div>
          )
        })}
      </div>

      <IconButton
        iconProps={{
          iconName: 'Cancel'
        }}
        onClick={onClickDelete}
        style={{
          color: 'var(--white)',
          position: 'absolute',
          top: 0,
          right: 0
        }}
      />
    </div>
  )
}
