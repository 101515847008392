export const BREADCRUMB_POP = 'BREADCRUMB_POP'
export const BREADCRUMB_PUSH = 'BREADCRUMB_PUSH'
export const BREADCRUMB_JUMP = 'BREADCRUMB_JUMP'
export const BREADCRUMB_REPLACE = 'BREADCRUMB_REPLACE'
export const RECORD_LAYER_AFTER_ZOOM = 'RECORD_LAYER_AFTER_ZOOM'

export const PIE_RING_ON_CLICK = 'PIE_RING_ON_CLICK'
export const PIE_RING_ON_HOVER = 'PIE_RING_ON_HOVER'
export const PIE_RING_OFF_HOVER = 'PIE_RING_OFF_HOVER'

export const POPULATION_POP = 'POPULATION_POP'
export const POPULATION_PUSH = 'POPULATION_PUSH'
export const POPULATION_REPLACE = 'POPULATION_REPLACE'
export const POPULATION_ON_HOVER = 'POPULATION_ON_HOVER'
export const POPULATION_OFF_HOVER = 'POPULATION_OFF_HOVER'
export const POPULATION_DRAW_INITIATED = 'POPULATION_DRAW_INITIATED'
export const POPULATION_DRAW_COMPLETED = 'POPULATION_DRAW_COMPLETED'

export const RECORD_LAYER_ON_CLICK = 'RECORD_LAYER_ON_CLICK'
export const RECORD_LAYER_ON_HOVER = 'RECORD_LAYER_ON_HOVER'
export const RECORD_LAYER_OFF_HOVER = 'RECORD_LAYER_OFF_HOVER'
