import THREE from 'lib/threejs'
import { buildNewSprite } from '../../lib/Petri.utils'

export default {
  init
}
function init(canvasView, C3D) {
  let methods = {
    getScene,
    getCamera,
    getRenderer,
    getViewport,
    getComposer,
    getObjects,

    getPhysicsWorld,
    setPhysicsWorld,

    setRenderable,
    isRenderable,

    setAnimated,
    isAnimated,

    attachUpdate,
    getUpdate,
    attachMouseHandler,
    getUpdateMouse,

    resizeViewport,
    buildComposer,
    addFxPasses,
    buildCamera,
    buildLight,
    buildMesh,
    addMesh,
    buildSprite,
    findNameByuuid,
    remove,
    removeAll,
    parseMaterialName,

    removeObjectByUuid,

    destroy,

    buildControls,
    getControls,
    getCannonDebugRenderer,
    setCannonDebugRenderer,
    debugLine,
    debugSphere
  }

  // CoreScene is the prototype of ALL scenes. Angular-land's directive thinks
  // of these as viewports instead of scenes.
  let renderable = false // true if done initializing
  let animated = false // true if first animation frame has been called
  let renderer = null // Three.js renderer
  let scene = null // Three.js scene
  let camera = null // Three.js camera
  let world = null // Cannon.js physics world
  let lights = {} // lights in this scene
  let objects = {} // objects in this scene
  let update = null // This scenes master update() method
  let updateMouse = null // This scenes master mouse update method
  let viewport = canvasView // canvas viewport information

  let effectFXAA, bloomPass, renderScene, composer

  // let axisHelper = null
  let controls = null
  let cannonDebugRenderer = null

  const viewWidth = viewport.viewWidth
  const viewHeight = viewport.viewHeight
  // Setup the WebGL Renderer and add it to the DOM:
  renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true })
  renderer.setSize(viewWidth, viewHeight)
  renderer.setPixelRatio(window.devicePixelRatio)
  renderer.toneMapping = THREE.LinearToneMapping
  renderer.sortObjects = true
  // Get the renderer to show shadows:
  renderer.shadowMap.enabled = true
  // renderer.setFaceCulling(THREE.CullFaceBack)
  // renderer.shadowMapCullFrontFaces = false
  // Shadow Map type can be one of these:
  // THREE.BasicShadowMap | THREE.PCFShadowMap | THREE.PCFSoftShadowMap
  renderer.shadowMap.type = THREE.PCFSoftShadowMap
  renderer.toneMapping = THREE.LinearToneMapping
  viewport.view.appendChild(renderer.domElement)
  scene = new THREE.Scene()
  // console.log('CoreScene: Scene UUID: ', scene.uuid)
  // console.log('scene:', scene)

  // Add axis helper:
  // axisHelper = new THREE.AxisHelper(1000)
  //scene.add(axisHelper)

  return methods

  function getScene() {
    return scene
  }

  function getCamera() {
    return camera
  }

  function getRenderer() {
    return renderer
  }

  function getViewport() {
    return viewport
  }

  function getComposer() {
    return composer
  }

  function getObjects() {
    return objects
  }

  function getPhysicsWorld() {
    return world
  }

  function setPhysicsWorld(physicsWorld) {
    world = physicsWorld
  }

  function setRenderable() {
    renderable = true
  }

  function isRenderable() {
    return renderable
  }

  function setAnimated() {
    animated = true
  }

  function isAnimated() {
    return animated
  }

  function resizeViewport(resizeEvent) {
    // console.warn('CS: resize', resizeEvent)
    const newWidth = resizeEvent.width
    const newHeight = resizeEvent.height

    // NOTE: This camera is a RAW THREE.JS CAMERA! NOT an instance of core-camera.
    // TODO: Convert this camera stuff to use core-camera so we can just call resizeCamera() here...
    if (camera) {
      // console.log('CS: Camera:', camera, newWidth, newHeight, newWidth/newHeight)
      camera.aspect = newWidth / newHeight
      camera.left = newWidth / -2
      camera.right = newWidth / 2
      camera.top = newHeight / 2
      camera.bottom = newHeight / -2
      camera.updateProjectionMatrix()
    }

    const pixelRatio = window.devicePixelRatio
    viewport.viewWidth = (newWidth * pixelRatio) | 0
    viewport.viewHeight = (newHeight * pixelRatio) | 0
    renderer.setSize(newWidth, newHeight)

    if (composer) {
      composer.setSize(newWidth, newHeight)
      effectFXAA.uniforms['resolution'].value.set(1 / newWidth, 1 / newHeight)
    }
  }

  function buildCamera(parameters) {
    let cameraJson = { object: parameters }
    camera = new THREE.ObjectLoader().parse(cameraJson)

    // HACK: This should be removed once v74 or Three.js is released.
    // THREE.ObjectLoader() doesn't import zoom param
    // if (typeof cameraJson.object.zoom !== 'undefined') {
    //   camera.zoom = cameraJson.object.zoom
    //   camera.updateProjectionMatrix()
    // }

    scene.add(camera)
    return camera
  }

  function buildComposer() {
    renderScene = new THREE.RenderPass(scene, camera)

    // renderScene.clear = true
    // renderer.toneMapping = THREE.ReinhardToneMapping
    renderer.gammaInput = true
    renderer.gammaOutput = true

    composer = new THREE.EffectComposer(renderer)
    composer.setSize(window.innerWidth, window.innerHeight)
    composer.addPass(renderScene)

    addFxPasses(composer)

    return composer
  }

  function addFxPasses(composerArg) {
    effectFXAA = new THREE.ShaderPass(THREE.FXAAShader)
    effectFXAA.uniforms['resolution'].value.set(
      1 / window.innerWidth,
      1 / window.innerHeight
    )
    composerArg.addPass(effectFXAA)

    let bloomParams = {
      resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
      strength: 1,
      radius: 1,
      threshold: 0.7
    }
    bloomPass = new THREE.UnrealBloomPass(
      bloomParams.resolution,
      bloomParams.strength,
      bloomParams.radius,
      bloomParams.threshold
    )
    composerArg.addPass(bloomPass)

    let copyShader = new THREE.ShaderPass(THREE.CopyShader)
    copyShader.renderToScreen = true
    composerArg.addPass(copyShader)

    return composerArg
  }

  function buildLight(lightName, parameters) {
    // Configure the lights Shadow Camera parameters:
    // light.castShadow = true
    // light.shadowCameraNear = 0.1
    // light.shadowCameraFar = petriScene.camera.far
    // light.shadowCameraFov = 50
    // light.shadowMapBias = 0.0039
    // light.shadowMapDarkness = 0.5
    // light.shadowMapWidth = 1024
    // light.shadowMapHeight = 1024
    // var shadowCameraSize = cameraDistance
    // light.shadowCameraLeft = -shadowCameraSize
    // light.shadowCameraRight = shadowCameraSize
    // light.shadowCameraTop = shadowCameraSize
    // light.shadowCameraBottom = -shadowCameraSize

    // THREE expects the parameters to be under the "object" key:
    let lightJson = { object: parameters }
    lights[lightName] = new THREE.ObjectLoader().parse(lightJson)
    scene.add(lights[lightName])
    return lights[lightName]
  }

  function buildMesh(geometryName, materialName, name) {
    // TODO: Possibly promisify this in the future:
    let geometry = C3D.geometries[geometryName]
    let material = parseMaterialName(materialName).clone()

    // add texture map if available:
    if (material.map !== null) {
      material.map = C3D.textures[materialName]
    }
    // add texture envMap if available:
    if (material.envMap !== null) {
      let textureName = material.envMap
      C3D.textures[textureName].mapping = THREE.SphericalReflectionMapping
      material.envMap = C3D.textures[textureName]
    }

    if (material.displacementMap !== null) {
      material.displacementMap = C3D.textures[`${materialName}Displacement`]
    }

    if (material.alphaMap !== null) {
      material.alphaMap = C3D.textures[`${materialName}Alpha`]
    }

    let newMesh = new THREE.Mesh(geometry, material)
    if (objects[name] !== undefined) {
      name += THREE.Math.generateUUID()
      // console.error('Mesh name collision. Appending UUID: ' + name)
    }
    newMesh.name = name

    // Add Mesh to list of core-scene object3Ds:
    const uuid = newMesh.uuid
    objects[uuid] = newMesh

    return newMesh
  }

  function addMesh(obj) {
    scene.add(obj)
  }

  function buildSprite(canvasElement, s, t, u, v, histValue) {
    // UV-coordinates (s, t, u, v) are defined as follows:

    // (s, t)        (u, t)
    //   *-------------*
    //   |             |
    //   |             |
    //   *-------------*
    // (s, v)        (u, v)

    //******
    // TODO: Should do loading somewhere else? Maybe in a Sprite manager?
    // Otherwise this negates the desired performance gain...Maybe put in setImage in coreSprite?
    // Or call it in the callback used in setImage
    let width, height, texture

    // If it's simply a url:
    if (typeof canvasElement === 'string') {
      // May need an onLoad callback in THREE.TextureLoader argument...
      texture = new THREE.TextureLoader().load(canvasElement)
    }

    // If it's a canvas object:
    if (
      typeof canvasElement === 'object' &&
      canvasElement.tagName === 'CANVAS'
    ) {
      width = canvasElement.width
      height = canvasElement.height
      // texture = new THREE.TextureLoader().load(canvasElement.toDataURL())
      texture = new THREE.Texture(canvasElement)
      texture.needsUpdate = true

      // This allows a sane method of accessing the UV-coordinates...
      texture.repeat.set(1 / width, 1 / height)
    }

    //******

    const newSprite = buildNewSprite(texture, s, t, u, v, histValue)
    // // Check if UV-coordinates are within image limits
    // if (s < 0 || t > width || u < 0 || v > height) {
    //   console.log('Out of bounds')
    //   return
    // }
    //
    // // Determine scale factor:
    // let scale = 1 / Math.sqrt((u - s) * (v - t))
    //
    // // Custom Sprite since THREE.js's native Sprite doesn't work
    // let mat = new THREE.MeshBasicMaterial({
    //   map: texture,
    //   depthTest: false,
    //   depthWrite: false
    // })
    // mat.transparent = true
    // // Make a new blank geometry:
    // let geom = new THREE.Geometry()
    //
    // // Add vertices to the geometry:
    // if (typeof histValue !== 'undefined') {
    //   // For histogram sprites:
    //   geom.vertices.push(new THREE.Vector3(-1 / 2, histValue - 1 / 2, 0))
    //   geom.vertices.push(new THREE.Vector3(-1 / 2, -1 / 2, 0))
    //   geom.vertices.push(new THREE.Vector3(1 / 2, -1 / 2, 0))
    //   geom.vertices.push(new THREE.Vector3(1 / 2, histValue - 1 / 2, 0))
    // } else {
    //   // For all other sprites (currently just labels):
    //   geom.vertices.push(new THREE.Vector3((-scale * (u - s)) / 2, (scale * (v - t)) / 2, 0))
    //   geom.vertices.push(new THREE.Vector3((-scale * (u - s)) / 2, (-scale * (v - t)) / 2, 0))
    //   geom.vertices.push(new THREE.Vector3((scale * (u - s)) / 2, (-scale * (v - t)) / 2, 0))
    //   geom.vertices.push(new THREE.Vector3((scale * (u - s)) / 2, (scale * (v - t)) / 2, 0))
    // }
    //
    // // Set the geometry faces:
    // geom.faces.push(new THREE.Face3(0, 1, 2))
    // geom.faces.push(new THREE.Face3(2, 3, 0))
    //
    // // Set the UV's for the faces to select portion of texture to display:
    // geom.faceVertexUvs[0].push([new THREE.Vector2(s, height - t), new THREE.Vector2(s, height - v), new THREE.Vector2(u, height - v)])
    // geom.faceVertexUvs[0].push([new THREE.Vector2(u, height - v), new THREE.Vector2(u, height - t), new THREE.Vector2(s, height - t)])
    // geom.faces[0].normal.set(0, 0, 1)
    //
    // // Create the Sprite mesh:
    // let newSprite = new THREE.Mesh(geom, mat)

    const uuid = newSprite.uuid

    // Add Sprite to list of core-scene object3Ds:
    objects[uuid] = newSprite

    return newSprite
  }

  function removeObjectByUuid(uuid) {
    let toRemove = objects[uuid]
    if (typeof toRemove !== 'undefined') {
      scene.remove(toRemove)
      if (typeof toRemove.geometry !== 'undefined') {
        toRemove.geometry.dispose()
      }
      if (typeof toRemove.material !== 'undefined') {
        if (toRemove.material.map !== null) {
          toRemove.material.map.dispose()
        }
        if (toRemove.material.envMap !== null) {
          toRemove.material.envMap.dispose()
        }
        toRemove.material.dispose()
      }
      delete objects[uuid]

      // This flag is currently here to determine whether or not object is created/removed within core-scene
      return true
    }
    return false
  }

  function findNameByuuid(uuid) {
    let toRet = null
    Object.keys(objects).forEach(function (key) {
      if (objects[key].uuid === uuid) {
        toRet = key
      }
    })
    return toRet
  }

  function remove(name) {
    let toRemove = objects[name]
    if (typeof toRemove !== 'undefined') {
      let foundChild = scene.getObjectByName(name)
      let indexOfChild = scene.children.indexOf(foundChild)

      if (indexOfChild >= 0) {
        console.log('Found child to remove!')

        scene.remove(foundChild)
        delete objects[name]
      } else if (indexOfChild < 0 && typeof foundChild !== 'undefined') {
        // console.log('Not an immediate child. We will have to recurse for removal')
        let defaultNode = scene.getObjectByName('root')
        defaultNode.traverse(function (aChild) {
          if (aChild.children.indexOf(foundChild) >= 0) {
            aChild.remove(foundChild)
            delete objects[name]
          }
        })

        // This may work better:
        // foundChild.parent.remove(foundChild)
        // delete objects[name]
      }
    }
  } // end remove

  function removeAll() {
    Object.keys(objects).forEach(function (child) {
      // let toRemove = objects[child]
      // scene.remove(toRemove)
      // delete objects[name]

      // let toRemove = objects[child]
      // scene.remove(toRemove)

      removeObjectByUuid(child)
      delete objects[child]
    })
  }

  function parseMaterialName(materialName) {
    let material
    if (materialName !== undefined) {
      material = C3D.materials[materialName]
    } else {
      material = new THREE.MeshPhongMaterial({
        color: 0xffffff,
        specular: 0xffffff,
        side: THREE.BackSide
        // side: THREE.BothSide
        // wireframe: true
      })
    }
    return material
  }

  function attachUpdate(callback) {
    update = callback
  }

  function getUpdate() {
    return update
  }

  function attachMouseHandler(callback) {
    updateMouse = callback
  }

  function getUpdateMouse() {
    return updateMouse
  }

  function destroy() {
    console.log('Destroying everything in this viewport...')

    renderable = false
    update = null
    updateMouse = null

    removeAll() // remove all objects
    renderer = null
  }

  function buildControls() {
    controls = new THREE.OrbitControls(camera, renderer.domElement)
    controls.target.set(0, 0, -10)
  }

  function getControls() {
    return controls
  }

  function getCannonDebugRenderer() {
    return cannonDebugRenderer
  }

  function setCannonDebugRenderer() {
    cannonDebugRenderer = new THREE.CannonDebugRenderer(scene, world)
  }

  function debugLine(parent, start, end, color) {
    if (typeof color === 'undefined') {
      color = 0xff0000
    }
    let material = new THREE.LineBasicMaterial({
      color: color
    })
    let geometry = new THREE.Geometry()
    geometry.vertices.push(start, end)
    let line = new THREE.Line(geometry, material)
    parent.add(line)
  }

  function debugSphere(parent, radius, position) {
    let geometry = new THREE.SphereGeometry(radius, 10, 10)
    let material = C3D.materials['transDebug']
    let sphere = new THREE.Mesh(geometry, material)
    sphere.position.copy(position)
    parent.add(sphere)
  }
}
