// import THREE from "lib/threejs";
import * as THREE from 'three'

import coreScene from './core-scene'
import coreLoader from './core-loader'
import coreCamera from './core-camera'
import coreMouse from './core-mouse'
import coreLabel from './core-label'

export { coreLabel }

export default {
  init,
  stopAnimating,
  createViewport,
  initScene,
  initCamera,
  getMouse,
  destroyViewport,
  load,
  grabTextures,
  animate
}

// C3D is the main container object for all Core3D data:
let C3D = {
  scenes: {}, // a collection of coreScene instances
  objects: {},
  textures: {},
  materials: {},
  geometries: {},
  lights: {},
  cameras: {},
  stats: null
}

// TODO: Clean this up so it sits in the viewport itself
let showFPS = false //utils.showFPS()

let animationRequest

function init() {
  // This starts core3d's main animation loop. This means all viewports are
  // bound to the same loop. This is required due to the way
  // requestAnimationFrame takes parameters. The only way James can think of
  // fixing this is to have hard references to each scene's animation
  // return animationRequest = requestAnimationFrame(animate)
  //THREE.ObjectLoader(THREE)

  if (typeof animationRequest === 'undefined') {
    // animating = true
    animationRequest = requestAnimationFrame(animate)
    return animationRequest
  }
}

function stopAnimating() {
  if (typeof animationRequest !== 'undefined') {
    cancelAnimationFrame(animationRequest)
    animationRequest = undefined
  }
}

function createViewport(viewportElement) {
  // Make sure we have a place to place the WebGLRenderer
  if (typeof viewportElement === 'undefined') {
    console.error('Core3d: No element given to createViewport!')
    return
  }

  // Setup the scene's viewport using it's context on the page:
  let view = viewportElement
  let viewBox = view.getBoundingClientRect()
  let viewWidth = viewBox.right - viewBox.left
  let viewHeight = viewBox.bottom - viewBox.top

  return {
    view,
    viewBox,
    viewWidth,
    viewHeight
  }
} // createViewport

function initScene(viewport) {
  const scene = coreScene.init(viewport, C3D)
  const sceneId = scene.getScene().uuid
  C3D.scenes[sceneId] = scene // Store this coreScene instance
  return scene
}

function initCamera(scene, params) {
  const camera = coreCamera.init(scene, params)
  const cameraId = camera.getCamera().uuid
  scene.cameras = scene.cameras || {}
  scene.cameras[cameraId] = camera
  return camera
}

function getMouse() {
  return coreMouse
}

function destroyViewport(sceneId) {
  if (typeof C3D.scenes[sceneId] !== 'undefined') {
    C3D.scenes[sceneId].destroy()
    delete C3D.scenes[sceneId]
  } else {
    console.warn(
      "Couldn't find a scene that matched that UUID. This will probably cause a memory leak..."
    )
  }
}

function load(json) {
  return coreLoader
    .load(json)
    .then((asset) => {
      switch (json.type) {
        case 'geometry':
          C3D.geometries[json.name] = asset
          break
        case 'material':
          C3D.materials[json.name] = asset
          break
        case 'texture':
          C3D.textures[json.name] = asset
          break
        case 'object':
          asset.traverse((child) => {
            if (child instanceof THREE.Mesh) {
              C3D.geometries[json.name] = child.geometry
            }
          })
          C3D.objects[json.name] = asset
          break
        case 'image':
          C3D.images[json.name] = asset
          break
        default:
          console.log('No case available')
      }
      return asset
    })
    .catch((error) => {
      console.error('Failed to load asset', json.name, ', Error:', error)
    })
}

function grabTextures() {
  return C3D.textures
}

function animate(timestamp) {
  if (showFPS) {
    C3D.stats.begin()
  }

  animationRequest = requestAnimationFrame(animate)

  Object.keys(C3D.scenes).forEach((sceneId) => {
    // Grab the CoreScene object using this sceneID:
    let scene = C3D.scenes[sceneId]

    // Let the CoreScene instance update itself based on any mouse input
    if (scene.getUpdateMouse() !== null) {
      scene.getUpdateMouse()(scene, timestamp)
    }

    // Only update scenes that have an update method attached to them:
    if (scene.getUpdate() !== null) {
      scene.getUpdate()(scene, timestamp)
    }

    // Only render scenes that are ready to be rendered:
    if (scene.isRenderable() && scene.getCamera() !== null) {
      if (!scene.isAnimated()) {
        // coreScene.buildComposer()
        scene.setAnimated()
      }
      // coreScene.getComposer().render()
      scene.getRenderer().render(scene.getScene(), scene.getCamera())
    }
  })

  if (showFPS) {
    C3D.stats.end()
  }
} // animate
