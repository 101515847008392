import * as THREE from 'three'

export default {
  init,
  get,
  show,
  hide,
  reset,
  attach,
  detach,
  destroy,
  getPercentRange,
  getSelected,
  setSelected,
  getContainer,
  objToScreenPosition,
  resetSliders,
  updateSliders,
  updateSlidersLive,
  updateMarker,
  // updateLabels,
  // setLeftLabel,
  // setRightLabel,
  // debug
  //drawDebugMarkers,
  updateDebugMarker,
  toAngle
}

// Create container:
const sliderContainer = new THREE.Group()
sliderContainer.name = 'sliderContainer'
sliderContainer.position.setZ(6)
sliderContainer.renderOrder = 2
sliderContainer.layers.mask = 2

// Initialize slider references:
let mid = null
let left = null
let right = null
let line = null
let marker = null

// Initialize slider properties:
const sliderTheta = {
  left: {
    start: 0,
    length: Math.PI
  },
  right: {
    start: Math.PI,
    length: Math.PI
  },
  mid: {
    start: 0,
    length: 2 * Math.PI
  }
}
const sliderColor = 0xff8000
const sliderHoverColor = 0xff0000
// Initialize slider position variables:
const currentRadius = 0.47
const paddingPercent = 0.11
const outerRadius = currentRadius + currentRadius * 2 * paddingPercent
const midRadius = 0.01
const sliderRadius = 0.025
// Offset the slider position based on slider radius relative to
// parent radius:
const sliderOffset = Math.atan(sliderRadius / outerRadius)
const startAngle = 0 - sliderOffset
const startX = outerRadius * Math.cos(startAngle)
const startY = outerRadius * Math.sin(startAngle)
const zIndex = 0.9
const endAngle = Math.PI * -2 + sliderOffset
const range = startAngle - endAngle
let markerposition = 0
// Init debug markers variables:
let debugContainer = null
// let debugMarkers = []
// debug tool:
//let debugLayers = 1
// let debugTimeSlices = 100
// Calculate  objects correct offsets from center of the petri:
// let offsetRadius = outerRadius * 0.1
// let markerSizeMax = outerRadius * 0.1

// let leftLabel = null
// let rightLabel = null
let pickedSlider = null
const percentRange = {
  start: 0,
  end: 0
}
const polygonOffsetParams = {
  polygonOffset: true,
  polygonOffsetUnits: 1,
  polygonOffsetFactor: -2
}

function init() {
  // Sliders:
  mid = createSlider(midRadius, 'mid', false)
  left = createSlider(sliderRadius, 'left', true)
  right = createSlider(sliderRadius, 'right', true)

  // Line:
  const material = new THREE.LineBasicMaterial({
    color: 0xffffff,
    ...polygonOffsetParams
  })
  const geometry = new THREE.BufferGeometry()
  const positions = new Float32Array(360 * 3) // 3 vertices per point
  geometry.setAttribute('position', new THREE.BufferAttribute(positions, 3))
  geometry.setDrawRange(0, 2)
  line = new THREE.Line(geometry, material)
  line.name = 'line'
  line.type = 'sliderLine'

  // dateSlider marker:
  const markerMat = new THREE.LineBasicMaterial({
    color: 0xffffff,
    ...polygonOffsetParams
  })
  const markerGeo = new THREE.Geometry()

  const markerSize = outerRadius * 0.03
  markerGeo.vertices.push(
    new THREE.Vector3(markerSize, -markerSize / 2, 0),
    new THREE.Vector3(0, 0, 0),
    new THREE.Vector3(markerSize, markerSize / 2, 0)
  )
  marker = new THREE.Line(markerGeo, markerMat)
  line.name = 'marker'

  // Add sliders to sliderContainer:
  sliderContainer.add(line)
  sliderContainer.add(mid)
  sliderContainer.add(left)
  sliderContainer.add(right)

  // Rotate sliderContainer to use 0 to -2*PI
  sliderContainer.visible = true
  sliderContainer.rotation.z = Math.PI / 2

  return Promise.resolve()
}

function get() {
  return sliderContainer.children
}

function show() {
  sliderContainer.visible = true
}

function hide() {
  sliderContainer.visible = false
}

function reset() {
  // TODO: Sorry, this needed to be done fast:
  if (typeof percentRange === 'undefined' || percentRange === null) {
    return
  }
  if (typeof mid === 'undefined' || mid === null) {
    return
  }
  percentRange.start = 0
  percentRange.end = 0
  mid.hittable = false
  moveSliders(startAngle, startAngle)
  drawLine(startAngle, startAngle)
  // drawMarker(startAngle, startAngle)
  // leftLabel.setHTML('')
  // rightLabel.setHTML('')
}

function attach(parentObject) {
  if (parentObject !== null && typeof parentObject !== 'undefined') {
    // Tie slider container reference to current parentObject:
    parentObject.sliderContainer = sliderContainer
    // Add sliderContainer to parent scene:
    parentObject.add(sliderContainer)
    sliderContainer.visible = true
  }
}

function detach(parentObject) {
  if (parentObject !== null && typeof parentObject !== 'undefined') {
    if (typeof parentObject.sliderContainer !== 'undefined') {
      delete parentObject.sliderContainer
      parentObject.remove(sliderContainer)
    }
  }
}

function destroy() {
  if (typeof sliderContainer === 'undefined' || sliderContainer === null) {
    return
  }
  sliderContainer.children.forEach((obj) => {
    if (obj.geometry) {
      obj.geometry.dispose()
    }
    if (obj.material) {
      obj.material.dispose()
    }
  })
  sliderContainer.children.length = 0
  mid = null
  left = null
  right = null
  line = null
  marker = null
}
//
// function initDebugMarkers() {
//   debugContainer = new THREE.Group()
//   debugContainer.name = 'debugContainer'
//   debugContainer.position.setZ(5)
//   debugContainer.rotation.z = Math.PI / 2
//   // debugContainer.rotation.x = 45*(Math.PI / -180)
// }

function getPercentRange() {
  return percentRange
}

function getSelected() {
  return pickedSlider
}

function setSelected(obj) {
  if (sliderContainer.children.includes(obj)) {
    pickedSlider = obj
    pickedSlider.material.color.setHex(sliderHoverColor)
    if (pickedSlider.name === 'left' || pickedSlider.name === 'right') {
      pickedSlider.children[0].material.color.setHex(sliderHoverColor)
    }
  } else {
    sliderContainer.children.forEach((el) => {
      if (el.type === 'sliderMesh') {
        el.material.color.setHex(sliderColor)
        if (el.name === 'left' || el.name === 'right') {
          el.children[0].material.color.setHex(sliderColor)
        }
      }
    })
    pickedSlider = null
  }
}

function objToScreenPosition(obj, camera, renderer) {
  const vector = new THREE.Vector3()
  let { height, width } = renderer.getContext().canvas
  const ratio = window.devicePixelRatio

  width = Math.floor(width / ratio)
  height = Math.floor(height / ratio)

  const widthHalf = 0.5 * width
  const heightHalf = 0.5 * height

  obj.updateMatrixWorld()
  vector.setFromMatrixPosition(obj.matrixWorld)
  vector.project(camera)

  vector.x = vector.x * widthHalf + widthHalf
  vector.y = -(vector.y * heightHalf) + heightHalf

  return {
    x: vector.x,
    y: vector.y
  }
}

function getContainer() {
  return sliderContainer
}

// function updateLabels() {
//   leftLabel.updatePosition(percentRange.start > 0.5)
//   rightLabel.updatePosition(percentRange.end > 0.5)
// }

// function setLeftLabel(str) {
//   console.log(leftLabel, str)
//   leftLabel.setHTML(str)
// }
//
// function setRightLabel(str) {
//   rightLabel.setHTML(str)
// }

function resetSliders() {
  // const parentPosition = Population.getLastPickedNode().position.clone()

  // console.log('parentPosition', parentPosition)
  // Get slider bounds:
  // updateSliders(
  //   new THREE.Vector2(0.01 - parentPosition.x, 0.7 - parentPosition.y),
  //   'left'
  // )
  // updateSliders(
  //   new THREE.Vector2(0.01 - parentPosition.x, 0.7 - parentPosition.y),
  //   'mid'
  // )
  // console.log({ ...percentRange })
  // while (percentRange.end > 0.3) {
  //   updateSliders(
  //     new THREE.Vector2(0.015 - parentPosition.x, 0.7 - parentPosition.y),
  //     'right'
  //   )
  // }

  // console.log({ ...percentRange })

  reset()
}

function updateSliders(position, sliderName) {
  // Selected slider: pickedSlider
  // Previous angles:
  let leftAngle = toAngle(percentRange.start)
  let rightAngle = toAngle(percentRange.end)
  let midAngle = (leftAngle + rightAngle) / 2
  let diffAngle = (leftAngle - rightAngle) / 2

  // Calculate the new angle(s):
  // Units below are in negative radians so numerical comparators for distance will be inverted
  // Or Math.abs will need to be used
  const name = pickedSlider?.name || sliderName
  switch (name) {
    case 'mid':
      midAngle = calculateAngle(position, midAngle)
      if (midAngle + diffAngle > startAngle) {
        midAngle = startAngle - diffAngle
      }
      if (midAngle - diffAngle < endAngle) {
        midAngle = endAngle + diffAngle
      }
      leftAngle = midAngle + diffAngle
      rightAngle = midAngle - diffAngle
      break
    case 'left':
      leftAngle = calculateAngle(position, leftAngle)
      if (leftAngle < rightAngle) {
        leftAngle = rightAngle
      }
      break
    case 'right':
      rightAngle = calculateAngle(position, rightAngle)
      if (rightAngle > leftAngle) {
        rightAngle = leftAngle
      }
      break
    default:
  }

  const startPS = toPercent(leftAngle)
  const endPS = toPercent(rightAngle)
  const midPS = toPercent((leftAngle + rightAngle) / 2)

  // PS.setRange(startPS, endPS)
  percentRange.start = startPS
  percentRange.end = endPS
  percentRange.mid = midPS
  // Set mid hittable state:
  mid.hittable = rightAngle !== leftAngle

  // Move sliders:
  moveSliders(leftAngle, rightAngle)

  // Draw line:
  drawLine(leftAngle, rightAngle)
  // Draw marker:
  // drawMarker(leftAngle, rightAngle)

  // leftLabel.updatePosition(startPS > 0.5)
  // rightLabel.updatePosition(endPS > 0.5)
  // const leftBounds = leftLabel.element.getBoundingClientRect()
  // const clx = (leftBounds.right - leftBounds.left) / 2 + leftBounds.left
  // const cly = (leftBounds.bottom - leftBounds.top) / 2 + leftBounds.top
  // const rightBounds = rightLabel.element.getBoundingClientRect()
  // const crx = (rightBounds.right - rightBounds.left) / 2 + rightBounds.left
  // const cry = (rightBounds.bottom - rightBounds.top) / 2 + rightBounds.top
  // const dist = Math.sqrt(Math.pow(crx - clx, 2) + Math.pow(cry - cly, 2))
  // if (dist < 50) {
  //   leftLabel.element.style.visibility = 'hidden'
  // } else {
  //   leftLabel.element.style.visibility = 'visible'
  // }

  return percentRange
}

// Update slider's according to range (for NoFlo, deprecated):
function updateSlidersLive(newPercentRange = percentRange) {
  percentRange.start = newPercentRange.start
  percentRange.end = newPercentRange.end
  percentRange.mid = percentRange.end - percentRange.start
  // Convert to angles:
  const leftAngle = toAngle(percentRange.start)
  const rightAngle = toAngle(percentRange.end)

  // const midAngle = (leftAngle + rightAngle) / 2

  // Set mid hittable state:
  mid.hittable = leftAngle !== rightAngle

  // Move sliders:
  moveSliders(leftAngle, rightAngle)

  // Draw line:
  drawLine(leftAngle, rightAngle)

  // Draw marker:
  drawMarker(leftAngle, rightAngle)
}

function updateMarker(position) {
  // Update the marker position value when the globe tells us to:
  markerposition = position
  // const percentRange = PS.getRange()
  const leftAngle = toAngle(percentRange.start)
  const rightAngle = toAngle(percentRange.end)
  drawMarker(leftAngle, rightAngle)
}

// Private:
// function setSliders(parentObject) {
//   // Tie slider container to current parentObject:
//   parentObject.sliderContainer = sliderContainer
//
//   // Add sliderContainer to parent scene:
//   parentObject.add(sliderContainer)
// }

function createSlider(radius, name, hittable) {
  // Build slider:
  const geometry = new THREE.CircleBufferGeometry(
    radius,
    16,
    sliderTheta[name].start,
    sliderTheta[name].length
  )
  const material = new THREE.MeshBasicMaterial({ color: sliderColor }) // Default orange color
  const slider = new THREE.Mesh(geometry, material)
  slider.name = name
  slider.type = 'sliderMesh'
  slider.hittable = hittable
  slider.position.set(startX, startY, zIndex) // 0.9 Z-index so it's always hittable

  // Add line from slider to edge of sphere:
  if (hittable) {
    const lineMat = new THREE.LineBasicMaterial({ color: sliderColor })
    const lineGeo = new THREE.Geometry()
    const lineSize = outerRadius * 0.04
    lineGeo.vertices.push(
      new THREE.Vector3(lineSize, 0, 0),
      new THREE.Vector3(0, 0, 0),
      new THREE.Vector3(0, 0, 0)
    )
    const sliderLine = new THREE.Line(lineGeo, lineMat)
    sliderLine.name = `${name}Line`
    sliderLine.position.setX(-2 * radius)
    slider.add(sliderLine)
  }

  return slider
}

function calculateAngle(position, prev) {
  let min = startAngle
  let max = endAngle

  // Convert angle to: 0 -> -PI on right hemisphere,
  // and 0 -> PI on left hemisphere:
  let angle = Math.atan(position.y / position.x)
  angle = position.x >= 0 ? angle - Math.PI / 2 : angle + Math.PI / 2

  if (Math.abs(angle) < Math.PI / 2) {
    // Remove 2*PI offset:
    prev = prev < -1 * Math.PI ? prev + Math.PI * 2 : prev
    max = max < -1 * Math.PI ? max + Math.PI * 2 : max

    // If less than min:
    if (angle > min && prev <= min) {
      angle = min
      // If greater than max:
    } else if (angle < max && prev >= max) {
      angle = max
    }
  }

  // Re-account for 2*PI offset:
  if (angle > 0) {
    angle -= Math.PI * 2
  }

  return angle
}

function moveSliders(leftAngle, rightAngle) {
  // Move sliders:
  moveSlider(left, leftAngle, true)
  moveSlider(right, rightAngle, true)
  moveSlider(mid, (leftAngle + rightAngle) / 2, false)
}

function moveSlider(slider, angle, rotate) {
  // Position:
  slider.position.setX(outerRadius * Math.cos(angle))
  slider.position.setY(outerRadius * Math.sin(angle))

  // Rotate:
  if (rotate) {
    slider.rotation.z = angle
  }
}

function drawLine(leftAngle, rightAngle) {
  const positions = line.geometry.attributes.position.array

  // Slider's open:
  if (leftAngle !== rightAngle) {
    let i = 0
    let j = 0
    let theta = leftAngle

    // Calculate the line vertex positions:
    while (theta > rightAngle) {
      theta = leftAngle + (i / 360) * Math.PI * -2
      i++

      positions[j++] = Math.cos(theta) * outerRadius
      positions[j++] = Math.sin(theta) * outerRadius
      positions[j++] = 0
    }

    // Draw the line and update:
    line.geometry.setDrawRange(0, i)

    // Slider's closed:
  } else {
    line.geometry.setDrawRange(0, 1)
  }

  // Redraw:
  line.geometry.attributes.position.needsUpdate = true
}

// function createTextLabel(container, camera) {
//   const div = document.createElement('div')
//   div.className = 'ui-slider-text-label'
//   div.style['user-select'] = 'none'
//   div.style['pointer-events'] = 'none'
//   div.style.position = 'absolute'
//   div.style.fontSize = 'small'
//   div.style.color = 'white'
//   div.style.width = '100px'
//   div.style.height = '100px'
//   div.innerHTML = ''
//   div.style.bottom = '16px'
//   div.style.left = '16px'
//
//   return {
//     container,
//     camera,
//     element: div,
//     parent: false,
//     position: new THREE.Vector3(0, 0, 0),
//     setHTML(html) {
//       this.element.innerHTML = html
//     },
//     clearHTML() {
//       this.element.innerHTML = ''
//     },
//     setParent(threeJsObj) {
//       this.parent = threeJsObj
//     },
//     updatePosition(alignRight = false) {
//       if (parent) {
//         this.position.copy(this.parent.position)
//       }
//       const elementBounds = this.element.getBoundingClientRect()
//       const labelPosition = new THREE.Vector3()
//         .copy(this.position)
//         .multiplyScalar(1.2)
//       // Position and scale offsets if slider container is attached to a parent object:
//       if (this.parent.parent.parent) {
//         const parentObj = this.parent.parent.parent
//         const parentWorldScale = new THREE.Vector3()
//         const parentWorldPosition = new THREE.Vector3()
//         parentWorldScale.setFromMatrixScale(parentObj.matrixWorld)
//         parentWorldPosition.setFromMatrixPosition(parentObj.matrixWorld)
//         labelPosition.multiplyScalar(parentWorldScale.x)
//         labelPosition.add(parentWorldPosition)
//       }
//       const coords2d = this.get2DCoords(labelPosition, this.camera)
//       const containerBounds = this.container.getBoundingClientRect()
//       const containerRight = containerBounds.right
//       const containerLeft = containerBounds.left
//       // this.element.style.top = `${coords2d.y}px`
//       if (alignRight) {
//         this.element.style.left =
//           elementBounds.left - elementBounds.width >= containerLeft
//             ? `${elementBounds.left - elementBounds.width}px`
//             : `${containerLeft}px`
//         this.element.style.width = `${coords2d.x}px`
//         this.element.style.textAlign = 'right'
//       } else {
//         const newLeft = coords2d.x + containerLeft
//         const newRight = containerRight
//         this.element.style.left = `${newLeft}px`
//         this.element.style.right = `${newRight}px`
//         this.element.style.width = `${newRight - newLeft}px`
//         this.element.style.textAlign = 'left'
//       }
//     },
//     get2DCoords(position, _camera) {
//       const boundary = this.container.getBoundingClientRect()
//       const cx = (boundary.right - boundary.left) / 2
//       const cy = (boundary.bottom - boundary.top) / 2
//       const vector = position.project(_camera)
//       vector.x = ((vector.x + 1) / 2) * boundary.width
//       vector.y = (-(vector.y - 1) / 2) * boundary.height
//       // This is for rotating 90 degrees counterclockwise for slider label
//       const nx = -(cy - vector.y) + cx
//       const ny = -(vector.x - cx) + cy
//       vector.x = nx
//       vector.y = ny
//       return vector
//     },
//     destroy() {
//       this.container = null
//       this.position = null
//       this.element = null
//       this.camera = null
//     }
//   }
// }

function drawMarker(leftAngle, rightAngle) {
  const span = rightAngle - leftAngle
  const angle = markerposition * span + leftAngle

  // const angle = (rightAngle  + leftAngle) /2
  const _offsetRadius = outerRadius * 0.05
  marker.position.setX((outerRadius + _offsetRadius) * Math.cos(angle))
  marker.position.setY((outerRadius + _offsetRadius) * Math.sin(angle))
  marker.rotation.z = angle
}

// function drawDebugMarkers() {
//   // This will build all of the markers we need to display the debug
//   // markers in the scene. The objects will be stored in the debugMarkers
//   // array inside the slider.directive.

//   // Iterate through each of the Layers:
//   let lastOffset = 0
//   for (let index = 0; index < debugLayers; index++) {
//     // Figure out this layers offset:
//     const thisOffset = outerRadius + offsetRadius + lastOffset
//     // const zOffset = index * 0.1

//     // Build all items in this layer:
//     const layerValues = buildDebugLayer(thisOffset)

//     lastOffset = lastOffset + markerSizeMax + 0.02

//     // Keep track of our layers:
//     debugMarkers.push(layerValues)
//   }
// }

// function buildDebugLayer(thisOffset) {
//   return new Array(debugTimeSlices).fill(1).map((el, index) => {
//     // Figure out this slices' angle:
//     let angle = (index / debugTimeSlices) * 360
//     angle *= Math.PI / -180

//     // Build actual debug markers elements:
//     const markerSize = markerSizeMax * el
//     const markerMat = new THREE.LineBasicMaterial({ color: 0xffffff })
//     const markerGeo = new THREE.Geometry()

//     // Define the initial size of the markers:
//     markerGeo.vertices.push(
//       new THREE.Vector3(0, 0, 0),
//       new THREE.Vector3(markerSize, 0, 0)
//     )
//     const debugMarker = new THREE.Line(markerGeo, markerMat)
//     debugMarker.name = `debugMarker${index}`

//     // Rotate the debug marker around the petri:
//     debugMarker.rotation.z = angle

//     // Debug marker position:
//     debugMarker.position.setX(thisOffset * Math.cos(angle))
//     debugMarker.position.setY(thisOffset * Math.sin(angle))
//     // debugMarker.position.setZ(zOffset)
//     debugContainer.add(debugMarker)

// Build debug cubes
// let cube
// let drawCube = false
// if (drawCube) {
//   // var geometry = new THREE.CubeGeometry(markerSize,0.01,0.01)
//   const geometry = new THREE.CubeGeometry(0.01, 0.01, 0.01)
//   const normalMaterial = new THREE.MeshNormalMaterial({
//     // wire-frame: true,
//     side: THREE.FrontSide
//   })
//   cube = new THREE.Mesh(geometry, normalMaterial)

//   // Rotate the debug cube:
//   //  ( (between 0 and 1) * (max angle) ) * (convert to rad)
//   //       (val / max)
//   // (((el*100) / 100) * 90)  * (Math.PI / -180)
//   const value = el * 90 * (Math.PI / -180)

//   // NOTE: local rotation doesn't cut it
//   // cube.rotation.y = (el * 90)  * (Math.PI / -180)
//   // cube.rotation.z = angle

//   // Do world axis rotation using quaternions:
//   const localQuat = new THREE.Quaternion()
//   localQuat.setFromAxisAngle(new THREE.Vector3(0, 1, 0), -value)
//   const worldQuat = new THREE.Quaternion()
//   worldQuat.setFromAxisAngle(new THREE.Vector3(0, 0, 1), angle)

//   // Combine those two quaternion rotations.
//   // NOTE: This order matters! It's backwards from your intuition
//   // worldQuat.multiply(localQuat)
//   cube.quaternion.copy(worldQuat)

//   cube.position.setX((thisOffset + markerSize) * Math.cos(angle))
//   cube.position.setY((thisOffset + markerSize) * Math.sin(angle))
//   // cube.position.setZ(zOffset)
//   debugContainer.add(cube)
// }

//     return debugMarker
//   })
// }

function updateDebugMarker(data) {
  if (typeof data !== 'undefined') {
    // figure out how many layers we have:
    // const newLayerCount = data.length
    // console.log(data, debugContainer.children)

    // TODO: build new layers if needed...

    // TODO: Figure out a better way to bind visual elements to data values
    data.forEach((layerData) => {
      // Parse this lays new data and update the view elements:
      layerData.slices.forEach((slice, index) => {
        // This if statement ensures that we don't try updating more
        // than 100 slices if elastic returns more than that:
        if (index < 100) {
          const thisMarker = debugContainer.children[index]
          let sliceValue = slice.value // / layerData.max
          if (sliceValue === 0) {
            // We can't set an item to have a scale of 0:
            sliceValue = 0.0000001
          }
          thisMarker.scale.setX(sliceValue)
        }
      })
    })
  }
}

// Helper functions (maybe move to utils.math):
function toAngle(percent) {
  return startAngle - percent * range
}

function toPercent(angle) {
  return (startAngle - angle) / range
}
