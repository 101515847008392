import * as THREE from 'three'
import bubble from './resources/bubble.png'
import hoverbubble from './resources/bubblehover.png'
import doublebubble from './resources/doublebubble.png'

const petri = [
  // Geometries:
  {
    type: 'geometry',
    name: 'plane',
    data: {
      type: 'PlaneGeometry',
      width: 1,
      height: 1
    },
    loaded: false
  },
  {
    type: 'geometry',
    name: 'cube',
    data: {
      type: 'BoxGeometry',
      width: 1,
      height: 1,
      depth: 1
    },
    loaded: false
  },
  {
    type: 'geometry',
    name: 'sphere',
    data: {
      type: 'SphereGeometry',
      radius: 0.5,
      widthSegments: 50,
      heightSegments: 50
    },
    loaded: false
  },
  // Materials:
  {
    type: 'material',
    name: 'sphereMaterial',
    data: {
      type: 'MeshBasicMaterial',
      envMap: 'sphereTexture',
      // color: 0x267C9A,
      opacity: 0.75,
      transparent: true
      // wireframe: true
    },
    loaded: false
  },
  {
    type: 'material',
    name: 'sphereParentMaterial',
    data: {
      type: 'MeshBasicMaterial',
      envMap: 'sphereParentTexture',
      // color: 0x267C9A,
      opacity: 0.7,
      transparent: true
      // wireframe: true
    },
    loaded: false
  },
  {
    type: 'material',
    name: 'icosahedronMaterial',
    data: {
      type: 'MeshPhongMaterial',
      // map: 'icosahedronTexture',
      // opacity: 1,
      color: 0x000000,
      shininess: 5,
      // transparent: false,
      wireframe: false,
      depthWrite: true
    },
    loaded: false
  },
  // {
  //   type: 'material',
  //   name: 'octagonMaterial',
  //   data: {
  //     type: 'MeshBasicMaterial',
  //     // opacity: 1,
  //     color: 0x000000,
  //     shininess: 5,
  //     // transparent: false,
  //     wireframe: false,
  //     depthWrite: true
  //   },
  //   loaded: false
  // },
  {
    type: 'material',
    name: 'normalMaterial',
    data: {
      type: 'MeshNormalMaterial',
      wireframe: false,
      side: THREE.DoubleSide
    },
    loaded: false
  },
  {
    type: 'material',
    name: 'blankMaterial',
    data: {
      type: 'MeshPhongMaterial',
      specular: 0xffffff,
      color: 0xff0000,
      transparent: true,
      opacity: 0.0,
      wireframe: false,
      depthTest: false,
      shininess: 10,
      alphaTest: 0.5,
      depthWrite: false
    },
    loaded: false
  },

  {
    type: 'texture',
    name: 'sphereTexture',
    data: {
      url: bubble,
      mapping: 'SphericalReflectionMapping'
    },
    loaded: false
  },
  {
    type: 'texture',
    name: 'sphereParentTexture',
    data: {
      url: doublebubble,
      mapping: 'SphericalReflectionMapping'
    },
    loaded: false
  },
  {
    type: 'texture',
    name: 'sphereHoverTexture',
    data: {
      url: hoverbubble,
      mapping: 'SphericalReflectionMapping'
    },
    loaded: false
  }
]

const background = [
  {
    type: 'material',
    name: 'backgroundMaterial',
    data: {
      type: 'MeshBasicMaterial',
      map: 'backgroundTexture'
    },
    loaded: false
  },
  {
    type: 'texture',
    name: 'backgroundTexture',
    data: {
      url: 'assets/bg.png'
    },
    loaded: false
  }
]

const population = [
  {
    type: 'object',
    name: 'Icosahedron',
    data: {
      url: 'assets/Icosahedron.obj'
    },
    loaded: false
  },
  {
    type: 'texture',
    name: 'sphereTexture',
    data: {
      url: 'assets/bubble.png',
      mapping: 'SphericalReflectionMapping'
    },
    loaded: false
  },
  {
    type: 'texture',
    name: 'sphereParentTexture',
    data: {
      url: 'assets/doublebubble.png',
      mapping: 'SphericalReflectionMapping'
    },
    loaded: false
    // }, {
    //   type: 'texture',
    //   name: 'icosahedronTexture',
    //   data: {
    //     url: 'assets/leather.jpg'
    //   },
    //   loaded: false
  }
]

const histogram = [
  {
    type: 'image',
    name: 'histogramTicks',
    data: {
      url: 'assets/histogramticks.svg'
    },
    loaded: false
  }
]

const onionskin = [
  {
    type: 'image',
    name: 'gdeltIcon',
    data: {
      url: 'assets/gdelt.png'
    },
    loaded: false
  },
  {
    type: 'image',
    name: 'twitterIcon',
    data: {
      url: 'assets/twitter.png'
    },
    loaded: false
  }
]

const mouse = [
  {
    type: 'texture',
    name: 'sphereHoverTexture',
    data: {
      url: 'assets/bubblehover.png',
      mapping: 'SphericalReflectionMapping'
    },
    loaded: false
  }
]

const fx = [
  {
    type: 'material',
    name: 'fxGridMaterial',
    data: {
      type: 'MeshPhongMaterial',
      map: 'fxGridTexture',
      opacity: 0.03,
      transparent: true,
      depthTest: false
    },
    loaded: false
  },
  {
    type: 'material',
    name: 'fxPinsMaterial',
    data: {
      type: 'MeshPhongMaterial',
      map: 'fxPinsTexture',
      opacity: 0.4,
      alphaTest: 0,
      transparent: true,
      wireframe: false,
      depthWrite: true
    },
    loaded: false
  },
  {
    type: 'material',
    name: 'fxFresnelMaterial',
    data: {
      type: 'MeshPhongMaterial',
      map: 'fxFresnelTexture',
      displacementMap: 'fxTextureDisplacement',
      displacementScale: 0.056143,
      displacementBias: 0.00005,
      alphaMap: 'fxTextureAlpha',
      side: THREE.DoubleSide,
      opacity: 0.12,
      transparent: true,
      wireframe: false,
      depthTest: false
    },
    loaded: false
  },
  {
    type: 'texture',
    name: 'fxGridTexture',
    data: {
      url: 'assets/gridtexture.png'
    },
    loaded: false
  },
  {
    type: 'texture',
    name: 'fxFresnelTexture',
    data: {
      url: 'assets/spheretexture.png'
    },
    loaded: false
  },
  {
    type: 'texture',
    name: 'fxTextureAlpha',
    data: {
      url: 'assets/alpha-mask5.fw.png'
    },
    loaded: false
  },
  {
    type: 'texture',
    name: 'fxTextureDisplacement',
    data: {
      url: 'assets/displacement-map.png'
    },
    loaded: false
  },
  {
    type: 'texture',
    name: 'fxPinsTexture',
    data: {
      url: 'assets/pins.png'
    },
    loaded: false
  }
]

export default {
  petri,
  background,
  population,
  histogram,
  onionskin,
  mouse,
  fx
}
