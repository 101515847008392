import { mergeStyleSets } from '@uifabric/styling'
import PropTypes from 'prop-types'
import React from 'react'
import {
  CORE3D,
  Population,
  Ribbon,
  Slider,
  PieRing,
  Histogram,
  RecordLayer
} from './lib/index'
import Breadcrumb from './components/Breadcrumb'
import TimeRange from './components/TimeRange'
import MobileTimeSlider from './components/MobileTimeSlider'
import * as Types from './lib/Types'
import {
  scaleFunctions,
  transformSliderData,
  transformHexLayerSliderData,
  transformHistogramData,
  isChildOfClass,
  getTimeRangeMarkerPosition
} from './lib/Petri.utils'
import isEqual from 'react-fast-compare'
import { formatDate, getTimeSliderRangeValues } from 'lib/utils'
import THREE from 'lib/threejs'

const TimeSlider = Slider
const CreateRibbon = Ribbon

const styles = mergeStyleSets({
  petriContainer: {
    zIndex: 1,
    height: '100%',
    position: 'relative'
  },
  petriWrapper: {
    flex: 1,
    height: '100%',
    overflow: 'hidden',
    position: 'relative'
  }
})

class Petri extends React.Component {
  constructor(props) {
    super(props)

    // Core3D references:
    this.mouse = null
    this.scene = null
    this.camera = null
    this.isQueryInProgress = false

    // Petri submodule action dispatch handler:
    this.dispatchHandler = this.dispatchHandler.bind(this)

    // Main update loop:
    this.updateScene = this.updateScene.bind(this)

    // Mouse handler bindings:
    this.onClick = this.onClick.bind(this)
    this.mouseUp = this.mouseUp.bind(this)
    this.mouseDown = this.mouseDown.bind(this)
    this.mouseMove = this.mouseMove.bind(this)
    this.mouseOut = this.mouseOut.bind(this)

    // resize viewport binding:
    this.resizeViewport = this.resizeViewport.bind(this)

    // Ribbon reference:
    this.ribbon = null

    // Time slider range debounce dispatcher:
    this.dispatchSliderBoundary = this.dispatchSliderBoundary.bind(this)

    // Breadcrumb trail:
    this.breadcrumb = []

    // Petri zoom & pan controls:
    this.controls = null

    this.currentTimeRange = {
      start: null,
      end: null
    }

    this.timeSliderPercentages = {}
    this.currentTimeRangeTracker = {}

    // Refs:
    this.petriRef = React.createRef()
    this.timeStartRef = React.createRef()
    this.timeEndRef = React.createRef()
    this.resetButtonRef = React.createRef()
    this.midResetButtonContainerRef = React.createRef()

    this.intervalNormalization = null

    this.state = {
      isReady: false,
      resetButtonDisabled: true,
      resultsLength: props.resultsLength,
      histogramData: []
    }
  }

  componentDidMount() {
    this.setUpScene()
    window.addEventListener('resize', this.resizeViewport)
  }

  componentWillUnmount() {
    // Reset timeSlider variables and remove sliders:
    TimeSlider.reset()
    TimeSlider.destroy()

    // Remove mouse event listeners:
    const container = this.petriRef.current
    container.onmousemove = null
    container.onmousedown = null
    container.onmouseup = null
    container.onmouseout = null

    // Stop animating:
    CORE3D.stopAnimating()

    // Destroy Petri scene:
    if (this.scene) {
      CORE3D.destroyViewport(this.scene.getScene().uuid)
    }

    // Destroy controls and all it's event listeners:
    if (this.controls) {
      this.controls.dispose()
    }

    // Remove keyboard event listeners:
    document.removeEventListener('keyup', this.revertPetriScale)
    document.removeEventListener('keydown', this.normalizePetriScale)
    window.removeEventListener('resize', this.resizeViewport)
  }

  setUpScene = () => {
    CORE3D.init()

    // Grab the petri container:
    const container = this.petriRef.current

    if (typeof container !== 'undefined' && container !== null) {
      // Create the viewport:
      const viewport = CORE3D.createViewport(container)

      // Create the scene:
      this.scene = CORE3D.initScene(viewport)
      const renderer = this.scene.getRenderer()
      renderer.domElement.className = 'petri-renderer-canvas'
      renderer.domElement.onwheel = this.mouseWheel
      renderer.sortObjects = true

      // Create a camera for the scene:
      this.camera = CORE3D.initCamera(this.scene, {
        type: 'OrthographicCamera',
        top: viewport.viewHeight / 2,
        left: viewport.viewWidth / -2,
        right: viewport.viewWidth / 2,
        bottom: viewport.viewHeight / -2,
        near: 0.01,
        far: 1000,
        zoom: 1
      })

      // Set mouse ray-casting hit detector:
      this.mouse = CORE3D.getMouse()

      // Attach main update render loop function:
      this.scene.attachUpdate(this.updateScene)

      // Ensure the initial size is set correctly:
      this.resizeViewport()

      // Submodule initialization promises:
      const promises = [
        Population.init(
          container,
          this.scene,
          this.camera,
          this.mouse,
          this.dispatchHandler
        ),
        Histogram.init(),
        RecordLayer.init(this.dispatchHandler),
        PieRing.init(this.dispatchHandler),
        TimeSlider.init(
          container,
          this.camera.getCamera(),
          this.dispatchHandler
        )
      ]

      // Actions to perform after initialization has completed:
      Promise.all(promises).then(() => {
        this.scene.setRenderable()
        this.controls = Population.buildControls()
        this.controls.panSpeed = 0.8

        this.toggleLoader(true)
        this.setState({ isReady: true })
      })

      // Event listeners for petri mouse events:
      container.onmousemove = (e) =>
        requestAnimationFrame(() => this.mouseMove(e))
      container.onmousedown = this.mouseDown
      container.onmouseup = this.mouseUp
      container.onmouseout = this.mouseOut

      container.addEventListener('touchstart', (e, external) => {
        if (e.touches.length === 1) {
          this.mouseMove(e, external)
          this.mouseDown(e)
        }
      })
      container.addEventListener('touchend', (e) => {
        this.mouseUp(e)
      })

      // Event listeners for normalizing and reverting sizes of current Petri layer nodes on SHIFT keydown/up:
      document.addEventListener('keydown', this.normalizePetriScale)
      document.addEventListener('keyup', this.revertPetriScale)

      // Initialize Petri-PieRing ribbon:
      this.ribbon = CreateRibbon(container, this.camera.getCamera())
      container.appendChild(this.ribbon.canvas)
    }
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    const { props, state } = this
    const hasNewRecords =
      nextProps.onLastLayer && !isEqual(nextProps.population, props.population)
    const hasNewPopulation =
      !nextProps.onLastLayer && !isEqual(nextProps.population, props.population)
    const shouldClearPetri = props.clear !== nextProps.clear
    const hasNewNormalization = props.isNormalized !== nextProps.isNormalized
    const hasNewBreadcrumb = props.breadcrumb !== nextProps.breadcrumb

    return (
      nextState.isReady !== state.isReady ||
      (nextState.isReady &&
        (hasNewRecords ||
          hasNewPopulation ||
          shouldClearPetri ||
          hasNewNormalization ||
          hasNewBreadcrumb ||
          state.resetButtonDisabled !== nextState.resetButtonDisabled ||
          nextProps.isQueryInProgress !== props.isQueryInProgress ||
          nextState.histogramData !== state.histogramData ||
          nextProps.isMobile !== props.isMobile))
    )
  }

  getSnapshotBeforeUpdate(prevProps) {
    const { clear, population, onLastLayer } = this.props

    if (!onLastLayer && !isEqual(population, prevProps.population)) {
      const total = population.reduce((acc, curr) => acc + curr.value, 0)
      const node = Population.getLastPickedNode()
      const key =
        node === Population.getRootNode()
          ? `Total (${total})`
          : `${node.nodeName} (${total})`

      if (node === Population.getRootNode()) {
        this.breadcrumb = []
      } else {
        this.breadcrumb.pop()
      }

      this.breadcrumb.push({ key, node, total })
    }

    if (clear) {
      this.breadcrumb = []
    }
    return null
  }

  componentDidUpdate(prevProps) {
    const { props } = this

    const petriHasNotLoadedData =
      props.population.length &&
      ((!Population.get().length && !props.onLastLayer) ||
        (!RecordLayer.get().length && props.onLastLayer))
    if (
      prevProps.isQueryInProgress !== props.isQueryInProgress ||
      this.isQueryInProgress
    ) {
      this.toggleLoader(props.isQueryInProgress)
    }

    if (
      props.isNormalized === prevProps.isNormalized &&
      isEqual(props.population, prevProps.population) &&
      isEqual(props.timeRange, prevProps.timeRange) &&
      isEqual(props.breadcrumb, prevProps.breadcrumb) &&
      !props.clear &&
      !petriHasNotLoadedData
    ) {
      return
    }

    this.update(prevProps)
  }

  update = () => {
    const { props, state } = this
    const {
      population,
      clear,
      onLastLayer,
      isWaitingForAsync,
      timeRange
    } = props
    const hasTimeRange = Boolean(
      timeRange?.start && timeRange?.end && timeRange?.field?.length
    )
    if (props.isNormalized) {
      clearInterval(this.intervalNormalization)
      this.intervalNormalization = setInterval(() => {
        Population.normalizeLayerScale()
      }, 100)
    } else if (props.isNormalized === false) {
      clearInterval(this.intervalNormalization)
      Population.revertLayerScale()
    }

    if (props.resultsLength !== state.resultsLength) {
      this.setState({ resultsLength: props.resultsLength })
    }

    // Clear ribbon on update:
    if (this.ribbon) {
      this.ribbon.clear()
    }

    // Current Petri parent node:
    const parentNode = Population.getLastPickedNode()

    // Remove/Hide Petri objects if flagged for clearing (from updating layer list):
    if (clear) {
      Population.removeNodes()
      Population.clear()
      if (!this.isQueryInProgress) {
        PieRing.hide()
      }
      Histogram.hide()
      TimeSlider.hide()
      RecordLayer.hide()
      RecordLayer.setSelected(null)
      this.resetTimeRange()
      if (this.camera) {
        this.camera.reset()
      }
      if (this.controls) {
        this.controls.reset()
      }
      return
    }
    PieRing.destroy()

    // If not fetching when updated, bind the data to Petri module(s) (will draw/animate automatically):
    if (!isWaitingForAsync) {
      // Hide TimeSlider:
      TimeSlider.reset()
      TimeSlider.hide()

      // Use RecordLayer on last layer:
      if (onLastLayer && parentNode) {
        if (parentNode.name === 'root') {
          Population.removeNodes()
          Population.clear()
        }

        // Hide the Pie Ring & Histogram:
        PieRing.hide()
        Histogram.hide()

        // Bind data to Record Layer and attach to parentPopulation node:
        RecordLayer.show()
        RecordLayer.bind(population)
        RecordLayer.attach(parentNode)

        // This unlock is for the very last layer when the population is unable to determine when
        // it can accept Petri clicks:
        Population.unlock()

        // Show TimeSlider and Histogram and attach to parent Population node if there is time range data:
        if (hasTimeRange && parentNode && props.aggregation.length) {
          this.handleHistogramData(parentNode)
        } else if (parentNode) {
          TimeSlider.hide()
          TimeSlider.detach(parentNode)
        }

        // Use Population if not on last layer:
      } else {
        // Hide the Record Layer:
        RecordLayer.hide()

        // This lock is for the very first layer when layer building is engaged without a Petri click:
        Population.lock()

        // Bind data to Population:
        Population.bind(population)

        // Bind data to Pie Ring and attach to parent Population node:
        PieRing.show()
        PieRing.bind(population)
        if (parentNode) {
          PieRing.attach(parentNode)
        }

        // Create/Update current Petri layer:
        Population.updateLayer()

        // Show TimeSlider and Histogram and attach to Population parentNode if there is time range data:
        if (hasTimeRange) {
          const currentBreadcrumb =
            props.breadcrumb[props.breadcrumb.length - 1]

          // Initialize time labels:
          const prevTimeRange = this.currentTimeRangeTracker[currentBreadcrumb]
            ? {
                ...this.currentTimeRangeTracker[currentBreadcrumb]
              }
            : null

          const prevPercentage = this.timeSliderPercentages[currentBreadcrumb]
            ? {
                ...this.timeSliderPercentages[currentBreadcrumb]
              }
            : null

          prevTimeRange &&
            this.setTimeRange({
              start: prevTimeRange.start,
              end: prevTimeRange.end,
              range: prevTimeRange.range
            })

          this.handleHistogramData(parentNode)
          prevPercentage && TimeSlider.updateSlidersLive(prevPercentage)

          // Otherwise hide Histogram & TimeSlider:
        } else {
          TimeSlider.hide()
          TimeSlider.detach(parentNode)
          Histogram.hide()
          Histogram.detach(parentNode)
        }
      }
    }

    if (this.scene) {
      this.resizeViewport()
    }
  }

  dispatchHandler(action) {
    const { breadcrumb, controls, ribbon, props } = this

    const { payload } = action
    const lastPickedNode = Population.getLastPickedNode()

    switch (action.type) {
      case Types.POPULATION_POP:
        action.type = Types.BREADCRUMB_POP

        breadcrumb.pop()
        const breadcrumbProp = [...props.breadcrumb]
        const removedBreadcrumb = breadcrumbProp.pop()
        const savedTimeRange = this.currentTimeRangeTracker[
          breadcrumbProp[breadcrumbProp.length - 2]
        ]

        props.changingBreadcrumb(action, savedTimeRange)

        delete this.currentTimeRangeTracker[removedBreadcrumb]
        delete this.timeSliderPercentages[removedBreadcrumb]

        controls.recalibrate(lastPickedNode)
        break

      case Types.POPULATION_PUSH:
        action.type = Types.BREADCRUMB_PUSH
        breadcrumb.push({
          key: `${payload.key} (${payload.value})`,
          node: lastPickedNode
        })
        props.changingBreadcrumb(action, this.currentTimeRange)
        controls.recalibrate(lastPickedNode)
        break

      case Types.POPULATION_REPLACE:
        action.type = Types.BREADCRUMB_REPLACE
        breadcrumb.pop()
        breadcrumb.push({
          key: `${payload.key} (${payload.value})`,
          node: lastPickedNode
        })
        props.changingBreadcrumb(action, this.currentTimeRange)
        controls.recalibrate(lastPickedNode)
        break

      case Types.BREADCRUMB_JUMP:
        props.changingBreadcrumb(action)
        controls.recalibrate(lastPickedNode)
        break

      case Types.POPULATION_ON_HOVER:
        if (payload) {
          // debounceUpdateMetaData(payload)
          const pieSlice = PieRing.get().find(
            (el) => payload.key === el.nodeData.key
          )
          const populationNode = Population.getChildNodes().find(
            (el) => payload.key === el.nodeData.key
          )
          if (pieSlice && populationNode) {
            // Highlight corresponding slice:
            pieSlice.highlight()
            // Draw connecting ribbon:
            ribbon.setNode(populationNode)
            ribbon.setSlice(pieSlice)
            ribbon.updatePosition()
          }
        }
        break

      case Types.POPULATION_OFF_HOVER:
        if (payload) {
          const pieSlice = PieRing.get().find(
            (el) => payload.key === el.nodeData.key
          )
          if (pieSlice) {
            pieSlice.unhighlight()
          }
        }
        // Remove ribbon when not hovering:
        ribbon.clear()
        break

      case Types.POPULATION_DRAW_COMPLETED:
        Population.unlock()
        break

      // NOTE: Brush & Link after zooming
      case Types.RECORD_LAYER_AFTER_ZOOM:
        // Brush & Link select record
        props.onRecordZoom(payload)
        break

      case Types.RECORD_LAYER_ON_HOVER:
        // highlightGeoPoint(payload.id)
        break

      case Types.RECORD_LAYER_OFF_HOVER:
        // highlightGeoPoint()
        break

      case Types.RECORD_LAYER_ON_CLICK:
        props.onRecordClick(action.payload.data)
        break

      case Types.PIE_RING_ON_CLICK:
        if (payload) {
          const populationNode = Population.getChildNodes().find(
            (el) => payload.key === el.nodeData.key
          )
          if (populationNode && populationNode.visible) {
            populationNode.onClick()
          }
        }
        break

      case Types.PIE_RING_ON_HOVER:
        if (payload) {
          const populationNode = Population.getChildNodes().find(
            (el) => payload.key === el.nodeData.key
          )
          const pieSlice = PieRing.get().find(
            (el) => payload.key === el.nodeData.key
          )
          if (populationNode && populationNode.visible && pieSlice) {
            // Highlight corresponding slice:
            populationNode.highlight()
            // Draw connecting ribbon:
            ribbon.setNode(populationNode)
            ribbon.setSlice(pieSlice)
            ribbon.updatePosition()
          }
        }
        break

      case Types.PIE_RING_OFF_HOVER:
        if (payload) {
          const populationNode = Population.getChildNodes().find(
            (el) => payload.key === el.nodeData.key
          )
          if (populationNode) {
            populationNode.unhighlight()
          }
        }
        // Remove ribbon when not hovering:
        ribbon.clear()
        break

      default:
        break
    }
  }

  // The main Petri update loop (currently lopped mouseHandler/hitDetection inside here as well):
  updateScene(scene, timestamp) {
    // Update modules:
    PieRing.animate()
    Population.animate()
    RecordLayer.animate()
    Histogram.animate()

    // Update camera:
    if (this.camera) {
      this.camera.update(timestamp)
    }

    // Update ribbon:
    if (this.ribbon) {
      this.ribbon.updatePosition()
    }

    // Update mouse (performs ray-cast to set pickedObject from hittableObjects):
    if (this.mouse) {
      const hittableObjects = [
        ...PieRing.get(),
        ...Population.get(),
        ...TimeSlider.get(),
        ...RecordLayer.get()
      ]
      this.mouse.runHitTest(this.scene.getCamera(), hittableObjects)
    }

    // Update mouse cursor context/style depending on selected object:
    const petriContainer = this.petriRef.current
    const pickedObject = this.mouse.getPickedObject()
    const lastPickedNode = Population.getLastPickedNode()
    if (
      (pickedObject?.type === 'sliderMesh' &&
        petriContainer.style.cursor !== 'grab') ||
      TimeSlider.getSelected()
    ) {
      if (petriContainer.style.cursor === 'grabbing') {
        return
      }
      petriContainer.style.cursor = 'grab'
    } else if (
      petriContainer.style.cursor !== 'zoom-in' &&
      (pickedObject?.name === 'dot' ||
        pickedObject?.name === 'pieRingSlice' ||
        pickedObject?.name === 'record')
    ) {
      petriContainer.style.cursor = 'zoom-in'
    } else if (
      petriContainer.style.cursor !== 'zoom-out' &&
      !pickedObject &&
      lastPickedNode?.name !== 'root'
    ) {
      petriContainer.style.cursor = 'zoom-out'
      // Mouse context/style at top level:
    } else if (
      petriContainer.style.cursor !== 'default' &&
      !pickedObject &&
      lastPickedNode?.name === 'root'
    ) {
      petriContainer.style.cursor = 'default'
    }
  }

  onClick(event, external) {
    const { props } = this
    // Get pickedObject:
    let pickedObject = this.mouse.getPickedObject()
    // Force deselect of any objects if this was called externally
    if (external) {
      pickedObject = null
    }

    // If pickedObject is clicked:
    if (pickedObject && typeof pickedObject.onClick === 'function') {
      const isRecordObject = pickedObject.name === 'record'

      // Save TimeSlider position
      const currentBreadcrumb = props.breadcrumb[props.breadcrumb.length - 1]
      const percentRange = {
        ...TimeSlider.getPercentRange()
      }
      this.timeSliderPercentages[currentBreadcrumb] =
        !percentRange.start &&
        !percentRange.end &&
        this.timeSliderPercentages[currentBreadcrumb]
          ? this.timeSliderPercentages[currentBreadcrumb]
          : percentRange
      this.updateTimeRangeMarkers({ type: 'hide' })

      this.currentTimeRangeTracker[currentBreadcrumb] = {
        ...this.currentTimeRange
      }

      // Call objects onClick callback:
      pickedObject.onClick(
        !isRecordObject ? () => PieRing.setIsLoading(pickedObject) : undefined
      )

      // RecordLayer specific logic:
      if (isRecordObject) {
        // Select record:
        RecordLayer.setSelected(pickedObject)

        // Zoom to record:
        Population.zoomTo(pickedObject, {
          type: Types.RECORD_LAYER_AFTER_ZOOM,
          payload: pickedObject.nodeData
        })
      }

      // If nothing is clicked (zooming out):
    } else {
      if (this.props.onLastLayer && RecordLayer.getSelected() !== null) {
        Population.zoomOut({
          type: Types.RECORD_LAYER_AFTER_ZOOM,
          payload: null
        })

        // Deselect Petri recordLayer:
        RecordLayer.setSelected(null)
        // Standard Petri node zoomOut:
      } else {
        // Zoom out:
        Population.zoomOut(undefined, PieRing.setIsLoading)
      }
    }
  }

  dispatchSliderBoundary() {
    const { timeRange, updateTimeRangeBoundary } = this.props
    const range = TimeSlider.getPercentRange()
    const diff = timeRange.end - timeRange.start
    const start = diff * range.start + timeRange.start
    const end = diff * range.end + timeRange.start
    updateTimeRangeBoundary(timeRange.field, start, end)
  }

  mouseUp(event, external) {
    const { props } = this
    const petriContainer = this.petriRef.current
    // Only handle for left click:
    if (event.button === 0 || event.type === 'touchend') {
      // Deselect slider on mouseUp if one is selected:
      if (TimeSlider.getSelected()) {
        const selectedTimeSlider = TimeSlider.getSelected().name
        TimeSlider.setSelected(null)
        petriContainer.style.cursor = 'default'
        props.onTimeSliderDrag('dragEnd')

        this.updateTimeRangeMarkers({ type: 'onDragEnd', selectedTimeSlider })
        // Otherwise fire Petri click handling:
      } else {
        // Fire click event only if target is not simpleLayerListItem:
        if (!isChildOfClass(event.target, 'simple-item')) {
          this.onClick(event, external)
        }
      }
    }
  }

  mouseDown(event) {
    const petriContainer = this.petriRef.current
    const isLeftClick = event.button === 0
    const isScrollWheelClick = event.button === 1

    if (isLeftClick || event.type === 'touchstart') {
      // Prevent mouse events external to petri container from interfering:
      event.preventDefault()
      const pickedObject = this.mouse.getPickedObject()

      // Grabs slider if pickedObject is a TimeSlider:
      TimeSlider.setSelected(pickedObject)
      if (pickedObject?.type === 'sliderMesh') {
        petriContainer.style.cursor = 'grabbing'
      }
    }

    if (isScrollWheelClick) {
      this.updateTimeRangeMarkers({ type: 'hide' })
    }
  }

  mouseMove(event) {
    const { props } = this

    // Set mouse position:
    const renderer = this.scene.getRenderer()

    const vector2 = new THREE.Vector2(
      renderer.domElement.clientWidth,
      renderer.domElement.clientHeight
    )

    const size = this.scene.getRenderer().getSize(vector2)
    this.mouse.setPosition(event, size)
    // TimeSlider logic if being dragged:
    const selectedTimeSlider = TimeSlider.getSelected()
    if (selectedTimeSlider) {
      props.onTimeSliderDrag('dragging')

      const parentPosition = Population.getLastPickedNode()
        .getWorldPosition(new THREE.Vector3())
        .project(this.camera.getCamera())

      const mousePosition = this.mouse.getPosition()
      // Get slider bounds:
      const range = TimeSlider.updateSliders(
        new THREE.Vector2(
          mousePosition.x - parentPosition.x,
          mousePosition.y - parentPosition.y
        )
      )
      // Calculate datetime to display on TimeSlider labels:
      const diff = props.timeRange.end - props.timeRange.start
      const start = Math.round(diff * range.start + props.timeRange.start)
      const end = Math.round(diff * range.end + props.timeRange.start)

      // Get Slider positions for time display
      const position = {
        start: {},
        mid: {},
        end: {}
      }
      const Camera = this.camera.getCamera()
      TimeSlider.get().forEach((sliderObj) => {
        if (sliderObj.name === 'left') {
          position.start = TimeSlider.objToScreenPosition(
            sliderObj,
            Camera,
            renderer
          )
        }
        if (sliderObj.name === 'mid') {
          position.mid = TimeSlider.objToScreenPosition(
            sliderObj,
            Camera,
            renderer
          )
        }
        if (sliderObj.name === 'right') {
          position.end = TimeSlider.objToScreenPosition(
            sliderObj,
            Camera,
            renderer
          )
        }
      })

      if (this.state.resetButtonDisabled) {
        this.setState({ resetButtonDisabled: false })
      }

      this.setTimeRange({
        selectedTimeSlider: selectedTimeSlider.name,
        start,
        end,
        position
      })

      this.filterPopulation({ range, start, end })
    }
  }

  onSliderChange = (isMidHandle, [start, mid, end]) => {
    const { props } = this
    const { timeRange } = props

    const range = getTimeSliderRangeValues({ timeRange, start, end })

    if (isMidHandle) {
      const diff = (start - end) / 2
      start = mid + diff
      end = mid - diff
    }
    this.setTimeRange({ range, start, end })
    this.filterPopulation({ range, start, end })
  }

  filterPopulation = ({ range, start, end }) => {
    const { props } = this
    if (props.onLastLayer) {
      let records = transformHexLayerSliderData({
        population: props.population,
        range,
        timeRange: props.timeRange,
        start,
        end
      })

      RecordLayer.update(records)

      if (this.state.resultsLength !== records.length) {
        this.setState({ resultsLength: records.length })
      }

      // Otherwise, calculate updated aggregation values/fraction based on slider range:
    } else {
      const scaleFunction = scaleFunctions[props.scale]
      const { scaledData, total } = transformSliderData(
        props.population,
        range,
        scaleFunction
      )

      if (this.state.resultsLength !== total) {
        this.setState({ resultsLength: total })
      }

      Population.bind(scaledData)
      Population.updateLayer()
    }
  }

  mouseOut(event) {
    // Prevent locking of slider when mouse moves out of petri container:
    if (isChildOfClass(event.toElement, 'simple-layerlist-container')) {
      return
    }

    // Deselect TimeSlider:
    if (TimeSlider.getSelected()) {
      TimeSlider.setSelected(null)
    }
    const renderer = this.scene.getRenderer()

    const vector2 = new THREE.Vector2(
      renderer.domElement.clientWidth,
      renderer.domElement.clientHeight
    )

    const size = this.scene.getRenderer().getSize(vector2)
    // Force mouse position to be way out:
    this.mouse.setPosition(event, size, true)
  }

  mouseWheel = () => {
    this.updateTimeRangeMarkers({ type: 'hide' })
  }

  resizeViewport() {
    // Get Petri container
    let container = this.petriRef.current

    if (container && this.scene) {
      // Remove this statement if you do not want Petri to be the same height as the window
      if (window.innerHeight !== container.clientHeight) {
        container.style.height = `${window.innerHeight}px`
      }

      let newDimensions = container.getBoundingClientRect()
      // Resize scene viewport:
      this.scene.resizeViewport(newDimensions)
      this.updateTimeRangeMarkers({ type: 'hide' })

      // Resize/reposition ribbon canvas:
      if (this.ribbon) {
        this.ribbon.updateCanvas()
      }

      // Reset camera position on resize:
      if (Population.getLastPickedNode()) {
        this.camera.resize()
      }
    }
  }

  // Normalize sizes of current Petri layer nodes on SHIFT keydown:
  normalizePetriScale(e) {
    if (e.shiftKey) {
      Population.normalizeLayerScale()
    }
  }

  // Revert sizes of current Petri layer nodes on SHIFT keyup:
  revertPetriScale(e) {
    if (!e.shiftKey) {
      Population.revertLayerScale()
    }
  }

  toggleLoader = (show) => {
    if (show) {
      PieRing.setIsLoading(Population.getRootNode())
      this.isQueryInProgress = true
    } else {
      PieRing.destroy()
      PieRing.bind(this.props.population)
      const parentNode = Population.getRootNode()
      if (parentNode) {
        PieRing.attach(parentNode)
      }
      this.isQueryInProgress = false
    }
  }

  handleHistogramData = (parentNode) => {
    const { props } = this
    const histogramData = transformHistogramData(props.aggregation)

    if (histogramData.length) {
      if (props.isMobile) {
        this.setState({ histogramData })
      } else {
        Histogram.setUp(parentNode, histogramData)
        TimeSlider.reset()
        TimeSlider.show()
        TimeSlider.attach(parentNode)
      }
    }
  }

  handleBreadcrumbClick = (node, index) => {
    RecordLayer.setSelected(null)
    Population.removePhysics()
    Population.detachParentLabel()
    this.breadcrumb.splice(index + 1, this.breadcrumb.length - index - 1)
    Population.zoomTo(
      node,
      {
        type: Types.BREADCRUMB_JUMP,
        payload: index
      },
      PieRing.setIsLoading
    )
  }

  resetTimeRange = () => {
    const { props } = this
    TimeSlider.reset()
    if (props.onLastLayer) {
      RecordLayer.hide()
      RecordLayer.show()
      RecordLayer.update(props.population)
    } else {
      Population.clear()
      Population.bind(props.population)
      Population.updateLayer()
    }

    this.currentTimeRangeTracker = {}
    this.timeSliderPercentages = {}
    this.currentTimeRange = {
      start: null,
      end: null
    }
    this.setState({
      resetButtonDisabled: true,
      resultsLength: props.resultsLength
    })
  }

  setTimeRange = ({
    range,
    selectedTimeSlider,
    start,
    end,
    position = { start: {}, end: {} }
  }) => {
    start = Math.round(start)
    end = Math.round(end)

    if (!this.props.isMobile) {
      this.updateTimeRangeMarkers({
        type: 'position',
        selectedTimeSlider,
        start,
        end,
        position
      })
    }

    this.currentTimeRange = {
      range,
      start,
      end
    }
  }

  updateTimeRangeMarkers = ({
    type = 'position',
    selectedTimeSlider,
    start,
    end,
    position = { start: {}, mid: {}, end: {} }
  }) => {
    const percentRange = TimeSlider.getPercentRange()
    const timeStartEl = this.timeStartRef.current
    const timeEndEl = this.timeEndRef.current
    const resetButtonEl = this.resetButtonRef.current
    const midResetButtonContainerEl = this.midResetButtonContainerRef.current
    if (!timeStartEl || !timeEndEl) {
      return
    }
    if (type === 'position') {
      timeStartEl.lastChild.innerText = formatDate(start)
      timeEndEl.lastChild.innerText = formatDate(end)

      if (
        position.start?.x &&
        position.start?.y &&
        position.end?.x &&
        position.end?.y
      ) {
        this.prevTimeSliderValues = { ...position }

        resetButtonEl.style.opacity = 0
        resetButtonEl.style.display = 'none'
        resetButtonEl.style.pointerEvents = 'none'
        timeStartEl.style.pointerEvents = 'none'
        timeEndEl.style.pointerEvents = 'none'
        midResetButtonContainerEl.style.pointerEvents = 'none'

        const start = getTimeRangeMarkerPosition({
          timeSlider: 'left',
          percentRange,
          element: timeStartEl,
          posX: position.start.x,
          posY: position.start.y
        })

        const end = getTimeRangeMarkerPosition({
          timeSlider: 'right',
          percentRange,
          element: timeEndEl,
          posX: position.end.x,
          posY: position.end.y
        })

        timeStartEl.style.top = `${start.y}px`
        timeStartEl.style.left = `${start.x}px`

        timeEndEl.style.top = `${end.y}px`
        timeEndEl.style.left = `${end.x}px`

        timeStartEl.style.transform = `translate(${start.translate.x}px, ${start.translate.y}px)`
        timeEndEl.style.transform = `translate(${end.translate.x}px, ${end.translate.y}px)`

        timeStartEl.style.padding = '7px 8px'
        timeEndEl.style.padding = '7px 8px'

        if (selectedTimeSlider === 'left') {
          timeStartEl.style.zIndex = '10001'
          timeEndEl.style.zIndex = '10000'
        } else if (selectedTimeSlider === 'right') {
          timeEndEl.style.zIndex = '10001'
          timeStartEl.style.zIndex = '10000'
        }

        const angle = TimeSlider.toAngle(percentRange.mid)
        const posX = position.mid.x
        const posY = position.mid.y
        const radius = -50
        const y = posY + Math.cos(angle) * radius
        const x = posX + Math.sin(angle) * radius
        midResetButtonContainerEl.style.top = `${y}px`
        midResetButtonContainerEl.style.left = `${x}px`

        if (this.state.resetButtonDisabled) {
          this.setState({ resetButtonDisabled: false })
        }
      } else if (!start && !end) {
        this.setState({ resetButtonDisabled: true })
      }
    } else if (type === 'onDragEnd') {
      resetButtonEl.style.pointerEvents = 'unset'

      if (selectedTimeSlider === 'left') {
        timeStartEl.style.pointerEvents = 'unset'
        const onLeftSide = percentRange.start > 0.5
        timeStartEl.firstElementChild.appendChild(resetButtonEl)
        if (onLeftSide) {
          timeStartEl.firstElementChild.style.right = 0
          timeStartEl.firstElementChild.style.left = 'unset'
        } else {
          timeStartEl.firstElementChild.style.left = 0
          timeStartEl.firstElementChild.style.right = 'unset'
        }

        resetButtonEl.style.display = 'unset'
        resetButtonEl.style.opacity = 1
        timeStartEl.style.padding = onLeftSide
          ? '7px 32px 7px 8px'
          : '7px 8px 7px 32px'
      } else if (selectedTimeSlider === 'mid') {
        midResetButtonContainerEl.style.pointerEvents = 'unset'
        midResetButtonContainerEl.appendChild(resetButtonEl)
        resetButtonEl.style.display = 'unset'
        resetButtonEl.style.opacity = 1
      } else if (selectedTimeSlider === 'right') {
        timeEndEl.style.pointerEvents = 'unset'
        const onLeftSide = percentRange.end > 0.5
        timeEndEl.firstElementChild.appendChild(resetButtonEl)
        if (onLeftSide) {
          timeEndEl.firstElementChild.style.right = 0
          timeEndEl.firstElementChild.style.left = 'unset'
        } else {
          timeEndEl.firstElementChild.style.left = 0
          timeEndEl.firstElementChild.style.right = 'unset'
        }
        resetButtonEl.style.display = 'unset'
        resetButtonEl.style.opacity = 1
        timeEndEl.style.padding = onLeftSide
          ? '7px 32px 7px 8px'
          : '7px 8px 7px 32px'
      }
    } else if (type === 'hide' && !this.state.resetButtonDisabled) {
      this.setState({ resetButtonDisabled: true })
    }
  }

  render() {
    const { props, state } = this

    return (
      <div className={`${styles.petriWrapper} ${props.className}`}>
        <Breadcrumb
          breadcrumb={this.breadcrumb}
          population={props.population}
          resultsLength={state.resultsLength}
          totalDocs={props.totalDocs}
          fieldList={props.layers}
          onClick={this.handleBreadcrumbClick}
        />

        <>
          {props.isMobile ? (
            <MobileTimeSlider
              getCurrentTimeRange={() => this.currentTimeRange}
              histogramData={state.histogramData}
              onChange={this.onSliderChange}
              resetTimeRange={this.resetTimeRange}
              timeRange={props.timeRange}
            />
          ) : (
            <TimeRange
              startRef={this.timeStartRef}
              endRef={this.timeEndRef}
              resetButtonRef={this.resetButtonRef}
              midResetButtonContainerRef={this.midResetButtonContainerRef}
              filterField={props.timeRange.field}
              resetTimeRange={this.resetTimeRange}
              resetButtonDisabled={state.resetButtonDisabled}
              timeRange={props.timeRange}
            />
          )}
        </>
        <div
          id="petri"
          className={styles.petriContainer}
          tabIndex="0"
          ref={this.petriRef}
        />
      </div>
    )
  }
}

Petri.propTypes = {
  aggregation: PropTypes.array,
  population: PropTypes.array.isRequired,
  isWaitingForAsync: PropTypes.bool.isRequired,
  clear: PropTypes.bool.isRequired,
  onLastLayer: PropTypes.bool.isRequired,
  changingBreadcrumb: PropTypes.func.isRequired,
  totalDocs: PropTypes.number,
  layers: PropTypes.array.isRequired,
  isNormalized: PropTypes.bool,
  scale: PropTypes.number,
  currentTimeRange: PropTypes.object,
  timeRange: PropTypes.object,
  performQuery: PropTypes.func,
  setCurrentTimeRange: PropTypes.func,
  updateTimeRangeBoundary: PropTypes.func,
  onRecordZoom: PropTypes.func,
  resultsLength: PropTypes.number
}

Petri.defaultProps = {
  clear: false,
  isWaitingForAsync: false,
  scale: 1,
  timeRange: {
    start: 1559347260000,
    end: 1575417660000,
    field: 'effect_start_date'
  },
  layers: [],
  currentTimeRange: null,
  onLastLayer: false,
  population: [],
  changingBreadcrumb: () => console.log('changing breadcrumb'),
  performQuery: () => console.log('performQuery'),
  setCurrentTimeRange: () => {},
  onSelectRecordLayer: () => console.log('onSelectRecordLayer')
}

Petri.displayName = 'Petri'

export default Petri
