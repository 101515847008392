import React from 'react'
import {
  DefaultButton,
  Coachmark,
  Text,
  Icon,
  IconButton,
  mergeStyleSets
} from 'office-ui-fabric-react'
import { boxShadow, transition, duration, easing } from 'styles/constants'
import { getTheme } from '@uifabric/styling'
import * as Types from 'lib/types'

const theme = getTheme()

const beakSize = 32
const tutorialContainerWidth = 550
const cardPadding = 16
const baseTutorialCardText = {
  transition: `transform ${duration.enteringScreen}ms ${easing.easeOut}, opacity ${duration.enteringScreen}ms ${easing.easeOut}`,
  opacity: 0,
  transform: 'translateY(32px)'
}

const styles = mergeStyleSets({
  tutorialCardBeak: {
    position: 'absolute',
    height: beakSize,
    width: beakSize,
    backgroundColor: theme.palette.themePrimary,
    transform: 'rotate(45deg)',
    boxShadow: boxShadow.elevation64,
    top: -(beakSize / 2) + 2,
    right: 6,
    borderRadius: '4px 4px 0px 0px',
    transition: transition.backgroundColor
  },
  tutorialCardCloseButton: {
    top: 8,
    right: 8,
    position: 'absolute',
    zIndex: 2
  },
  tutorialContainer: {
    zIndex: 1000001,
    maxWidth: tutorialContainerWidth,
    position: 'fixed',
    boxShadow: boxShadow.elevation64,
    transform: 'scale(0)',
    // transition: transition.transformShort,
    transition: transition.all
  },
  tutorialCard: {
    borderRadius: 4,
    zIndex: 1,
    padding: cardPadding,
    backgroundColor: theme.palette.themePrimary,
    color: 'white',
    position: 'relative',
    overflow: 'hidden'
  },
  tutorialCardHeadline: {
    fontWeight: '500',
    marginTop: 0,
    ...baseTutorialCardText
  },
  tutorialCardBody: {
    ...baseTutorialCardText
  },
  tutorialCardHint: {
    display: 'flex',
    alignItems: 'flex-start',
    ...baseTutorialCardText
  },
  tutorialCardSuccessText: {
    position: 'absolute',
    width: `calc(100% - ${cardPadding * 2}px)`,
    height: `calc(100% - ${cardPadding * 2}px)`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 1,
    userSelect: 'none',
    pointerEvents: 'none',
    ...baseTutorialCardText
  },
  tutorialCardReveal: {
    position: 'absolute',
    backgroundColor: 'var(--orange)',
    height: '100%',
    width: '100%',
    top: 0,
    left: 0,
    clipPath: 'circle(0%)',
    transition: transition.clipPath,
    userSelect: 'none',
    pointerEvents: 'none'
  },
  tutorialContainerShow: {
    transform: 'scale(1)'
  }
})

type Props = {
  currentStep: Types.Step
  finishText: string
  isFinished: boolean
  onDismiss: TimerHandler | any
  onAnswerClick: Function
  show: boolean
}

const TutorialCard = ({
  currentStep,
  finishText,
  isFinished,
  onDismiss,
  onAnswerClick,
  show
}: Props) => {
  const ref = React.useRef<HTMLDivElement>(null)
  const successTextRef = React.useRef<HTMLDivElement>(null)
  const beakRef = React.useRef<HTMLDivElement>(null)
  const revealRef = React.useRef<HTMLDivElement>(null)
  const targetRef = React.useRef<HTMLDivElement>(null)
  const contentCollectionRef = React.useRef<NodeListOf<HTMLElement>>(null)
  const className = React.useMemo(
    () =>
      `${styles.tutorialContainer} ${show ? styles.tutorialContainerShow : ''}`,
    [show]
  )
  const [style, setStyle] = React.useState({ beak: {}, container: {} })

  const toggleContentView = React.useCallback((show = true) => {
    if (contentCollectionRef.current) {
      if (show) {
        let tutorialAnswersList: NodeListOf<HTMLElement>
        contentCollectionRef.current.forEach((item, i) => {
          const delay = i * 200
          if (item.id === 'tutorialAnswersList') {
            // @ts-ignore
            tutorialAnswersList = item.childNodes
            // @ts-ignore
            tutorialAnswersList.forEach(
              // @ts-ignore
              (answerEl: HTMLElement, answerIndex) => {
                if (answerEl.style) {
                  answerEl.style.transitionDelay = `${
                    delay + answerIndex * 200
                  }ms`
                  answerEl.style.transform = 'translateX(0px)'
                  answerEl.style.opacity = '1'
                }
              }
            )
          } else {
            item.style.transitionDelay = `${delay}ms`
            item.style.transform = 'translateY(0px)'
            item.style.opacity = '1'
          }

          if (contentCollectionRef.current) {
            const isLastNode = contentCollectionRef.current.length - 1 === i
            if (isLastNode) {
              item.addEventListener('transitionend', () => {
                tutorialAnswersList.forEach((answerEl) => {
                  if (answerEl.style) {
                    answerEl.style.transitionDelay = '0ms'
                  }
                })
              })
            }
          }
        })
      } else {
        contentCollectionRef.current.forEach((item) => {
          if (item.id === 'tutorialAnswersList') {
            // @ts-ignore
            item.childNodes.forEach((answerEl: HTMLElement) => {
              if (answerEl.style) {
                answerEl.style.transitionDelay = '0ms'
                answerEl.style.transform = 'translateX(100px)'
                answerEl.style.opacity = '0'
              }
            })
          } else {
            item.style.transform = 'translateY(32px)'
            item.style.transitionDelay = '0ms'
            item.style.opacity = '0'
          }
        })
      }
    }
  }, [])

  const toggleSuccessView = React.useCallback(
    (show = true) => {
      if (
        contentCollectionRef.current &&
        successTextRef.current &&
        revealRef.current &&
        beakRef.current
      ) {
        if (show) {
          successTextRef.current.addEventListener(
            'transitionend',
            () => {
              toggleContentView(false)
              setTimeout(onDismiss, 2000)
            },
            { once: true }
          )

          beakRef.current.style.backgroundColor = 'var(--orange)'
          revealRef.current.style.clipPath = 'circle(100%)'
          successTextRef.current.style.transform = 'translateY(0px)'
          successTextRef.current.style.opacity = '1'
        } else {
          revealRef.current.style.clipPath = 'circle(0%)'
          beakRef.current.style.backgroundColor = theme.palette.themePrimary
          successTextRef.current.style.transform = 'translateY(32px)'
          successTextRef.current.style.opacity = '0'
        }
      }
    },
    [onDismiss, toggleContentView]
  )

  const onStepChange = React.useCallback(() => {
    toggleContentView(false)
    toggleSuccessView(true)
  }, [toggleContentView, toggleSuccessView])

  const setStyles = React.useCallback(() => {
    if (targetRef.current) {
      const targetDems = targetRef.current.getBoundingClientRect()
      let styles = {
        beak: {},
        container: {}
      }
      switch (currentStep.align) {
        case 'leftTop':
          styles.container = {
            top: targetDems.top + targetDems.height + beakSize / 2,
            left: targetDems.left - tutorialContainerWidth / 2,
            transformOrigin: 'top right'
          }
          break
        case 'centerBottom':
          if (show) {
            styles.container = {
              bottom: 16,
              left: 16,
              width: 'calc(100vw - 32px)',
              height: 'unset',
              transformOrigin: 'bottom center'
            }
            styles.beak = {
              top: 'unset',
              right: 'unset',
              bottom: -14,
              left: `calc(50vw - ${beakSize}px)`
            }
          } else {
            styles.container = {
              bottom: targetDems.bottom,
              left: targetDems.left,
              width: targetDems.width,
              height: targetDems.height
            }
          }

          break
      }
      setStyle(styles)
    }
  }, [currentStep.align, setStyle, show])

  const onHide = React.useCallback(() => {
    if (
      contentCollectionRef.current &&
      successTextRef.current &&
      revealRef.current &&
      beakRef.current
    ) {
      toggleSuccessView(false)
      toggleContentView(false)
    }
  }, [toggleContentView, toggleSuccessView])

  React.useLayoutEffect(() => {
    if (!targetRef.current) {
      let targetElement

      if (typeof currentStep.cardTarget === 'string') {
        targetElement = document.querySelector(currentStep.cardTarget)
      } else {
        targetElement = currentStep.cardTarget
      }
      // @ts-ignore
      targetRef.current = targetElement
    }
  }, [setStyles, currentStep.cardTarget])

  React.useLayoutEffect(() => {
    const tutorialContainer = ref.current

    if (tutorialContainer) {
      if (!contentCollectionRef.current) {
        // @ts-ignore
        contentCollectionRef.current = tutorialContainer.querySelectorAll(
          '[data-tutorialtransitionid = "tutorialContent"]'
        )
      }

      tutorialContainer.ontransitionend = ({
        propertyName,
        target = { id: '' }
      }) => {
        if (
          propertyName === 'transform' &&
          // @ts-ignore
          target?.id === 'tutorialContainer'
        ) {
          if (show) {
            setStyles()
            toggleContentView(true)
          } else {
            onHide()
          }
        }
      }
    }
  }, [setStyles, show, toggleContentView, onHide])

  React.useEffect(() => {
    if (show) {
      onStepChange()
    }
    // eslint-disable-next-line
  }, [currentStep])

  React.useEffect(() => {
    if (isFinished) {
      toggleSuccessView(true)
    }
    // eslint-disable-next-line
  }, [isFinished])

  return (
    <>
      {show && currentStep.indicatorTarget && (
        <Coachmark target={currentStep.indicatorTarget} />
      )}
      <div
        id={'tutorialContainer'}
        style={style.container}
        ref={ref}
        className={className}
      >
        <div
          ref={beakRef}
          className={styles.tutorialCardBeak}
          style={style.beak}
        />

        <div className={styles.tutorialCard}>
          <IconButton
            className={styles.tutorialCardCloseButton}
            iconProps={{ iconName: 'Cancel' }}
            onClick={onDismiss}
          />

          <div ref={successTextRef} className={styles.tutorialCardSuccessText}>
            <Text
              data-tutorialtransitionid={'tutorialSuccessText'}
              block={true}
              variant={'xxLarge'}
              styles={{ root: { fontSize: 52 } }}
            >
              {isFinished ? finishText : currentStep.successText}
            </Text>
          </div>

          <Text
            data-tutorialtransitionid={'tutorialContent'}
            className={styles.tutorialCardHeadline}
            as={'p'}
            block={true}
            variant={'mediumPlus'}
          >
            {currentStep.headline}
          </Text>
          <Text
            data-tutorialtransitionid={'tutorialContent'}
            className={styles.tutorialCardBody}
            as={'p'}
            block={true}
            variant={'medium'}
          >
            {currentStep.content}
          </Text>

          <AnswersList onClick={onAnswerClick} answer={currentStep.answer}>
            {currentStep.answers}
          </AnswersList>

          <Text
            data-tutorialtransitionid={'tutorialContent'}
            className={styles.tutorialCardHint}
            block={true}
            variant={'small'}
          >
            <Icon
              title={'Hint'}
              styles={{ root: { marginRight: 4 } }}
              iconName={'HintText'}
            />
            {currentStep.hint}
          </Text>

          <div ref={revealRef} className={styles.tutorialCardReveal} />
        </div>
      </div>
    </>
  )
}

export default TutorialCard

const answersListStyles = mergeStyleSets({
  answersListContainer: {
    marginBottom: 16
  },
  answersListItem: {
    opacity: 0,
    transform: 'translateX(100px)',
    transition: `${transition.boxShadow}, ${transition.backgroundColor}, ${transition.color}, ${transition.transformShort}, ${transition.opacityShort}`,
    padding: 8,
    backgroundColor: 'var(--overlay)',
    borderRadius: 16,
    marginRight: 8,
    marginTop: 8,
    marginBottom: 8,
    boxShadow: boxShadow.elevation4,
    '&:hover': {
      transitionDelay: '0ms !important',
      borderColor: 'white',
      color: 'white',
      backgroundColor: 'transparent',
      boxShadow: boxShadow.elevation8
    },
    '&:disabled': {
      borderColor: 'var(--red)',
      transform: 'rotateX(360deg) !important',
      transitionDelay: '0ms !important',
      textDecoration: 'line-through'
    }
  }
})

const AnswersList = ({
  answer,
  children,
  onClick
}: {
  answer: string
  children: string[]
  onClick: Function
}) => {
  const [disabled, setDisabled] = React.useState<{ [key: string]: boolean }>({})

  React.useEffect(() => {
    const disabledObj: { [key: string]: boolean } = {}
    children.forEach((val) => {
      disabledObj[val] = false
    })
    setDisabled(disabledObj)
  }, [children])

  const onAnswerClick = React.useCallback(
    (selectedAnswer) => {
      if (selectedAnswer === answer) {
        onClick(answer)
      } else {
        setDisabled({ ...disabled, [selectedAnswer]: true })
      }
    },
    [answer, onClick, disabled, setDisabled]
  )
  return (
    <div
      id={'tutorialAnswersList'}
      data-tutorialtransitionid={'tutorialContent'}
      className={answersListStyles.answersListContainer}
    >
      {children.map((value, i) => {
        return (
          <DefaultButton
            key={i}
            disabled={disabled[value]}
            onClick={() => onAnswerClick(value)}
            className={answersListStyles.answersListItem}
          >
            {value}
          </DefaultButton>
        )
      })}
    </div>
  )
}
