import icosahedron from 'components/Petri/lib/recordlayer/resources/icosahedron.obj'

export default {
  Sphere: [
    {
      type: 'geometry',
      name: 'SphereGeometry',
      data: {
        type: 'SphereGeometry',
        radius: 0.435,
        widthSegments: 50,
        heightSegments: 50
      }
    },
    {
      type: 'material',
      name: 'SphereMaterial',
      data: {
        type: 'MeshBasicMaterial',
        color: 0x777777,
        opacity: 0.6,
        transparent: true
      }
    }
  ],
  Icosahedron: [
    {
      type: 'object',
      name: 'IcosahedronGeometry',
      data: {
        url: icosahedron
      }
    },
    {
      type: 'material',
      name: 'IcosahedronMaterial',
      data: {
        type: 'MeshBasicMaterial',
        color: 0x4dacff,
        wireframe: false,
        depthWrite: true
      }
    }
  ],
  Octagon: [
    {
      type: 'geometry',
      name: 'OctagonGeometry',
      data: {
        type: 'CircleGeometry',
        radius: 0.435,
        segments: 6
      }
    },
    {
      type: 'material',
      name: 'OctagonMaterial',
      data: {
        type: 'MeshBasicMaterial',
        color: 0x4dacff,
        wireframe: false,
        depthWrite: true
      }
    }
  ]
}
