import CORE3D from '../core-3d'
import assets from './assets.js'

const loaded = {}

export default {
  init,
  load
}

function init() {
  // Initialize loaded to false for all group assets:
  Object.keys(assets).forEach((key) => {
    loaded[key] = false
  })
}

function load(groupName) {
  if (!loaded[groupName]) {
    let promises = []
    assets[groupName].forEach((json) => {
      promises.push(loadJSON(json))
    })
    return Promise.all(promises)
      .then(() => {
        loaded[groupName] = true
      })
      .catch(() => {
        console.warn('Failed to load', groupName, 'assets')
      })
  } else {
    return Promise.resolve()
  }
}

// Private:
function loadJSON(json) {
  if (json.loaded === false) {
    return CORE3D.load(json)
      .then((asset) => {
        json.loaded = true
        return asset
      })
      .catch(() => {
        console.warn('Failed to load', json.name)
      })
  }
}
