import React from 'react'
import { mergeStyleSets } from '@uifabric/styling'
import { transition } from 'styles/constants'

type Props = {
  open: boolean
  children: React.ReactElement
}

const styles = mergeStyleSets({
  bottomSheet: {
    boxShadow:
      'rgba(0, 0, 0, 0.133) 0px -6.4px 14.4px 0px, rgba(0, 0, 0, 0.11) 0px -1.2px 3.6px 0px;',
    background: 'rgba(16, 25, 35, 0.9)',
    position: 'fixed',
    height: '100vh',
    top: '100vh',
    right: 0,
    left: 0,
    transition: transition.transformShort,
    zIndex: 10001,
    overflowY: 'auto',
    padding: '16px 8px 32px 8px',
    borderRadius: '16px 16px 0px 0px'
  }
})
const BottomSheet = (props: Props) => {
  return (
    <div
      className={styles.bottomSheet}
      style={{
        transform: `translateY(${props.open ? `calc(-100vh + 48px)` : '0px'})`
      }}
    >
      {props.children}
    </div>
  )
}

export default BottomSheet
