import * as THREE from 'three'

export default {
  init
}

function init(scene, cameraOptions) {
  const methods = {
    getCamera,
    reset: resetCamera,
    resize: resizeCamera,
    move: moveCamera,
    update: updateCamera,
    animateTo,
    lookAt
  }

  const CS = scene
  const startingDiameter = 1

  // Camera settings:
  const zoomPadding = 0.65 // 1 = no padding, 0 = all padding
  const tweenTime = 400

  // Lock camera:
  const debugCamera = false
  const lockCamera = debugCamera

  let tweenStartLocation = null // x, y, radius
  let tweenEndLocation = null // x, y, radius
  let tweenStartDiameter = startingDiameter
  let tweenEndDiameter = null
  let tweenStartTime = 0
  let tweenEndTime = 0
  let lerpAlpha = 0
  let isTweening = false
  let defaultCallback = () => {
    // console.log('Camera tween completed!')
  }
  let nextCallback = defaultCallback

  // const initialPosition = new THREE.Vector3(0, 0, startingDiameter)
  CS.buildCamera(cameraOptions)

  let zoomScale = 1 // Updated based on container size
  let currentDiameter = startingDiameter

  // If we need orbit controls:
  // if (debugCamera) {
  //   CS.buildControls()
  // } else {
  setZoom() // This will set the zoom level of the camera
  CS.getCamera().updateProjectionMatrix()
  // }

  return methods
  //
  // function isDebugCamera() {
  //   return debugCamera
  // }

  function lookAt(vec) {
    // const c = CS.getCamera()
    // c.lookAt(vec)
    CS.getControls().target.set(vec.x, vec.y, vec.z)
  }
  //
  // function isMoving() {
  //   return isTweening
  // }

  function getCamera() {
    return CS.getCamera()
  }

  function resetCamera() {
    animateTo(0, 0, startingDiameter)
  }

  function resizeCamera() {
    const viewport = CS.getViewport()
    const camera = CS.getCamera()
    if (camera.type === 'PerspectiveCamera') {
      camera.aspect = viewport.viewWidth / viewport.viewHeight
    } else if (camera.type === 'OrthographicCamera') {
      camera.left = viewport.viewWidth / -2
      camera.right = viewport.viewWidth / 2
      camera.top = viewport.viewHeight / 2
      camera.bottom = viewport.viewHeight / -2
    }
    setZoom()
    camera.updateProjectionMatrix()
  }

  function moveCamera(newX, newY, newZoom) {
    const camera = CS.getCamera()
    camera.position.x = newX
    camera.position.y = newY
    camera.zoom = Math.abs(newZoom) // Abs = don't flip camera
    camera.updateProjectionMatrix()
  }

  function updateCamera(timestamp) {
    if (isTweening) {
      let nextPosOnTween = null
      let nextDiaOnTween = null

      // Clone the start location vector so we can mutate it without
      // changing its value:
      const posToLerp = tweenStartLocation.clone()

      // Figure out the alpha value we'll need for the tween (0-1):
      lerpAlpha = (timestamp - tweenStartTime) / (tweenEndTime - tweenStartTime)

      // We don't want to overshoot our target:
      if (lerpAlpha > 1) {
        nextPosOnTween = posToLerp.lerp(tweenEndLocation, 1)
        nextDiaOnTween = tweenEndDiameter
        currentDiameter = tweenEndDiameter
        isTweening = false
        lerpAlpha = 0
        if (typeof nextCallback !== 'undefined') {
          nextCallback()
          nextCallback = defaultCallback // reset the callback
        }
      } else {
        nextPosOnTween = posToLerp.lerp(tweenEndLocation, lerpAlpha)
        nextDiaOnTween =
          tweenStartDiameter +
          lerpAlpha * (tweenEndDiameter - tweenStartDiameter)
      }

      // Calculate the zoom based on this lerp's diameter:
      const nextZoom = calculateZoom(nextDiaOnTween)
      moveCamera(nextPosOnTween.x, nextPosOnTween.y, nextZoom)
    }
  }

  function animateTo(newX, newY, newDiameter, callback) {
    const cam = CS.getCamera()
    tweenStartLocation = new THREE.Vector2(cam.position.x, cam.position.y)
    tweenEndLocation = new THREE.Vector2(newX, newY)
    // Set current diameter based off of current zoom:
    currentDiameter = calculateDiameter(cam.zoom)
    tweenStartDiameter = currentDiameter
    tweenEndDiameter = newDiameter
    tweenStartTime = window.performance.now()
    tweenEndTime = tweenStartTime + tweenTime
    nextCallback = callback || defaultCallback

    isTweening = !lockCamera // only allow camera tween if unlocked
  }

  // private
  function setZoom() {
    if (!debugCamera) {
      const viewport = CS.getViewport()
      // Change zoomScale based on aspect ratio of container:
      zoomScale =
        Math.min(viewport.viewWidth, viewport.viewHeight) * zoomPadding
      CS.getCamera().zoom = calculateZoom()
    }
  }

  function calculateZoom(diameter) {
    diameter = diameter || currentDiameter
    return zoomScale / diameter
  }

  function calculateDiameter(zoom) {
    return zoomScale / zoom
  }
}
