import * as React from 'react'
import { IconButton, mergeStyleSets } from 'office-ui-fabric-react'
import { Slider } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { transition, boxShadow, duration, easing } from 'styles/constants'
import { formatDate, getTimeSliderRangeValues } from 'lib/utils'
import { AppContext } from 'Context'
import isEqual from 'react-fast-compare'

type TimeRange = {
  start: number
  mid: number
  end: number
  range: { start: number; end: number }
}

type CurrentTimeRange = {
  range: { start: number; end: number }
  start: number
  mid: number
  end: number
}

type Props = {
  histogramData: number[]
  onChange: (isMidHandle: boolean, values: number[]) => void
  resetTimeRange: () => void
  timeRange: TimeRange
  getCurrentTimeRange: () => CurrentTimeRange
}

const styles = mergeStyleSets({
  mobileTimeSliderContainer: {
    zIndex: 1000,
    position: 'fixed',
    right: 28
  },
  mobileTimeSliderButton: {
    position: 'fixed',
    right: 16,
    zIndex: 1000,
    boxShadow: boxShadow.elevation16
  },
  mobileTimeSliderResetButton: {
    position: 'fixed',
    right: 62,
    zIndex: 1000,
    boxShadow: boxShadow.elevation16,
    transition: transition.transform,
    transformOrigin: 'center right'
  },
  mobileTimeSliderDateSlider: {
    position: 'absolute !important',
    fill: 'var(--orange)',
    right: -18
  },
  mobileTimeSliderHistogramTick: {
    transition: `${transition.width}, ${transition.opacityShort}`,
    boxShadow: boxShadow.elevation4
  },
  mobileTimeSliderHistogramGroup: { transform: 'translateX(4px)' },
  mobileTimeSliderHistogramLine: {
    fill: 'rgba(255, 128, 0, 0.3)',
    x: 2,
    transition: transition.height,
    transitionDelay: '0ms',
    boxShadow: boxShadow.elevation4
  },
  mobileTimeSliderHistogramSvg: {
    position: 'absolute',
    right: 0,
    height: '100%',
    transform: 'rotateZ(180deg)',
    pointerEvents: 'none',
    userSelect: 'none'
  }
})

const MobileTimeSlider = (props: Props) => {
  const { windowDimensions } = React.useContext(AppContext)
  const { onChange, getCurrentTimeRange } = props
  const calculateMidRange = React.useCallback(
    (start: number, end: number) => (start + end) / 2,
    []
  )

  const [open, setOpen] = React.useState(false)
  const [height, setHeight] = React.useState(windowDimensions.height - 180)

  React.useEffect(() => {
    setHeight(windowDimensions.height - 180)
  }, [windowDimensions])

  const initialTimeRangeState = React.useMemo(() => {
    return {
      start: props.timeRange.start,
      mid: calculateMidRange(props.timeRange.start, props.timeRange.end),
      end: props.timeRange.end,
      range: {
        start: 0,
        end: 1
      }
    }
  }, [props.timeRange, calculateMidRange])

  const currentTimeRangeState = React.useMemo(() => {
    const { end, start } = getCurrentTimeRange()
    const range = getTimeSliderRangeValues({
      timeRange: props.timeRange,
      start,
      end
    })

    return {
      start: start || initialTimeRangeState.start,
      mid: calculateMidRange(start, end) || initialTimeRangeState.mid,
      end: end || initialTimeRangeState.end,
      range: {
        start: range?.start || 0,
        end: range?.end || 1
      }
    }
  }, [
    getCurrentTimeRange,
    props.timeRange,
    initialTimeRangeState,
    calculateMidRange
  ])
  const shouldHideTimeSlider = React.useMemo(
    () => (!currentTimeRangeState.start || !currentTimeRangeState.end) && !open,
    [currentTimeRangeState, open]
  )
  const [timeRange, setTimeRange] = React.useState(initialTimeRangeState)

  const onSliderChange = React.useCallback(
    (event, values) => {
      const isMidHandle = event.target.dataset.index === '1'
      requestAnimationFrame(() => {
        onChange(isMidHandle, values)
        const { range, start, end } = getCurrentTimeRange()
        setTimeRange({ start, mid: calculateMidRange(start, end), end, range })
      })
    },
    [onChange, getCurrentTimeRange, calculateMidRange]
  )

  React.useEffect(() => {
    if (currentTimeRangeState.start && currentTimeRangeState.end) {
      setTimeRange(currentTimeRangeState)
    } else {
      setOpen(false)
    }
  }, [currentTimeRangeState])

  return (
    <>
      <div
        className={styles.mobileTimeSliderContainer}
        style={{ height, top: windowDimensions.height - height - (100 + 16) }}
      >
        <HistogramSVG
          height={height}
          histogramData={props.histogramData}
          open={open}
          range={timeRange.range}
        />
        <DateSlider
          height={height}
          min={props.timeRange.start}
          max={props.timeRange.end}
          open={open}
          onChange={onSliderChange}
          timeRange={timeRange}
          showMidSlider={
            open &&
            !(
              props.timeRange.start === timeRange.start &&
              props.timeRange.end === timeRange.end
            )
          }
        />
      </div>

      <IconButton
        className={styles.mobileTimeSliderButton}
        onClick={() => setOpen(!open)}
        iconProps={{ iconName: open ? 'Cancel' : 'DateTime' }}
        styles={{
          root: {
            color: 'var(--white)',
            backgroundColor: 'var(--neutralLight)',
            borderRadius: '50%',
            top: windowDimensions.height - 100,
            display: shouldHideTimeSlider ? 'none' : 'unset'
          }
        }}
      />
      <IconButton
        disabled={isEqual(initialTimeRangeState, timeRange)}
        title="Reset Time"
        ariaLabel="Reset Time"
        className={styles.mobileTimeSliderResetButton}
        onClick={() => {
          props.resetTimeRange()
          setTimeRange(initialTimeRangeState)
        }}
        iconProps={{ iconName: 'Refresh' }}
        styles={{
          root: {
            color: 'var(--white)',
            backgroundColor: 'var(--orange)',
            borderRadius: '50%',
            transform: `scale(${open ? 1 : 0})`,

            top: windowDimensions.height - 100
          }
        }}
      />
    </>
  )
}

MobileTimeSlider.defaultProps = {
  histogramData: []
}

export default MobileTimeSlider

const HistogramSVG = (props: {
  height: number
  histogramData: number[]
  open: boolean
  range: { start: number; end: number }
}) => {
  const { ticks, delay, max } = React.useMemo(() => {
    let i = 0
    const max = props.histogramData.reduce(
      (acc, val) => (acc > val ? acc : val),
      0
    )
    let histogramTicks = props.histogramData.map((val, index) => {
      const w = (val / max) * 50
      const y = Math.round((props.height / props.histogramData.length) * index)
      return { w, y, val }
    })

    return {
      max,
      ticks: histogramTicks,
      delay: histogramTicks.map(({ w }) => {
        let transitionDelay = 0
        if (w !== 0) {
          transitionDelay = i * 5
          i++
        }
        return transitionDelay
      })
    }
  }, [props.height, props.histogramData])
  return (
    <svg
      className={styles.mobileTimeSliderHistogramSvg}
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        className={styles.mobileTimeSliderHistogramLine}
        width={2}
        height={props.open ? props.height - 3 : 0}
      />
      <g className={styles.mobileTimeSliderHistogramGroup}>
        {ticks.map(({ y, w, val }, index) => {
          const I = props.open ? index : ticks.length - 1 - index
          const transitionDelay = `${props.open ? delay[I] : delay[I] / 2}ms`

          const Max = (val / max) * 100
          const offset = {
            start: 10,
            mid: Max > 50 ? 80 : null,
            end: Max > 80 ? 100 : null
          }
          const gradientId = `tick_${index}`
          return (
            <g key={index}>
              <defs>
                <linearGradient
                  id={gradientId}
                  x1="0%"
                  y1="0%"
                  x2="100%"
                  y2="0%"
                >
                  <stop
                    offset={`${offset.start}%`}
                    style={{ stopColor: 'var(--yellow)', stopOpacity: 1 }}
                  />
                  {offset.mid && (
                    <stop
                      offset={`${offset.mid}%`}
                      style={{ stopColor: 'var(--white)', stopOpacity: 1 }}
                    />
                  )}

                  {offset.end && (
                    <stop
                      offset={`${offset.end}%`}
                      style={{ stopColor: 'var(--red)', stopOpacity: 1 }}
                    />
                  )}
                </linearGradient>
              </defs>
              <rect
                fill={`url(#${gradientId})`}
                data-index={index}
                className={styles.mobileTimeSliderHistogramTick}
                style={{
                  transition: `width ${
                    props.open ? duration.enteringScreen : duration.shortest
                  }ms ${easing.sharp} ${transitionDelay}, opacity 50ms ${
                    easing.sharp
                  } 5ms`,
                  opacity:
                    props.height * props.range.end < y ||
                    props.height * props.range.start > y
                      ? 0.3
                      : 1
                }}
                y={y}
                width={props.open ? w : 0}
                height={2}
              />
            </g>
          )
        })}
      </g>
    </svg>
  )
}

const useStyles = makeStyles({
  active: {
    transform: 'scale(1.3) !important'
  },
  rail: {
    opacity: 0
  },
  root: {
    color: 'var(--orange)',
    opacity: 0
  },
  thumb: {
    transition: transition.transformShort,
    transform: 'scale(1)'
  },
  trackTransition: {
    transition: transition.transformShort
  },
  valueLabel: {
    transform: 'scale(0) !important',
    left: 'unset',
    top: -3,
    fontSize: '1rem',
    '&.MuiSlider-valueLabel > span': {
      transformOrigin: 'right center !important',
      right: 8,
      position: 'absolute',
      backgroundColor: 'var(--darkOverlay)',
      backdropFilter: 'blur(2px)',
      pointerEvents: 'none',
      userSelect: 'none',
      borderRadius: '32px !important',
      width: 'unset',
      height: 'unset',
      transform: 'rotate(0deg)',
      padding: '2px 8px'
    },
    '&.MuiSlider-valueLabel > span > span': {
      whiteSpace: 'nowrap',
      transform: 'rotate(0deg)'
    }
  },
  show: {
    transform: 'scale(1) translateX(-16px) !important'
  },
  onMidShow: {
    transform: 'scale(1) !important'
  },
  hidden: {
    transform: 'scale(0) !important'
  },
  trackClosed: {
    transform: 'scaleY(0)'
  },
  trackOpen: {
    transform: 'scaleY(1)'
  }
})

const DateSlider = (props: {
  height: number
  min: number
  max: number
  onChange?: (event: any, values: any) => void
  open: boolean
  showMidSlider: boolean
  timeRange: TimeRange
}) => {
  const ref = React.useRef<HTMLSpanElement>(null)
  const midSlider = React.useRef<HTMLSpanElement>(null)
  const startSlider = React.useRef<HTMLSpanElement>(null)
  const endSlider = React.useRef<HTMLSpanElement>(null)
  const track = React.useRef<HTMLSpanElement>(null)

  const classes = useStyles(props)

  React.useEffect(() => {
    if (ref.current?.children) {
      const children = ref.current.children

      // @ts-ignore
      track.current = children[1]
      // @ts-ignore
      startSlider.current = children[3]
      // @ts-ignore
      midSlider.current = children[4]
      // @ts-ignore
      endSlider.current = children[5]

      if (startSlider.current) {
        startSlider.current.ontouchstart = () => {
          if (startSlider.current) {
            startSlider.current.children[0].classList.add(classes.show)
          }
        }
        startSlider.current.ontouchend = () => {
          if (startSlider.current) {
            startSlider.current.children[0].classList.remove(classes.show)
          }
        }
      }

      if (endSlider.current) {
        endSlider.current.ontouchstart = () => {
          if (endSlider.current) {
            endSlider.current.children[0].classList.add(classes.show)
          }
        }
        endSlider.current.ontouchend = () => {
          if (endSlider.current) {
            endSlider.current.children[0].classList.remove(classes.show)
          }
        }
      }

      if (midSlider.current) {
        midSlider.current.children[0].remove()
        midSlider.current.classList.add(classes.hidden)

        midSlider.current.ontouchstart = () => {
          if (startSlider.current && endSlider.current) {
            startSlider.current.children[0].classList.add(classes.onMidShow)
            endSlider.current.children[0].classList.add(classes.onMidShow)
          }
        }
        midSlider.current.ontouchend = () => {
          if (startSlider.current && endSlider.current) {
            startSlider.current.children[0].classList.remove(classes.onMidShow)
            endSlider.current.children[0].classList.remove(classes.onMidShow)
          }
        }
      }
    }
  }, [classes.onMidShow, classes.hidden, classes.show])

  React.useEffect(() => {
    if (
      startSlider.current &&
      endSlider.current &&
      midSlider.current &&
      track.current
    ) {
      if (props.open) {
        startSlider.current.classList.remove(classes.hidden)
        endSlider.current.classList.remove(classes.hidden)

        if (ref.current) {
          if (ref.current.style.opacity !== '1') {
            ref.current.style.opacity = '1'
          }
        }
      } else {
        startSlider.current.classList.add(classes.hidden)
        endSlider.current.classList.add(classes.hidden)
        midSlider.current.classList.add(classes.hidden)

        track.current.addEventListener(
          'transitionend',
          () => {
            if (track.current && ref.current) {
              // After Slider has initialized
              if (ref.current.style.opacity !== '1') {
                ref.current.style.opacity = '1'
              }
            }
          },
          { once: true }
        )
      }
    }
  }, [classes.hidden, props.open])

  React.useEffect(() => {
    if (midSlider.current) {
      if (props.showMidSlider) {
        midSlider.current.classList.remove(classes.hidden)
      } else {
        midSlider.current.classList.add(classes.hidden)
      }
    }
  }, [classes.hidden, props.showMidSlider])

  React.useEffect(() => {
    // Handles weird behavior when changing index
    if (ref.current && !props.open) {
      if (ref.current.style.opacity !== '0') {
        ref.current.style.opacity = '0'
      }
    }
    // eslint-disable-next-line
  }, [props.min, props.max])

  return (
    <Slider
      style={
        props.open ? undefined : { userSelect: 'none', pointerEvents: 'none' }
      }
      innerRef={ref}
      classes={{
        active: classes.active,
        rail: classes.rail,
        root: classes.root,
        thumb: classes.thumb,
        track: `${classes.trackTransition} ${
          props.open ? classes.trackOpen : classes.trackClosed
        }`,
        valueLabel: classes.valueLabel
      }}
      className={styles.mobileTimeSliderDateSlider}
      orientation={'vertical'}
      min={props.min}
      max={props.max}
      value={[props.timeRange.start, props.timeRange.mid, props.timeRange.end]}
      onChange={props.onChange}
      valueLabelDisplay={'on'}
      valueLabelFormat={(date) => formatDate(date, true)}
    />
  )
}
