import moment from 'moment'
import { screenSize } from 'styles/constants'
export const capitalizeFirstLetter = (word) => {
  if (!word || typeof word !== 'string') {
    return word
  }
  const firstLetter = word[0].toUpperCase()
  return firstLetter + word.substring(1)
}

export const removeSeparatorsAndCapitalize = (word) => {
  return word
    .split(/[_-]/g)
    .map((val) => capitalizeFirstLetter(val))
    .join(' ')
}
export const formatDate = (date, abbreviate = false) => {
  const format = abbreviate ? 'h:mm a, M/D' : 'h:mm a, MMM Do YYYY'
  if (!date) {
    return ''
  }
  return moment(date).format(format)
}

export const getTimeSliderRangeValues = ({ timeRange, start, end }) => {
  let hasCorrectValues = [timeRange.start, timeRange.end, start, end].every(
    (value) => typeof value === 'number'
  )
  const Range = timeRange.start - timeRange.end

  const sliderRange = {
    start: (timeRange.start - start) / Range,
    end: (timeRange.start - end) / Range
  }

  hasCorrectValues =
    hasCorrectValues && sliderRange.start >= 0 && sliderRange.end >= 0

  return hasCorrectValues ? sliderRange : { start: 0, end: 1 }
}

export const getScreenSize = ({
  currentWindowDimensions = {
    height: window.innerHeight,
    width: window.innerWidth
  }
}) => {
  const width = currentWindowDimensions.width

  let currentScreenSize = 'lg'
  if (width > screenSize.xl) {
    currentScreenSize = 'xl'
  } else if (width > screenSize.lg && width < screenSize.xl) {
    currentScreenSize = 'lg'
  } else if (width > screenSize.md && width < screenSize.lg) {
    currentScreenSize = 'md'
  } else if (width > screenSize.sm && width < screenSize.md) {
    currentScreenSize = 'sm'
  } else if (width < screenSize.sm) {
    currentScreenSize = 'xs'
  }

  return currentScreenSize
}
