import React from 'react'
import PropTypes from 'prop-types'
import { mergeStyleSets } from '@uifabric/styling'
import { DefaultButton, Icon } from 'office-ui-fabric-react'
import { AppContext } from 'Context'
import { boxShadow, mediaQuery, transition } from 'styles/constants'

const styles = mergeStyleSets({
  breadcrumbOverlay: {
    alignItems: 'inherit',
    backgroundColor: 'var(--darkOverlay)',
    backdropFilter: 'blur(5px)',
    borderRadius: 16,
    display: 'inherit',
    maxWidth: 'fit-content',
    whiteSpace: 'nowrap',
    boxShadow: boxShadow.elevation16
  },
  breadcrumbContainer: {
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    top: 16,
    color: 'var(--black)',
    width: '100%',
    textAlign: 'center',
    zIndex: 2,
    transition: transition.opacity,
    pointerEvents: 'none',
    [mediaQuery.isXs]: {
      top: 'unset',
      bottom: 16,
      maxWidth: '100vw',
      overflowX: 'auto',
      padding: '8px 16px'
    }
  },
  breadcrumbButton: {
    pointerEvents: 'all',
    backgroundColor: 'transparent',
    border: 'none',
    minWidth: 'fit-content',
    maxWidth: 'fit-content',
    paddingLeft: 4,
    paddingRight: 4,
    textTransform: 'unset'
  }
})

const Button = (props) => (
  <DefaultButton
    data-testid={'breadcrumbButton'}
    onClick={props.onClick}
    className={styles.breadcrumbButton}
    styles={props.styles}
  >
    {props.children}
  </DefaultButton>
)

Button.propTypes = {
  children: PropTypes.string,
  onClick: PropTypes.func
}

const Breadcrumb = React.forwardRef((props, ref) => {
  const { petri, screenSize } = React.useContext(AppContext)
  const hideHeaderFooter = React.useMemo(
    () => petri.breadcrumb.length > 1 || petri.onLastLayer,
    [petri.breadcrumb, petri.onLastLayer]
  )
  const isMobile = screenSize === 'xs'
  const hasResultsLength = typeof props.resultsLength === 'number'
  return (
    <div
      className={styles.breadcrumbContainer}
      ref={ref}
      style={{ opacity: hideHeaderFooter ? 1 : 0 }}
    >
      <div className={styles.breadcrumbOverlay}>
        {props.fieldList.length === 1 && hasResultsLength && (
          <Button>{`Total ${
            isMobile ? '' : `(${props.resultsLength})`
          }`}</Button>
        )}
        {props.breadcrumb.map((el, i) => {
          const value = el.key

          if (i < props.breadcrumb.length - 1) {
            return (
              <React.Fragment key={i}>
                <Button
                  onClick={() => props.onClick(el.node, i)}
                  styles={
                    isMobile
                      ? {
                          label: {
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            maxWidth: `calc(90vw / ${
                              props.breadcrumb.length + 1
                            } - 28px)`
                          }
                        }
                      : undefined
                  }
                >
                  {value}
                </Button>
                <Icon iconName={'ChevronRight'} />
              </React.Fragment>
            )
          }
          return (
            <Button key={i}>
              {`${value}${
                el.total !== props.resultsLength && hasResultsLength
                  ? ` - (${props.resultsLength})`
                  : ''
              }`}
            </Button>
          )
        })}
      </div>
    </div>
  )
})

Breadcrumb.displayName = 'Breadcrumb'

Breadcrumb.defaultProps = {
  breadcrumb: [],
  fieldList: [],
  onClick: () => {},
  totalDocs: 0
}

const { array, func, number } = PropTypes

Breadcrumb.propTypes = {
  breadcrumb: array.isRequired,
  fieldList: array.isRequired,
  onClick: func.isRequired,
  population: PropTypes.array.isRequired,
  totalDocs: number.isRequired,
  resultsLength: PropTypes.number
}

export default Breadcrumb
