import * as THREE from 'three'

import loader from 'components/Petri/lib/recordlayer/loaders/loader.js'
import assets from 'components/Petri/lib/recordlayer/assets.js'

export default {
  load,
  get
}

export const assetStore = {}

const loaded = {}

function load(type) {
  if (typeof loaded[type] === 'undefined') {
    let promises = []
    assets[type].forEach(function (json) {
      promises.push(loadJSON(json))
    })
    return Promise.all(promises)
      .then(function () {
        loaded[type] = true
      })
      .catch(function (err) {
        console.error(
          'Failed to load assets for record layer type',
          type,
          ':',
          err
        )
      })
  } else {
    return Promise.resolve()
  }
}

function get(name) {
  return assetStore[name]
}

// Private:
function loadJSON(json) {
  if (typeof assetStore[json.name] === 'undefined') {
    return loader
      .load(json)
      .then(function (asset) {
        if (json.type === 'object') {
          asset.traverse(function (child) {
            if (child instanceof THREE.Mesh) {
              assetStore[json.name] = child.geometry
            }
          })
        } else {
          assetStore[json.name] = asset
        }
      })
      .catch(function (err) {
        console.warn('Failed to load', json.name, ':', err)
      })
  }
}
