import * as THREE from 'three'
import OBJLoader from './recordlayer/loaders/OBJLoader'

export const buildNewSprite = (texture, s, t, u, v, histValue) => {
  // UV-coordinates (s, t, u, v) are defined as follows:
  // (s, t)        (u, t)
  //   *-------------*
  //   |             |
  //   |             |
  //   *-------------*
  // (s, v)        (u, v)
  // Get the height and width of the texture:
  const width = Math.ceil(1 / texture.repeat.x)
  const height = Math.ceil(1 / texture.repeat.y)
  // Check if UV-coordinates are within image limits
  if (s < 0 || t > width || u < 0 || v > height) {
    console.log('Out of bounds')
    return
  }
  // Determine scale factor:
  const scale = 1 / Math.sqrt((u - s) * (v - t))
  // Custom Sprite since THREE.js's native Sprite doesn't work
  const mat = new THREE.MeshBasicMaterial({
    map: texture,
    depthTest: false,
    depthWrite: false,
    shadowSide: THREE.BackSide
  })
  mat.transparent = true
  const geometry = new THREE.Geometry()
  // Add vertices to the geometry:
  if (typeof histValue !== 'undefined') {
    // For histogram sprites:
    geometry.vertices.push(new THREE.Vector3(-1 / 2, histValue - 1 / 2, 0))
    geometry.vertices.push(new THREE.Vector3(-1 / 2, -1 / 2, 0))
    geometry.vertices.push(new THREE.Vector3(1 / 2, -1 / 2, 0))
    geometry.vertices.push(new THREE.Vector3(1 / 2, histValue - 1 / 2, 0))
  } else {
    // For all other sprites (currently just labels):
    geometry.vertices.push(
      new THREE.Vector3((-scale * (u - s)) / 2, (scale * (v - t)) / 2, 0)
    )
    geometry.vertices.push(
      new THREE.Vector3((-scale * (u - s)) / 2, (-scale * (v - t)) / 2, 0)
    )
    geometry.vertices.push(
      new THREE.Vector3((scale * (u - s)) / 2, (-scale * (v - t)) / 2, 0)
    )
    geometry.vertices.push(
      new THREE.Vector3((scale * (u - s)) / 2, (scale * (v - t)) / 2, 0)
    )
  }
  // Set the geometry faces:
  geometry.faces.push(new THREE.Face3(0, 1, 2))
  geometry.faces.push(new THREE.Face3(2, 3, 0))
  // Set the UV's for the faces to select portion of texture to display:
  geometry.faceVertexUvs[0].push([
    new THREE.Vector2(s, height - t),
    new THREE.Vector2(s, height - v),
    new THREE.Vector2(u, height - v)
  ])
  geometry.faceVertexUvs[0].push([
    new THREE.Vector2(u, height - v),
    new THREE.Vector2(u, height - t),
    new THREE.Vector2(s, height - t)
  ])
  geometry.faces[0].normal.set(0, 0, 1)
  // Create the Sprite mesh:
  return new THREE.Mesh(geometry, mat)
}

// export const formatHoverData = data => {
//   const metadata = []
//   Object.keys(data).forEach(key => {
//     metadata.push({
//       key: key,
//       value: typeof data[key] === 'string' && data[key] === '' ? 'EMPTY_FIELD' : data[key]
//     })
//   })
//   return metadata
// }

export const linearScale = (x) => x

export const loadGeometry = (data) => {
  let geometry

  switch (data.type) {
    case 'PlaneGeometry':
      geometry = new THREE.PlaneGeometry(
        data.width || 1,
        data.height || 1,
        data.widthSegments || 1,
        data.heightSegments || 1
      )
      break
    case 'SphereGeometry':
      geometry = new THREE.SphereGeometry(
        data.radius || 1,
        data.widthSegments || 50,
        data.heightSegments || 50
      )
      break
    default:
      geometry = new THREE.BoxGeometry(
        data.width || 1,
        data.height || 1,
        data.depth || 1,
        data.widthSegments || 1,
        data.heightSegments || 1,
        data.depthSegments || 1
      )
      break
  }

  return new Promise((resolve, reject) => {
    if (typeof geometry !== 'undefined') {
      resolve(geometry)
    } else {
      console.error('Failed to load geometry', data.name)
      reject()
    }
  })
}

export const loadImage = (data) => {
  let image = new Image()
  image.src = data.url
  return new Promise((resolve, reject) => {
    image.onload = () => {
      resolve(image)
    }
    image.onerror = () => {
      reject()
    }
  })
}

export const loadMaterial = (data) => {
  // List of map texture types for THREE.js materials:
  const maps = [
    'map',
    'aoMap',
    'envMap',
    'bumpMap',
    'alphaMap',
    'lightMap',
    'normalMap',
    'emissiveMap',
    'specularMap',
    'metalnessMap',
    'roughnessMap',
    'displacementMap'
  ]

  const materialLoader = new THREE.MaterialLoader()

  // This will be fixed in version 74 of Three.js:
  if (typeof data.uuid === 'undefined') {
    data.uuid = THREE.Math.generateUUID()
  }
  // TODO: Make this validate that the textures are loaded? Some three.js warning...
  // Creates a placeholder string property for us to reference textures to be used later on by texture name:
  let textures = {}
  maps.forEach((map) => {
    if (data[map]) {
      textures[data[map]] = data[map]
    }
  })
  materialLoader.setTextures(textures)

  const material = materialLoader.parse(data)

  return new Promise((resolve, reject) => {
    if (typeof material !== 'undefined') {
      resolve(material)
    } else {
      console.error('Failed to load material', data.name)
      reject()
    }
  })
}

export const loadObject = (data) => {
  const objectLoader = new OBJLoader()

  return new Promise((resolve, reject) => {
    objectLoader.load(
      data.url,
      resolve,
      (xhr) => {
        if (xhr.lengthComputable) {
          // const percentComplete = (xhr.loaded / xhr.total) * 100
        }
      },
      reject
    )
  })
}

export const loadTexture = (data) => {
  const textureLoader = new THREE.TextureLoader()

  return new Promise((resolve, reject) => {
    textureLoader.load(
      data.url,
      (texture) => {
        // Attach a texture mapping if defined:
        if (typeof data.mapping !== 'undefined') {
          texture.mapping = THREE[data.mapping]
        }
        resolve(texture)
      },
      (xhr) => {
        if (xhr.lengthComputable) {
          const percentComplete = (xhr.loaded / xhr.total) * 100
          console.log(`${Math.round(percentComplete, 2)}% downloaded`)
        }
      },
      reject
    )
  })
}

export const log2Scale = (x) => Math.log2(x + 1) // offset of 1 to map 0 to 0
const squareRoot = (x, scaleValue = 1) => {
  if (x === 0) {
    // So population bubbles don't show
    scaleValue = 0
  }
  return Math.cbrt(x + scaleValue)
}

export const scaleFunctions = [linearScale, squareRoot]
// export const scaleFunctions = [linearScale, log2Scale]

// Scale petri/population data based on scaleFunction:
export const scaleData = (data, scaleFunction) => {
  const scaleDataArr = []
  const total = data.reduce((acc, el) => acc + scaleFunction(el.value), 0)
  data.forEach((node) => {
    const scale = scaleFunction(node.value) / total
    scaleDataArr.push({ ...node, scale })
  })
  return { scaledData: scaleDataArr, total }
}

// Takes petri/population data and calculates new values based on slider bounds from nested range buckets:
export const transformSliderData = (petriData, range, scaleFunction) => {
  // If fully open or closed return the original values:
  if (range.start === range.end || range.end - range.start === 1) {
    return scaleData(petriData, scaleFunction)
    // Otherwise calculate new values from bucket values between the slider bounds:
  } else {
    // Convert range to nearest integer for array index lookup:
    // const start = Math.floor(range.start * 100)
    // const end = Math.ceil(range.end * 100)
    const start = Math.floor(range.start * 125)
    const end = Math.ceil(range.end * 125)
    // Array for transformed data (will be same shape as petriData):
    const data = []
    // Number for tabulating current total within the slider range:
    let total = 0
    // Loop through each node, update to values between slider range, push to new array:
    petriData.forEach((node) => {
      // Calculate value between start and end indices:
      const value = node.range.reduce(
        (acc, bucket, ind) =>
          ind > start && ind < end ? acc + bucket.doc_count : acc,
        0
      )
      // Push new node data, replacing value with with value between the range:
      data.push({ ...node, value })
      // Add this value to the current total:
      total += scaleFunction(value)
    })
    // Adjust node fraction for each node data based on current total:
    data.forEach(
      (node) =>
        (node.scale = total !== 0 ? scaleFunction(node.value) / total : 0)
    )
    return { scaledData: data, total }
  }
}

export const transformHexLayerSliderData = ({
  population,
  range,
  timeRange,
  start,
  end
}) => {
  let records = []
  // Grab the date field from timerange prop:
  let dateField = timeRange.field
  // If closed/fully-open, return original hits list, otherwise filter based on slider range:
  if (range.start === range.end || range.end - range.start === 1) {
    records = population
  } else {
    let i = 0
    // For loop appears to be more performant
    for (i; i < population.length; i++) {
      const rec = population[i]
      const recordField = rec.data[dateField]
      if (typeof recordField === 'object') {
        const recDateTime = {
          start: recordField.gte,
          end: recordField.lte
        }
        if (recDateTime.start >= start && recDateTime.end <= end) {
          records.push(rec)
        }
      } else {
        const recDateTime = recordField
        if (recDateTime >= start && recDateTime <= end) {
          records.push(rec)
        }
      }
    }
    // records = population.filter((rec) => {
    //   const recordField = rec.data[dateField]
    //   if (typeof recordField === 'object') {
    //     const recDateTime = {
    //       start: recordField.gte,
    //       end: recordField.lte
    //     }
    //     return recDateTime.start >= start && recDateTime.end <= end
    //   } else {
    //     const recDateTime = recordField
    //     return recDateTime >= start && recDateTime <= end
    //   }
    // })
  }
  return records
}

// Takes time-slider data and calculates total count for each time interval out of 100 intervals:
export const transformHistogramData = (petriData) => {
  const data = []
  let hasHistogramData = false

  if (!petriData.length) {
    return data
  }
  for (let i = 0; i < 125; i++) {
    const val = petriData.reduce((acc, node) => {
      const docCount = acc + node.range[i]?.doc_count
      if (!isNaN(docCount)) {
        return docCount
      }
      return 0
    }, 0)
    if (!isNaN(val)) {
      data[i] = val
      if (val !== 0 && !hasHistogramData) {
        hasHistogramData = true
      }
    }
  }
  return hasHistogramData ? data : []
}

// Recursively go up DOM tree to determine if element is child of an element with given className:
export const isChildOfClass = (element, className) => {
  if (element === null) {
    return false
  }
  if (element.className === className) {
    return true
  }
  if (typeof element.parentNode !== 'undefined') {
    return isChildOfClass(element.parentNode, className)
  }
}

export const debounce = (func, delay) => {
  let timer = null
  return function () {
    const that = this
    const args = arguments
    clearTimeout(timer)
    timer = setTimeout(() => {
      func.apply(that, args)
    }, delay)
  }
}

export const getTimeRangeMarkerPosition = ({
  element,
  posX,
  posY,
  timeSlider,
  percentRange
}) => {
  const elWidth = element.clientWidth
  const elHeight = element.clientHeight
  const halfMarkerWidth = elWidth / 2

  const margin = 32
  posX = Math.round(posX)
  posY = Math.round(posY)

  let x = posX
  let y = posY
  let translate = { x: 0, y: 0 }

  if (timeSlider === 'left') {
    if (percentRange.start < 0.45 && percentRange.start > 0.01) {
      x = x + margin
    }
    if (percentRange.start < 0.01) {
      translate.x = -(elWidth + margin)
      translate.y = -elHeight
    } else if (percentRange.start < 0.25) {
      translate.y = -elHeight
    } else if (percentRange.start < 0.55 && percentRange.start > 0.45) {
      translate.y = elHeight
      translate.x = -halfMarkerWidth
    } else if (percentRange.start > 0.55) {
      translate.x = -(elWidth + margin * 2)
      if (percentRange.start > 0.75) {
        translate.y = -elHeight
      }
    }
  }

  if (timeSlider === 'right') {
    if (percentRange.end < 0.45) {
      translate.x = margin
    }

    if (percentRange.end < 0.25) {
      translate.y = -elHeight
    } else if (percentRange.end < 0.55 && percentRange.end > 0.45) {
      translate.y = elHeight
      translate.x = -(halfMarkerWidth + margin)
    } else if (percentRange.end > 0.55) {
      translate.x = -(elWidth + margin * 2)
      if (percentRange.end > 0.75) {
        translate.y = -elHeight
      }
    }
  }

  return { x, y, translate }
}
