import { loadTheme, getTheme } from '@uifabric/styling'
import { initializeIcons } from '@uifabric/icons'
import { transition } from './constants'

export const palette = {
  themePrimary: 'var(--themePrimary)',
  themeLighterAlt: 'var(--themeLighterAlt)',
  themeLighter: 'var(--themeLighter)',
  themeLight: 'var(--themeLight)',
  themeTertiary: 'var(--themeTertiary)',
  themeSecondary: 'var(--themeSecondary)',
  themeDarkAlt: 'var(--themeDarkAlt)',
  themeDark: 'var(--themeDark)',
  themeDarker: 'var(--themeDarker)',
  neutralLighterAlt: 'var(--neutralLighterAlt)',
  neutralLighter: 'var(--neutralLighter)',
  neutralLight: 'var(--neutralLight)',
  neutralQuaternaryAlt: 'var(--neutralQuaternaryAlt)',
  neutralQuaternary: 'var(--neutralQuaternary)',
  neutralTertiaryAlt: 'var(--neutralTertiaryAlt)',
  neutralTertiary: 'var(--neutralTertiary)',
  neutralSecondary: 'var(--neutralSecondary)',
  neutralPrimaryAlt: 'var(--neutralPrimaryAlt)',
  neutralPrimary: 'var(--neutralPrimary)',
  neutralDark: 'var(--neutralDark)',
  white: 'var(--black)',
  black: 'var(--white)',
  bodyBackground: 'var(--bodyBackground)',
  bodyText: 'var(--defaultText)',
  red: 'var(--red)',
  yellow: 'var(--yellow)',
  darkRed: 'var(--darkRed)',
  purple: 'var(--purple)',
  green: 'var(--green)',
  blue: 'var(--blue)',
  orange: 'var(--orange)',
  grey: 'var(--grey)',
  border: 'var(--border)',
  hover: 'var(--hover)'
}

const { fonts } = getTheme()
export const HEIGHT = 28
const OVERLAY = 'var(--overlay)'
const SURFACE_ELEMENTS = 'var(--surfaceElements)'

const defaultFontStyle = {
  fontFamily: 'Roboto'
}

const fontStyles = {
  small: {
    fontWeight: '300'
  },
  medium: {
    fontWeight: '400'
  },
  large: {
    fontWeight: '500'
  },
  xLarge: {
    fontWeight: '700'
  }
}

const ActionButtonStyles = {
  root: {
    transition: transition.backgroundColor,
    minHeight: HEIGHT,
    height: HEIGHT,
    lineHeight: HEIGHT - 2
  },

  flexContainer: {
    width: 'inherit'
  },
  icon: {
    fontSize: fonts.medium.fontSize
  }
}

const CalloutStyles = {
  container: {},
  beak: {
    backgroundColor: SURFACE_ELEMENTS
  },
  calloutMain: {
    borderColor: 'transparent',
    backgroundColor: SURFACE_ELEMENTS,
    padding: '2px 0px'
  }
}

const CommandBarButtonStyles = {
  root: {
    transition: transition.backgroundColor,
    color: 'white',
    minHeight: HEIGHT,
    height: HEIGHT,
    lineHeight: HEIGHT - 2,
    textTransform: 'uppercase'
  },
  rootHovered: {
    color: 'white'
  },
  label: {
    color: 'white'
  },
  flexContainer: {
    width: 'inherit'
  },
  icon: {
    color: 'white',
    fontSize: fonts.medium.fontSize
  }
}

const ComboBoxStyles = {
  root: {}
}

const ContextualMenuStyles = {
  root: {
    backgroundColor: SURFACE_ELEMENTS,
    padding: '4px 0px'
  },
  subComponentStyles: {
    menuItem: {
      root: {
        fontSize: fonts.medium.fontSize,
        lineHeight: HEIGHT - 2,
        height: HEIGHT,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }
    }
  }
}

const DefaultButtonStyles = {
  root: {
    transition: transition.borderColor,
    backgroundColor: 'transparent',
    height: HEIGHT,
    minHeight: HEIGHT,
    lineHeight: HEIGHT - 2,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: palette.themePrimary
    }
  },
  flexContainer: {
    width: 'inherit'
  },
  label: {
    fontWeight: fontStyles.large.fontWeight
    // color: 'white'
    // selectors: {
    //   '&:hover': {
    //     color: 'white'
    //   }
    // }
  },
  icon: {
    color: 'white',
    fontSize: fonts.medium.fontSize
  }
}

const CompoundButtonStyles = {
  root: {
    transition: transition.borderColor,
    backgroundColor: 'transparent',
    height: 'fit-content',
    minHeight: HEIGHT,
    lineHeight: HEIGHT - 2,
    textTransform: 'uppercase',
    '&:hover': {
      backgroundColor: 'transparent',
      borderColor: palette.themePrimary
    }
  },
  flexContainer: {
    width: 'inherit'
  },
  label: {
    fontWeight: fontStyles.large.fontWeight
  },
  icon: {
    color: 'white',
    fontSize: fonts.medium.fontSize
  }
}

const DropdownStyles = {
  dropdown: {
    height: HEIGHT
  },

  dropdownItemSelected: {
    height: HEIGHT,
    minHeight: HEIGHT
  },
  dropdownItem: {
    height: HEIGHT,
    minHeight: HEIGHT
  },
  dropdownOptionText: {
    height: HEIGHT,
    lineHeight: HEIGHT - 2
  },

  title: {
    height: HEIGHT,
    lineHeight: HEIGHT - 2,
    display: 'flex',
    alignItems: 'center',
    background: 'var(--surfaceElements)',
    color: 'var(--white)'
  },
  caretDownWrapper: {
    height: HEIGHT,
    lineHeight: HEIGHT - 2
  },
  label: {
    fontWeight: fontStyles.large.fontWeight
  }
}

const IconStyles = {
  root: {
    color: 'white'
  }
}

const IconButtonStyles = {
  rootChecked: {
    color: 'white'
  },
  iconChecked: {
    color: 'white'
  },
  root: {
    transition: transition.backgroundColor,
    height: HEIGHT,
    width: HEIGHT,
    color: 'white'
  },
  flexContainer: {
    maxWidth: '100%'
  }
}

const ModalStyles = {
  root: {
    backgroundColor: OVERLAY
  },
  main: {
    backgroundColor: SURFACE_ELEMENTS,
    minHeight: 'unset'
  }
}

const PrimaryButtonStyles = {
  root: {
    transition: transition.backgroundColor,
    minHeight: HEIGHT,
    height: HEIGHT,
    lineHeight: HEIGHT - 2,
    textTransform: 'uppercase'
  },

  flexContainer: {
    width: 'inherit'
  }
}

const PanelStyles = {
  main: {
    background: 'var(--surfaceElements)'
  },
  overlay: {
    backgroundColor: OVERLAY
  }
}

const PivotStyles = {
  root: {
    transition: transition.backgroundColor
  },
  itemContainer: {},
  link: {
    height: HEIGHT,
    transition: 'inherit'
  },
  linkIsSelected: {
    height: HEIGHT,
    transition: 'inherit'
  },
  text: {
    height: HEIGHT
  }
}

const SearchBoxStyles = {
  root: {
    height: HEIGHT
  }
}

const SpinButtonStyles = {}

const TextFieldStyles = {
  field: {
    height: HEIGHT
  },
  fieldGroup: {
    height: HEIGHT + 2,
    transition: transition.borderColor
  }
}

const TagItemStyles = {
  root: {
    height: HEIGHT - 6,
    lineHeight: HEIGHT - 8
  },
  close: {}
}

const TagPickerStyles = {
  root: {},
  input: {
    flexGrow: 1,
    maxWidth: '18%',
    height: HEIGHT,
    alignSelf: 'center',
    minHeight: HEIGHT,
    lineHeight: HEIGHT - 2
  },
  text: {
    minHeight: HEIGHT,
    height: HEIGHT
  }
}

const TextStyles = {
  root: {
    color: 'var(--white)'
  }
}

const ToggleStyles = {
  thumb: {
    backgroundColor: 'var(--white)'
  }
}

export const customizations = {
  scopedSettings: {
    ActionButton: {
      styles: ActionButtonStyles
    },
    CalloutContent: {
      styles: CalloutStyles
    },
    CommandBarButton: {
      styles: CommandBarButtonStyles
    },
    CompoundButton: {
      styles: CompoundButtonStyles
    },
    ContextualMenu: {
      styles: ContextualMenuStyles
    },
    ComboBox: {
      styles: ComboBoxStyles
    },
    DefaultButton: {
      styles: DefaultButtonStyles
    },
    Dropdown: {
      styles: DropdownStyles
    },
    Icon: {
      styles: IconStyles
    },
    IconButton: {
      styles: IconButtonStyles
    },
    Modal: {
      styles: ModalStyles
    },
    Panel: {
      styles: PanelStyles
    },
    Pivot: {
      styles: PivotStyles
    },
    PrimaryButton: {
      styles: PrimaryButtonStyles
    },
    SearchBox: {
      styles: SearchBoxStyles
    },
    SpinButton: {
      styles: SpinButtonStyles
    },
    TagItem: {
      styles: TagItemStyles
    },
    TagPicker: {
      styles: TagPickerStyles
    },
    Text: {
      styles: TextStyles
    },
    TextField: {
      styles: TextFieldStyles
    },
    Toggle: {
      styles: ToggleStyles
    }
  }
}

const semanticColors = {
  bodyStandoutBackground: SURFACE_ELEMENTS,
  inputPlaceholderText: 'var(--grey)',
  severeWarningBackground: '#f44336'
}

loadTheme({
  defaultFontStyle,
  fonts: fontStyles,
  palette,
  semanticColors,
  disableGlobalClassNames: false
})

initializeIcons()
