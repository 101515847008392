import React from 'react'
import Tutorial from 'components/Tutorial/Tutorial'
import { AppContext } from 'Context'

const TutorialContainer = () => {
  const { actions, tutorial, screenSize } = React.useContext(AppContext)
  const isMobile = React.useMemo(() => screenSize === 'xs', [screenSize])
  const _tutorial = React.useMemo(() => {
    return {
      ...tutorial,
      steps: tutorial.steps.map((step) => ({
        ...step,
        align:
          typeof step.align === 'function' ? step.align(isMobile) : step.align,
        hint: typeof step.hint === 'function' ? step.hint(isMobile) : step.hint
      }))
    }
  }, [isMobile, tutorial])

  return (
    <Tutorial
      {..._tutorial}
      isMobile={isMobile}
      onStart={() => actions.updateConfiguration('usElection2020')}
      finishText={'Nice Work!'}
    />
  )
}

export default TutorialContainer
