import { getTheme } from '@uifabric/styling'

const {
  effects: { elevation4, elevation8, elevation16, elevation64 }
} = getTheme()

export const easing = {
  easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
  easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
  easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
  sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
}

export const border = {
  bottom: '1px solid var(--neutralLight)'
}

export const boxShadow = {
  elevation4,
  elevation8,
  elevation16,
  elevation64
}

export const duration = {
  shortest: 150,
  shorter: 200,
  short: 250,
  standard: 300,
  complex: 375,
  enteringScreen: 225,
  leavingScreen: 195
}

export const transition = {
  backgroundColor: `background-color ${duration.standard}ms ${easing.easeInOut}`,
  boxShadow: `box-shadow ${duration.standard}ms ${easing.easeInOut}`,
  borderColor: `border-color ${duration.shorter}ms ${easing.easeOut}`,
  color: `color ${duration.standard}ms ${easing.easeInOut}`,
  opacity: `opacity ${duration.enteringScreen}ms ${easing.easeIn}`,
  filterSaturate: `filter-saturate ${duration.enteringScreen}ms ${easing.easeIn}`,
  filterBrightness: `filter-brightness ${duration.enteringScreen}ms ${easing.easeIn}`,
  opacityShort: `opacity ${duration.leavingScreen}ms ${easing.sharp}`,
  opacityShortest: `opacity 50ms ${easing.sharp}`,
  transform: `transform ${duration.enteringScreen}ms ${easing.easeInOut}`,
  transformShort: `transform ${duration.shortest}ms ${easing.easeInOut}`,
  transformShortest: `transform 50ms ${easing.sharp}`,
  transformInstant: `transform 0ms ${easing.sharp}`,
  height: `height ${duration.leavingScreen}ms ${easing.easeOut}`,
  width: `width ${duration.enteringScreen}ms ${easing.sharp}`,
  margin: `margin ${duration.leavingScreen}ms ${easing.easeOut}`,
  padding: `padding ${duration.enteringScreen}ms ${easing.easeOut}`,
  top: `top ${duration.shortest}ms ${easing.easeOut}`,
  clipPath: `clip-path ${duration.shortest}ms ${easing.easeOut}`,
  all: `all ${duration.shortest}ms ${easing.easeOut}`
}

export const screenSize = {
  xs: 0,
  sm: 600,
  md: 960,
  lg: 1280,
  xl: 1920
}
export const mediaQuery = {
  isXs: `@media screen and (max-width: ${screenSize.sm}px) and (min-width: ${screenSize.xs}px)`,
  isSm: `@media screen and (max-width: ${screenSize.md}px) and (min-width: ${screenSize.sm}px)`,
  isMd: `@media screen and (max-width: ${screenSize.sm}px) and (min-width: ${screenSize.md}px)`,
  isLg: `@media screen and (max-width: ${screenSize.lg}px) and (min-width: ${screenSize.md}px)`,
  isXl: `@media screen and (max-width: ${screenSize.xl}px) and (min-width: ${screenSize.lg}px)`
}
