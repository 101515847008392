import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls'
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass'
import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer'
import { FXAAShader } from 'three/examples/jsm/shaders/FXAAShader'
import { ShaderPass } from 'three/examples/jsm/postprocessing/ShaderPass'
import { CopyShader } from 'three/examples/jsm/shaders/CopyShader'
import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass'
import * as THREE from 'three/build/three.module'

const controls = {
  OrbitControls
}

const postProcessing = {
  CopyShader,
  FXAAShader,
  EffectComposer,
  RenderPass,
  ShaderPass,
  UnrealBloomPass
}

let Three = {
  ...THREE,
  ...controls,
  ...postProcessing
}

export default Three
