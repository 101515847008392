import * as THREE from 'three'

/**
 * @author qiao / https://github.com/qiao
 * @author mrdoob / http://mrdoob.com
 * @author alteredq / http://alteredqualia.com/
 * @author WestLangley / http://github.com/WestLangley
 * @author erich666 / http://erichaines.com
 */

// Modified by Zoic Labs

// This set of controls performs dollying (zooming) and panning.
//
//    Zoom - mousewheel / touch: two finger spread or squish
//    Pan - middle mouse, or arrow keys / touch: three finger swipe

const ZoomPanControls = function (object, domElement) {
  this.object = object

  this.domElement = domElement !== undefined ? domElement : document

  // Set to false to disable this control
  this.enabled = true

  // "target" sets the location of focus, where the object orbits around
  this.target = new THREE.Vector3()

  // How far you can dolly in and out ( PerspectiveCamera only )
  this.minDistance = 0
  this.maxDistance = Infinity

  // How far you can zoom in and out ( OrthographicCamera only )
  this.minZoom = 0
  this.maxZoom = Infinity

  // Set to true to enable damping (inertia)
  // If damping is enabled, you must call controls.update() in your animation loop
  this.enableDamping = false
  this.dampingFactor = 0.25

  // This option actually enables dollying in and out left as "zoom" for backwards compatibility.
  // Set to false to disable zooming
  this.enableZoom = true
  this.zoomSpeed = 1.0

  // Set to false to disable panning
  this.enablePan = true
  this.keyPanSpeed = 7.0 // pixels moved per arrow key push

  // Set to false to disable use of the keys
  this.enableKeys = true

  // The four arrow keys
  this.keys = { LEFT: 37, UP: 38, RIGHT: 39, BOTTOM: 40 }

  // Mouse buttons
  // this.mouseButtons = { ORBIT: THREE.MOUSE.LEFT, ZOOM: THREE.MOUSE.MIDDLE, PAN: THREE.MOUSE.RIGHT }
  this.mouseButtons = { PAN: THREE.MOUSE.MIDDLE }

  // for reset
  this.target0 = this.target.clone()
  this.position0 = this.object.position.clone()
  this.zoom0 = this.object.zoom
  this.quaternion0 = this.object.quaternion.clone()

  const scope = this

  const changeEvent = { type: 'change' }
  const startEvent = { type: 'start' }
  const endEvent = { type: 'end' }

  const STATE = {
    NONE: -1,
    ROTATE: 0,
    DOLLY: 1,
    PAN: 2,
    TOUCH_ROTATE: 3,
    TOUCH_DOLLY: 4,
    TOUCH_PAN: 5
  }

  let state = STATE.NONE

  const EPS = 0.000001

  // current position in spherical coordinates
  // const spherical = new THREE.Spherical()
  // const sphericalDelta = new THREE.Spherical()

  // let scale = 1
  const panOffset = new THREE.Vector3()
  let zoomChanged = false

  // const rotateStart = new THREE.Vector2()
  // const rotateEnd = new THREE.Vector2()
  // const rotateDelta = new THREE.Vector2()

  const panStart = new THREE.Vector2()
  const panEnd = new THREE.Vector2()
  const panDelta = new THREE.Vector2()

  const dollyStart = new THREE.Vector2()
  const dollyEnd = new THREE.Vector2()
  const dollyDelta = new THREE.Vector2()

  // This function is tightly coupled with Population/Petri (first if conditional):
  this.recalibrate = (parentNode) => {
    if (parentNode.name === 'root') {
      scope.target = new THREE.Vector3()
    } else if (parentNode?.scaleContainer?.matrixWorld) {
      scope.target = new THREE.Vector3()
        .setFromMatrixPosition(parentNode.scaleContainer.matrixWorld)
        .setZ(0)
    }

    scope.target0 = scope.target.clone()
    scope.position0 = scope.object.position.clone()
    scope.zoom0 = scope.object.zoom
    scope.quaternion0 = scope.object.quaternion.clone()

    // scope.update()
  }

  this.reset = () => {
    scope.target.copy(scope.target0)
    scope.object.position.copy(scope.position0)
    scope.object.zoom = scope.zoom0
    scope.object.quaternion.copy(scope.quaternion0)

    scope.object.updateProjectionMatrix()
    scope.dispatchEvent(changeEvent)

    // scope.update()

    state = STATE.NONE
  }

  // this method is exposed, but perhaps it would be better if we can make it private...
  this.update = (() => {
    // const offset = new THREE.Vector3()

    // so camera.up is the orbit axis
    // const quat = new THREE.Quaternion().setFromUnitVectors(object.up, new THREE.Vector3(0, 1, 0))
    // const quatInverse = quat.clone().inverse()

    const lastPosition = new THREE.Vector3()
    const lastQuaternion = new THREE.Quaternion()

    return () => {
      const position = scope.object.position

      // move target to panned location
      scope.target.add(panOffset)

      position.copy(scope.target)

      // scale = 1
      panOffset.set(0, 0, 0)

      if (
        zoomChanged ||
        lastPosition.distanceToSquared(scope.object.position) > EPS ||
        8 * (1 - lastQuaternion.dot(scope.object.quaternion)) > EPS
      ) {
        scope.dispatchEvent(changeEvent)

        lastPosition.copy(scope.object.position)
        lastQuaternion.copy(scope.object.quaternion)
        zoomChanged = false

        return true
      }

      return false
    }
  })()

  this.dispose = () => {
    scope.domElement.removeEventListener('contextmenu', onContextMenu, false)
    scope.domElement.removeEventListener('mousedown', onMouseDown, false)
    scope.domElement.removeEventListener('wheel', onMouseWheel, false)

    scope.domElement.removeEventListener('touchstart', onTouchStart, false)
    scope.domElement.removeEventListener('touchend', onTouchEnd, false)
    scope.domElement.removeEventListener('touchmove', onTouchMove, false)

    document.removeEventListener('mousemove', onMouseMove, false)
    document.removeEventListener('mouseup', onMouseUp, false)

    // window.removeEventListener( 'keydown', onKeyDown, false )
    scope.domElement.removeEventListener('mouseup', onElementMouseUp, false)
    scope.domElement.removeEventListener('keydown', onKeyDown, false)

    //scope.dispatchEvent( { type: 'dispose' } ) // should this be added here?
  }

  const getZoomScale = () => Math.pow(0.95, scope.zoomSpeed)

  const panLeft = (() => {
    const v = new THREE.Vector3()

    return (distance, objectMatrix) => {
      v.setFromMatrixColumn(objectMatrix, 0) // get X column of objectMatrix
      v.multiplyScalar(-distance)

      panOffset.add(v)
    }
  })()

  const panUp = (() => {
    const v = new THREE.Vector3()
    return (distance, objectMatrix) => {
      v.setFromMatrixColumn(objectMatrix, 1) // get Y column of objectMatrix
      v.multiplyScalar(distance)

      panOffset.add(v)
    }
  })()

  const pan = (() => {
    const offset = new THREE.Vector3()

    return (deltaX, deltaY) => {
      const element =
        scope.domElement === document ? scope.domElement.body : scope.domElement

      if (scope.object instanceof THREE.PerspectiveCamera) {
        // perspective
        const position = scope.object.position
        offset.copy(position).sub(scope.target)
        let targetDistance = offset.length()

        // half of the fov is center to top of screen
        targetDistance *= Math.tan(((scope.object.fov / 2) * Math.PI) / 180.0)

        // we actually don't use screenWidth, since perspective camera is fixed to screen height
        panLeft(
          (2 * deltaX * targetDistance) / element.clientHeight,
          scope.object.matrix
        )
        panUp(
          (2 * deltaY * targetDistance) / element.clientHeight,
          scope.object.matrix
        )
      } else if (scope.object instanceof THREE.OrthographicCamera) {
        // orthographic
        panLeft(
          (deltaX * (scope.object.right - scope.object.left)) /
          scope.object.zoom /
          element.clientWidth,
          scope.object.matrix
        )
        panUp(
          (deltaY * (scope.object.top - scope.object.bottom)) /
          scope.object.zoom /
          element.clientHeight,
          scope.object.matrix
        )
      } else {
        // camera neither orthographic nor perspective
        console.warn(
          'WARNING: OrbitControls.js encountered an unknown camera type - pan disabled.'
        )
        scope.enablePan = false
      }
    }
  })()

  const dollyIn = (dollyScale) => {
    if (scope.object instanceof THREE.PerspectiveCamera) {
      // scale /= dollyScale
    } else if (scope.object instanceof THREE.OrthographicCamera) {
      scope.object.zoom = Math.max(
        scope.minZoom,
        Math.min(scope.maxZoom, scope.object.zoom * dollyScale)
      )
      scope.object.updateProjectionMatrix()
      zoomChanged = true
    } else {
      console.warn(
        'WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.'
      )
      scope.enableZoom = false
    }
  }

  const dollyOut = (dollyScale) => {
    if (scope.object instanceof THREE.PerspectiveCamera) {
      // scale *= dollyScale
    } else if (scope.object instanceof THREE.OrthographicCamera) {
      scope.object.zoom = Math.max(
        scope.minZoom,
        Math.min(scope.maxZoom, scope.object.zoom / dollyScale)
      )
      scope.object.updateProjectionMatrix()
      zoomChanged = true
    } else {
      console.warn(
        'WARNING: OrbitControls.js encountered an unknown camera type - dolly/zoom disabled.'
      )
      scope.enableZoom = false
    }
  }

  const handleMouseDownDolly = (event) => {
    dollyStart.set(event.clientX, event.clientY)
  }

  const handleMouseDownPan = (event) => {
    panStart.set(event.clientX, event.clientY)
  }

  const handleMouseMoveDolly = (event) => {
    dollyEnd.set(event.clientX, event.clientY)

    dollyDelta.subVectors(dollyEnd, dollyStart)

    if (dollyDelta.y > 0) {
      dollyIn(getZoomScale())
    } else if (dollyDelta.y < 0) {
      dollyOut(getZoomScale())
    }

    dollyStart.copy(dollyEnd)

    scope.update()
  }

  const handleMouseMovePan = (event) => {
    panEnd.set(event.clientX, event.clientY)

    panDelta.subVectors(panEnd, panStart)

    pan(panDelta.x, panDelta.y)

    panStart.copy(panEnd)

    scope.update()
  }

  const handleMouseUp = () => {
    // console.log( 'handleMouseUp' )
  }

  const handleMouseWheel = (event) => {
    // console.log( 'handleMouseWheel' )

    if (event.deltaY < 0) {
      dollyOut(getZoomScale())
    } else if (event.deltaY > 0) {
      dollyIn(getZoomScale())
    }

    // scope.update()
  }

  const handleKeyDown = (event) => {
    switch (event.keyCode) {
      case scope.keys.UP:
        pan(0, -scope.keyPanSpeed)
        scope.update()
        break

      case scope.keys.BOTTOM:
        pan(0, scope.keyPanSpeed)
        scope.update()
        break

      case scope.keys.LEFT:
        pan(-scope.keyPanSpeed, 0)
        scope.update()
        break

      case scope.keys.RIGHT:
        pan(scope.keyPanSpeed, 0)
        scope.update()
        break

      default:
    }
  }

  const getDollyDistance = (event) => {
    const dx = event.touches[0].pageX - event.touches[1].pageX
    const dy = event.touches[0].pageY - event.touches[1].pageY

    return Math.sqrt(dx * dx + dy * dy)
  }

  const handleTouchStartDolly = (event) => {
    dollyStart.set(0, getDollyDistance(event))
  }

  const handleTouchMoveDolly = (event) => {
    dollyEnd.set(0, getDollyDistance(event))

    dollyDelta.subVectors(dollyEnd, dollyStart)

    if (dollyDelta.y > 0) {
      dollyOut(getZoomScale())
    } else if (dollyDelta.y < 0) {
      dollyIn(getZoomScale())
    }

    dollyStart.copy(dollyEnd)

    scope.update()
  }

  const handleTouchStartPan = (event) => {
    panStart.set(event.touches[0].pageX, event.touches[0].pageY)
  }

  const handleTouchMovePan = (event) => {
    panEnd.set(event.touches[0].pageX, event.touches[0].pageY)

    panDelta.subVectors(panEnd, panStart)

    pan(panDelta.x, panDelta.y)

    panStart.copy(panEnd)

    scope.update()
  }

  const handleTouchEnd = () => {
    //console.log( 'handleTouchEnd' )
  }

  const onMouseDown = (event) => {
    if (scope.enabled === false) {
      return
    }

    event.preventDefault()

    if (event.button === scope.mouseButtons.ZOOM) {
      if (scope.enableZoom === false) {
        return
      }

      handleMouseDownDolly(event)

      state = STATE.DOLLY
    } else if (event.button === scope.mouseButtons.PAN) {
      if (scope.enablePan === false) {
        return
      }

      handleMouseDownPan(event)

      state = STATE.PAN
    }

    if (state !== STATE.NONE) {
      document.addEventListener('mousemove', onMouseMove, false)
      document.addEventListener('mouseup', onMouseUp, false)

      scope.dispatchEvent(startEvent)
    }
  }

  const onElementMouseUp = () => {
    if (scope.enabled === false) {
      return
    }

    scope.domElement.focus()
  }

  const onMouseMove = (event) => {
    if (scope.enabled === false) {
      return
    }

    event.preventDefault()

    if (state === STATE.DOLLY) {
      if (scope.enableZoom === false) {
        return
      }

      handleMouseMoveDolly(event)
    } else if (state === STATE.PAN) {
      if (scope.enablePan === false) {
        return
      }

      handleMouseMovePan(event)
    }
  }

  const onMouseUp = (event) => {
    if (scope.enabled === false) {
      return
    }

    handleMouseUp(event)

    document.removeEventListener('mousemove', onMouseMove, false)
    document.removeEventListener('mouseup', onMouseUp, false)

    scope.dispatchEvent(endEvent)

    state = STATE.NONE
  }

  const onMouseWheel = (event) => {
    if (
      scope.enabled === false ||
      scope.enableZoom === false ||
      (state !== STATE.NONE && state !== STATE.ROTATE)
    ) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    handleMouseWheel(event)

    scope.dispatchEvent(startEvent) // not sure why these are here...
    scope.dispatchEvent(endEvent)
  }

  const onKeyDown = (event) => {
    if (
      scope.enabled === false ||
      scope.enableKeys === false ||
      scope.enablePan === false
    ) {
      return
    }

    handleKeyDown(event)
  }

  const onTouchStart = (event) => {
    if (scope.enabled === false) {
      return
    }

    switch (event.touches.length) {
      case 1: // one-fingered touch: rotate
        if (scope.enableRotate === false) {
          return
        }

        // handleTouchStartRotate(event)

        state = STATE.TOUCH_ROTATE

        break

      case 2: // two-fingered touch: dolly
        if (scope.enableZoom === false) {
          return
        }

        handleTouchStartDolly(event)

        state = STATE.TOUCH_DOLLY

        break

      case 3: // three-fingered touch: pan
        if (scope.enablePan === false) {
          return
        }

        handleTouchStartPan(event)

        state = STATE.TOUCH_PAN

        break

      default:
        state = STATE.NONE
    }

    if (state !== STATE.NONE) {
      scope.dispatchEvent(startEvent)
    }
  }

  const onTouchMove = (event) => {
    if (scope.enabled === false) {
      return
    }

    event.preventDefault()
    event.stopPropagation()

    switch (event.touches.length) {
      case 1: // one-fingered touch: rotate
        if (scope.enableRotate === false) {
          return
        }
        if (state !== STATE.TOUCH_ROTATE) {
          return
        } // is this needed?...

        // handleTouchMoveRotate(event)

        break

      case 2: // two-fingered touch: dolly
        if (scope.enableZoom === false) {
          return
        }
        if (state !== STATE.TOUCH_DOLLY) {
          return
        } // is this needed?...

        handleTouchMoveDolly(event)

        break

      case 3: // three-fingered touch: pan
        if (scope.enablePan === false) {
          return
        }
        if (state !== STATE.TOUCH_PAN) {
          return
        } // is this needed?...

        handleTouchMovePan(event)

        break

      default:
        state = STATE.NONE
    }
  }

  const onTouchEnd = (event) => {
    if (scope.enabled === false) {
      return
    }

    handleTouchEnd(event)

    scope.dispatchEvent(endEvent)

    state = STATE.NONE
  }

  const onContextMenu = (event) => {
    event.preventDefault()
  }

  scope.domElement.addEventListener('contextmenu', onContextMenu, false)

  scope.domElement.addEventListener('mousedown', onMouseDown, false)
  scope.domElement.addEventListener('wheel', onMouseWheel, false)

  scope.domElement.addEventListener('touchstart', onTouchStart, false)
  scope.domElement.addEventListener('touchend', onTouchEnd, false)
  scope.domElement.addEventListener('touchmove', onTouchMove, false)

  scope.domElement.addEventListener('mouseup', onElementMouseUp, false)
  scope.domElement.addEventListener('keydown', onKeyDown, false)

  this.update()
}

ZoomPanControls.prototype = Object.create(THREE.EventDispatcher.prototype)
ZoomPanControls.prototype.constructor = ZoomPanControls

// Object.defineProperties(ZoomPanControls.prototype, {
//   center: {
//     get: () => {
//       console.warn('ZoomPanControls: .center has been renamed to .target')
//       return this.target
//     }
//   },

//   // backward compatibility

//   noZoom: {
//     get: () => {
//       console.warn('ZoomPanControls: .noZoom has been deprecated. Use .enableZoom instead.')
//       return !this.enableZoom
//     },

//     set: value => {
//       console.warn('ZoomPanControls: .noZoom has been deprecated. Use .enableZoom instead.')
//       this.enableZoom = !value
//     }
//   },

//   noPan: {
//     get: () => {
//       console.warn('ZoomPanControls: .noPan has been deprecated. Use .enablePan instead.')
//       return !this.enablePan
//     },

//     set: value => {
//       console.warn('ZoomPanControls: .noPan has been deprecated. Use .enablePan instead.')
//       this.enablePan = !value
//     }
//   },

//   noKeys: {
//     get: () => {
//       console.warn('ZoomPanControls: .noKeys has been deprecated. Use .enableKeys instead.')
//       return !this.enableKeys
//     },

//     set: value => {
//       console.warn('ZoomPanControls: .noKeys has been deprecated. Use .enableKeys instead.')
//       this.enableKeys = !value
//     }
//   },

//   staticMoving: {
//     get: () => {
//       console.warn('ZoomPanControls: .staticMoving has been deprecated. Use .enableDamping instead.')
//       return !this.enableDamping
//     },

//     set: value => {
//       console.warn('ZoomPanControls: .staticMoving has been deprecated. Use .enableDamping instead.')
//       this.enableDamping = !value
//     }
//   },

//   dynamicDampingFactor: {
//     get: () => {
//       console.warn('ZoomPanControls: .dynamicDampingFactor has been renamed. Use .dampingFactor instead.')
//       return this.dampingFactor
//     },

//     set: value => {
//       console.warn('ZoomPanControls: .dynamicDampingFactor has been renamed. Use .dampingFactor instead.')
//       this.dampingFactor = value
//     }
//   }
// })

export default ZoomPanControls
