import THREE from 'lib/threejs'
import CANNON from 'cannon'
import CORE3D, { coreLabel } from '../core-3d'
import loader from './loader.js'
import ZoomPanControls from './ZoomPanControls.js'
import * as Types from '../Types'
const isDebug = false
export default {
  init,
  bind,
  clear,
  reset,
  animate,
  zoomTo,
  zoomOut,
  createLayer,
  updateLayer,
  get,
  getRootNode,
  getLastPickedNode,
  getSelectableNodes,
  hasChildren,
  getChildNodes,
  getSiblingNodes,
  normalizeLayerScale,
  revertLayerScale,
  resetScale,
  removeNodes,
  removeChildNodes,
  removePhysics,
  removeRootNode,
  attachLabels,
  attachParentLabel,
  detachParentLabel,
  lock,
  unlock,
  buildControls,
  getControls
}

// Maximum number of node meshes per layer:
const worldLimit = 1000

// Minimum node mesh scale threshold:
const minScale = 0.001

// Diameter/size of default node mesh:
const rootDiameter = 1

// Animation parameters:
const timeToFreeze = 1000

// Physics settings:
const timeStep = 1 / 60

// CoreScene, CoreMouse, CoreCamera references:
let scene = null
let camera = null

// Physics world reference:
let world = null

// CannonDebugRenderer:
// let cannonDebugRenderer = null

// ZoomPan(or possibly Orbit)Controls reference:
let controls = null

// Default parent node mesh:
let rootNode = null
let lastPickedNode = null

// Node mesh Object3D storage:
let populationNodes = []

// Cannon contact material reference:
let roughMaterial = null
let roughRoughCM = null

// Population state references:
let populationState = {}
let newLayer = false
// let frozen = true
let zooming = false

// Population data references:
let populationData = null
let metadata = {
  total: 0
}

// Node texture references:
let sphereTexture, sphereHoverTexture

// Click action reference:
let clickAction = null

let dispatchHandler = null

// User input lock flag:
let allowUserInput = true

// Non-population layer flag:
let nonPopulationLayer = false

// Init non-blocking assets:
loader.init()

function init(container, coreScene, coreCamera, coreMouse, dispatchCallback) {
  dispatchHandler = dispatchCallback

  // Get scene, camera, and mouse handler:
  scene = coreScene
  camera = coreCamera

  // Initialize camera position:
  let zoomPadding = 0.65
  let viewport = scene.getViewport()
  let initialPosition = new THREE.Vector3(0, 0, 1)
  scene.getCamera().position.copy(initialPosition)
  scene.getCamera().updateProjectionMatrix()
  scene.getCamera().zoom =
    Math.min(viewport.viewWidth, viewport.viewHeight) * zoomPadding

  const light = scene.buildLight('PopulationLight_1', {
    type: 'AmbientLight',
    color: 0xaeddf0,
    intensity: 1.0
  })
  const light2 = scene.buildLight('PopulationLight_2', {
    type: 'DirectionalLight',
    color: 0xaeddf0,
    intensity: 5.0
  })
  light.position.set(0, 0, rootDiameter)
  light2.position.set(rootDiameter, 1.5 * rootDiameter, rootDiameter)

  // Clean population state:
  reset()

  // Create physics world (may move to CORE3D):
  world = new CANNON.World()
  world.gravity.set(0, 0, 0)
  world.broadphase = new CANNON.NaiveBroadphase()
  world.solver.iterations = 6
  scene.setPhysicsWorld(world)
  world.addEventListener('preStep', () => {
    Object.keys(scene.getObjects()).forEach((uuid) => {
      const obj = scene.getObjects()[uuid]
      if (typeof obj.preStep !== 'undefined') {
        obj.preStep()
      }
    })
  })
  world.addEventListener('postStep', () => {
    Object.keys(scene.getObjects()).forEach((uuid) => {
      const obj = scene.getObjects()[uuid]
      if (typeof obj.postStep !== 'undefined') {
        obj.postStep()
      }
    })
  })

  // Create scene debug renderer:
  // cannonDebugRenderer = new THREE.CannonDebugRenderer(scene.getScene(), world)

  // Create physics world contact material (may move to CORE3D):
  roughMaterial = new CANNON.Material('roughMaterial')
  roughRoughCM = new CANNON.ContactMaterial(roughMaterial, roughMaterial, {
    friction: 0,
    restitution: 0.000001,
    contactEquationStiffness: 1e8,
    contactEquationRelaxation: 3,
    contactEquationRegularizationTime: 3
  })
  world.addContactMaterial(roughRoughCM)

  return loader.load('petri').then(() => {
    // Create root mesh:
    rootNode = scene.buildMesh('sphere', 'sphereParentMaterial', 'rootNode')
    scene.addMesh(rootNode)
    rootNode.name = 'root'
    rootNode.position.z = -10
    rootNode.scale.setScalar(rootDiameter)
    rootNode.radius = rootDiameter / 2
    // rootNode.castShadow = true
    // rootNode.receiveShadow = true

    // Create root node scale container:
    const scaleContainer = new THREE.Group()
    scaleContainer.name = 'scaleContainer'
    scaleContainer.position.z = 1

    // Create root node label container:
    const labelContainer = new THREE.Group()
    labelContainer.name = 'labelContainer'

    // Add and attach references of scale and label containers to root node:
    rootNode.scaleContainer = scaleContainer
    rootNode.add(scaleContainer)
    rootNode.labelContainer = labelContainer
    rootNode.add(labelContainer)

    // Set our own parameters:
    rootNode.animatingCount = 0
    // rootNode.hittable = true

    // Set last picked reference to rootNode
    isDebug && console.log('ON LOAD', rootNode)
    lastPickedNode = rootNode

    // Set texture references:
    sphereTexture = CORE3D.grabTextures()['sphereTexture']
    sphereHoverTexture = CORE3D.grabTextures()['sphereHoverTexture']
  })
}

// Lock petri from user input (in case it needs to be called externally)
function lock() {
  allowUserInput = false
}

// Unlock petri from user input (in case it needs to be called externally)
function unlock() {
  allowUserInput = true
}

function buildControls() {
  if (camera && scene.getRenderer()) {
    scene.getRenderer().domElement.tabIndex = '0'
    controls = new ZoomPanControls(
      camera.getCamera(),
      scene.getRenderer().domElement
    )
    return controls
  }
}

function getControls() {
  return controls
}

function afterAnimation() {
  // console.log('Zoom finished')
  attachParentLabel()

  zooming = false

  if (dispatchHandler) {
    dispatchHandler({
      type: clickAction,
      payload: lastPickedNode.nodeData
    })
  }
}

// Generate afterAnimation callback that will dispatch input action:
function generateAfterAnimation(action) {
  return () => {
    attachParentLabel()

    if (dispatchHandler) {
      dispatchHandler(action)
    }
  }
}

const updateZoomPosition = (action, { matrixWorld }) => {
  const newPosition = new THREE.Vector3()
  const newScale = new THREE.Vector3()
  newPosition.setFromMatrixPosition(matrixWorld)
  newScale.setFromMatrixScale(matrixWorld)
  const scale = Math.max(newScale.x, newScale.y, newScale.z)

  // zooming = true
  if (typeof action !== 'undefined') {
    camera.animateTo(
      newPosition.x,
      newPosition.y,
      scale,
      generateAfterAnimation(action)
    )
  } else {
    camera.animateTo(newPosition.x, newPosition.y, scale, afterAnimation)
  }
}

function zoomTo(obj, action, callback) {
  if (allowUserInput) {
    if (obj !== rootNode && obj.name !== 'dot') {
      nonPopulationLayer = true
      allowUserInput = true
      clickAction = null
    } else if (obj === rootNode || obj.name === 'dot') {
      nonPopulationLayer = false
      allowUserInput = false
    }

    lastPickedNode = obj

    callback && callback(lastPickedNode)

    if (lastPickedNode !== rootNode) {
      lastPickedNode.parent.updateMatrixWorld()
    }

    updateZoomPosition(action, lastPickedNode)
  }
}

function zoomOut(action, callback) {
  if (lastPickedNode !== rootNode && allowUserInput) {
    if (!nonPopulationLayer) {
      removePhysics()
      detachParentLabel()
      allowUserInput = false
      clickAction = Types.POPULATION_POP
    }
    lastPickedNode = lastPickedNode.parent.parent

    if (lastPickedNode !== rootNode) {
      lastPickedNode.parent.updateMatrixWorld()
    }

    if (!nonPopulationLayer) {
      allowUserInput = false
    }

    nonPopulationLayer = false
    callback && callback(lastPickedNode)

    updateZoomPosition(action, lastPickedNode)
  }
}

function reset() {
  populationState = {
    lastAdded: 0,
    worldCount: 0,
    populateTimer: 0,
    currentAngle: 0
  }
  // zoomTo(lastPickedNode)
  // zoomOut()
}

// Main update function called once per frame by the animation loop:
function animate() {
  // Update population if a new layer with data exists:
  if (scene && lastPickedNode && newLayer && populationData) {
    const cutoff = Math.min(populationData.length, worldLimit)
    if (populationState.worldCount < cutoff) {
      // OLD: add 1 node a frame:
      populationState.worldCount += addNode(populationState.worldCount)
    } else {
      // Set new layer flag to false:
      newLayer = false

      // Meshes have been drawn, initiate timeout:
      setTimeout(freezeObjects, timeToFreeze)

      populationData = null

      if (dispatchHandler) {
        dispatchHandler({
          type: Types.POPULATION_DRAW_COMPLETED
        })
      }
    }

    // Attach mesh text labels:
    attachLabels()
  }

  // Update the physics world:
  world.step(timeStep)

  // Update the positions and scales of all meshes in the current population:
  centerAndScale()

  // Update scene debug renderer:
  // if (cannonDebugRenderer !== null) {
  //   cannonDebugRenderer.update()
  // }
}

// TODO: Eventually data argument may be an event/state thing:
function bind(data) {
  populationData = data
  // Compute current total count of node values, used for scaling in addNode:
  metadata.total = data.reduce((acc, el) => acc + el.value, 0)
}

function clear() {
  populationData = null
  metadata.total = 0
}

function getLastPickedNode() {
  return lastPickedNode
}

function hasChildren() {
  return lastPickedNode.scaleContainer.children.length > 0
}

function getChildNodes() {
  if (
    typeof lastPickedNode === 'undefined' ||
    lastPickedNode === null ||
    typeof lastPickedNode.scaleContainer === 'undefined' ||
    lastPickedNode.scaleContainer === null
  ) {
    return []
  }
  return lastPickedNode.scaleContainer.children
    ? lastPickedNode.scaleContainer.children
    : []
}

function getSiblingNodes() {
  // If at root level, no sibling nodes so return empty array:
  if (lastPickedNode === rootNode) {
    return []
  }
  // Otherwise return array of sibling nodes, filtering out lastPickedNode:
  return lastPickedNode.parent.children.filter(
    (node) => node !== lastPickedNode
  )
}

function createLayer() {
  isDebug && console.log('createLayer')
  const parentNode = lastPickedNode

  // Remove any existing child node meshes:
  if (parentNode && typeof parentNode.scaleContainer !== 'undefined') {
    removeAllChildren(parentNode)

    // Recreate and add scaleContainer:
    parentNode.scaleContainer = new THREE.Group()
    parentNode.scaleContainer.position.z = 1
    parentNode.scaleContainer.name = 'scaleContainer'
    parentNode.add(parentNode.scaleContainer)

    // Recreate and add labelContainer:
    parentNode.labelContainer = new THREE.Group()
    parentNode.labelContainer.name = 'labelContainer'
    parentNode.add(parentNode.labelContainer)
  }

  // Reset populationState:
  reset()

  // // Initiate drawing/animation only if there is data and not on the final layer:
  // if (petriModel.getPopulationData().length > 0 && !petriModel.nonPopulationLayer()) {
  //   frozen = false
  // }

  // Flag new layer creation:
  newLayer = true
}

function updateLayer() {
  const nodes = lastPickedNode?.scaleContainer?.children

  isDebug && console.log('updateLayer', nodes, populationData, lastPickedNode)
  // Update nodes with new data:
  populationData.forEach((el, ind) => {
    const node = nodes?.find((e) => e.nodeName === el.key)

    // If node exists, update:
    if (node) {
      // Update node's value, for sorting:
      node.nodeValue = el.value

      // Use data node scale if it exists, otherwise calculate it:
      const scale = el.scale || Math.max(el.value / metadata.total, minScale)
      // Update node's drawn size (radius etc.) based on new scale:
      node.updateScale(scale)

      // Update associated node data:
      node.nodeData = Object.assign({}, el)
      node.showLabel = true

      // If not, add new node:
    } else {
      addNode(ind)
    }
  })

  // Remove nodes (and their children & labels) that do not exist:
  let i = nodes.length
  while (i--) {
    const node = nodes[i]
    const found = populationData.reduce((acc, curr) => {
      return acc ? acc : curr.key === node.nodeName
    }, false)
    if (!found) {
      if (node.body) {
        world.removeBody(node.body)
      }
      scene.removeObjectByUuid(node.uuid)
      // Recursive call to remove children of child node:
      removeAllChildren(node)
      lastPickedNode.scaleContainer.remove(node)
      scene.removeObjectByUuid(node.label.uuid)
      lastPickedNode.labelContainer.remove(node.label)
      delete node.label
      let index = populationNodes.indexOf(node)
      if (index > -1) {
        populationNodes.splice(index, 1)
      }
    }
  }

  // Sort nodes to show labels for the three largest (done in centerAndScale):
  nodes.sort((a, b) => b.nodeValue - a.nodeValue)

  // Attach labels after sorting (so new nodes have labels, and the updated top 3 are visible):
  attachLabels()

  // Once previous layer is done being updated, unlock Petri from user input
  // allowUserInput = true

  // zoomTo(lastPickedNode)
  if (dispatchHandler) {
    dispatchHandler({
      type: Types.POPULATION_DRAW_COMPLETED
    })
  }
}

function normalizeLayerScale() {
  const scaleContainer = lastPickedNode ? lastPickedNode.scaleContainer : null
  if (scaleContainer) {
    // Only normalize/revert nodes that are currently visible (maybe check nodeValue > 0):
    const nodes = scaleContainer.children.filter((el) => el.visible === true)
    const nodeCount = nodes.length
    const normalizedScale = 1 / nodeCount
    nodes.forEach((node) => {
      node.updateScale(normalizedScale, true)
      node.showLabel = true
    })
  }
}

function revertLayerScale() {
  const scaleContainer = lastPickedNode ? lastPickedNode.scaleContainer : null
  if (scaleContainer) {
    // Only normalize/revert nodes that are currently visible (maybe check nodeValue > 0):
    const nodes = scaleContainer.children.filter((el) => el.visible === true)
    nodes.forEach((node) => {
      node.updateScale(node.nodeScale)
      // node.showLabel = false
    })
  }
}

function resetScale() {
  // TODO: Maybe reference parent to avoid looping through every single node Object3D:
  populationNodes.forEach((node) => node.resetScale())
}

function removeNodes() {
  isDebug && console.log('removeNodes')
  if (rootNode !== null) {
    // console.log('Removing all nodes except default node from the scene')
    // Remove all known node mesh Object3D's from the scene:
    removeAllChildren(rootNode)

    // // Clear out Petri Label factory:
    // PL.removeAllLabels()

    // Recreate and add scaleContainer:
    rootNode.scaleContainer = new THREE.Group()
    rootNode.scaleContainer.position.z = 1
    rootNode.scaleContainer.name = 'scaleContainer'
    rootNode.add(rootNode.scaleContainer)

    // Recreate and add labelContainer:
    rootNode.labelContainer = new THREE.Group()
    rootNode.labelContainer.name = 'labelContainer'
    rootNode.add(rootNode.labelContainer)

    // Clear out list of known Node Object3D's:
    populationNodes.length = 0

    // Reset populationState:
    reset()

    // Set lastPickedNode back to root:
    lastPickedNode = rootNode

    // Set flag back to nonPopulationLayer (recordLayer):
    nonPopulationLayer = false
  } else {
    console.warn(
      'Population: Population was cleared before rootNode was defined...'
    )
  }
}

function removeChildNodes() {
  if (lastPickedNode && lastPickedNode.name !== 'record') {
    removeAllChildren(lastPickedNode)
  }
}

function removePhysics() {
  if (lastPickedNode.name !== 'record') {
    lastPickedNode.scaleContainer.children.forEach((node) => {
      if (typeof node.body !== 'undefined') {
        world.removeBody(node.body)
      }
      // // Don't clear these in case the body needs to be used again:
      // node.preStep = function () {
      // }
      // node.postStep = function () {
      // }
    })
  }
}

function removeRootNode() {
  rootNode.remove(rootNode.scaleContainer)
  rootNode.remove(rootNode.labelContainer)
  // scene.getScene().remove(rootNode)
  scene.removeObjectByUuid(rootNode.uuid)
  rootNode = null
  lastPickedNode = null
}

function getRootNode() {
  return rootNode
}

function getSelectableNodes() {
  let selectableNodes = []
  if (lastPickedNode && !nonPopulationLayer) {
    selectableNodes = selectableNodes
      .concat(getChildNodes())
      .concat(getSiblingNodes())
  }
  return selectableNodes
}

function get() {
  let selectableNodes = []
  if (lastPickedNode && !nonPopulationLayer) {
    selectableNodes = selectableNodes
      .concat(getChildNodes())
      .concat(getSiblingNodes())
  }
  return selectableNodes
}

function attachLabels() {
  if (lastPickedNode !== null) {
    lastPickedNode.scaleContainer.children.forEach((node) => {
      let label = node.label
      // Create label and attach to node if it doesn't exist:
      if (typeof label === 'undefined') {
        const labelText = node.nodeName !== '' ? node.nodeName : 'EMPTY_FIELD'
        coreLabel.addLabel({
          id: node.nodeData._id,
          text: labelText,
          fontSize: 64,
          fillColor: '#FFFFFF'
        })
        label = coreLabel.getLabel(labelText)
        label.labelName = labelText
        node.label = label
      }
      // Initialize visibility to false, except for top 3 nodes:
      label.visible = true

      // Scale and position the label respective to it's mesh:
      let newScale = new THREE.Vector3(1, 1, 1)
      newScale.copy(node.scale)
      // This scaling fits label inside spherical node (may need tweaking):
      label.scale.copy(newScale.divideScalar(3))
      label.position.copy(node.position)
      label.quaternion.copy(node.quaternion)

      // Add label to parent labelContainer:
      lastPickedNode.labelContainer.add(label)
    })

    // Scale and position labelContainer respective to it's scaleContainer:
    lastPickedNode.labelContainer.scale.copy(
      lastPickedNode.scaleContainer.scale
    )
    lastPickedNode.labelContainer.position.copy(
      lastPickedNode.scaleContainer.position
    )

    // Place labels above nodes so they're clearly visible:
    lastPickedNode.labelContainer.position.setZ(9)
  }
}

function attachParentLabel() {
  // Parent label resize, reposition, and make visible:
  const parentNode = lastPickedNode
  if (parentNode) {
    const parentLabel = parentNode.label
    if (typeof parentLabel !== 'undefined' && !parentLabel.visible) {
      parentLabel.savedScale = parentLabel.scale.clone()
      parentLabel.savedPosition = parentLabel.position.clone()
      const scale = parentLabel.scale.y
      parentLabel.position.setY(parentLabel.position.y + scale)
      parentLabel.scale.divideScalar(2)
      parentLabel.visible = true
    }
  }
}

function detachParentLabel() {
  // Last parent label revert size, position, and visibility:
  const parentNode = lastPickedNode
  if (parentNode && parentNode.name !== 'record') {
    const lastParentLabel = parentNode.label
    if (typeof parentNode.label !== 'undefined') {
      lastParentLabel.scale.copy(lastParentLabel.savedScale)
      lastParentLabel.position.copy(lastParentLabel.savedPosition)
      lastParentLabel.visible = false
    }
    parentNode.labelContainer.children.forEach(
      (label) => (label.visible = false)
    )
  }
}

function centerAndScale() {
  const parentNode = lastPickedNode
  // This block performs the actual center and scaling, the flags here may change depending on scenario
  // (live or static data, constantly running physics engine, etc.):
  if (
    parentNode !== null &&
    !zooming &&
    parentNode.animatingCount > 0 &&
    parentNode.scaleContainer.children.length > 0
  ) {
    // Current scale and label containers:
    const scaleContainer = parentNode.scaleContainer
    const labelContainer = parentNode.labelContainer

    // Update the node mesh and labels based on the physics bodies positions
    // (this MUST be done before we calculate the population bounds):
    scaleContainer.children.forEach((node) => {
      const body = node.body
      const label = node.label
      if (typeof body !== 'undefined') {
        node.position.copy(body.position)
        node.quaternion.copy(body.quaternion)
        if (typeof label !== 'undefined') {
          label.position.copy(body.position)
          label.quaternion.copy(body.quaternion)
        }
      }
    })

    // Parent node's REAL location:
    const parentWorldPosition = new THREE.Vector3()
    parentWorldPosition.setFromMatrixPosition(parentNode.matrixWorld)

    // Parent node's world scale:
    const worldScale = new THREE.Vector3()
    worldScale.setFromMatrixScale(parentNode.matrixWorld)

    // Parent node's bounding box WITHOUT children:
    // .size() returns edge lengths of the box on each axis:

    parentNode.geometry.computeBoundingBox()
    const parentSize = new THREE.Vector3()
    parentNode.geometry.boundingBox.getSize(parentSize)

    // REAL size of the parent node's bounding box based on it's world scale:
    parentSize.multiply(worldScale)

    // Parent node's bounding sphere radius and position:
    const boundingSphere = getBoundingSphere(scaleContainer)

    const populationMax = Math.max(minScale, boundingSphere.radius * 2)

    const populationCenter = boundingSphere.center

    // SCALE the population:
    {
      // Determine longest axis-aligned box edge of the parent's bounds:
      const parentMax = Math.max(parentSize.x, parentSize.y, parentSize.z)

      // Find ratio needed to scale the population to fill the parent:
      // - Divide by this to get to the physics scale
      // - Multiple by this to get to the mesh scale
      const scale = Math.max(minScale, parentMax / populationMax)

      // Scale the current scaleContainer and labelContainer so they fill the parent
      // to the correct size:
      scaleContainer.scale.multiplyScalar(scale)
      labelContainer.scale.multiplyScalar(scale)
    }

    // CENTER the population:
    {
      // NOTE: LOOK HERE FIRST! Scale values that are too small will break (cannot invert determinant 0)
      // Determine scale offset required to center the population inside it's parent:
      const meshScaleOffset = new THREE.Vector3() // Save offset
      meshScaleOffset.subVectors(parentWorldPosition, populationCenter)

      // We don't want to change the z position of our label container or
      // scale container, so we need to zero out that axis in this Vec3:
      meshScaleOffset.setZ(0)

      // Center the current scaleContainer and labelContainer so they stay within the parent bounds:
      scaleContainer.position.add(meshScaleOffset)
      labelContainer.position.add(meshScaleOffset)
    }
  } // end if

  if (parentNode && parentNode.scaleContainer && parentNode.labelContainer) {
    parentNode.scaleContainer.children.forEach((node) => {
      node.label.visible = node.hovering || node.showLabel
      if (!node.visible) {
        node.label.visible = false
      }
    })
  }
}

// Recursively removes all child node meshes and respective containers attached to them:
function removeAllChildren(parentNode) {
  isDebug && console.log('removeAllChildren')
  // Remove nodes from parent node:
  parentNode.scaleContainer.children.forEach((node) => {
    if (node.body) {
      world.removeBody(node.body)
    }
    scene.removeObjectByUuid(node.uuid)
    // Recursive call to remove children of child node:
    removeAllChildren(node)
    delete node.label
    let index = populationNodes.indexOf(node)
    if (index > -1) {
      populationNodes.splice(index, 1)
    }
  })
  parentNode.scaleContainer.children.length = 0
  parentNode.remove(parentNode.scaleContainer)
  scene.getScene().remove(parentNode.scaleContainer)
  delete parentNode.scaleContainer
  // Remove labels from parent node:
  parentNode.labelContainer.children.forEach((label) => {
    scene.removeObjectByUuid(label.uuid)
  })
  parentNode.labelContainer.children.length = 0
  parentNode.remove(parentNode.labelContainer)
  scene.getScene().remove(parentNode.labelContainer)
  delete parentNode.labelContainer

  // Remove expandFrames parameter:
  delete parentNode.expandFrames
}

function freezeObjects() {
  let parentNode = lastPickedNode
  parentNode.scaleContainer.children.forEach((node) => {
    // Allow slider scaling after animation freeze:
    node.shouldScale = true
  })
  // New layer is done animating:
  // frozen = true
}

// Circle pack builder:
function addNode(index) {
  // Grab Data we need to draw this layer:
  const nodeData = populationData[index]
  const nodeName = nodeData.key
  // Set node's value (for sorting):
  const nodeValue = nodeData.value

  // Set node's drawn size (radius etc.) based on the scale (IMPORTANT!!!):
  // Use scale if it exists
  const nodeScale = nodeData.scale
    ? Math.max(nodeData.scale, minScale)
    : Math.max(nodeValue / metadata.total, minScale)

  // Set the node's size based on it's fraction of the whole current dataset:
  const radius = nodeScale / 2

  // We need the parent object. We should know it at this point:
  const parentNode = lastPickedNode

  // Build node Object3D:
  const nodeMesh = scene.buildMesh('sphere', 'sphereMaterial', 'dot')

  // Scale the mass and spring rate by the parent scale:
  // Scale the popBounds based on our world scale:
  const worldScale = new THREE.Vector3()
  worldScale.setFromMatrixScale(parentNode.matrixWorld)
  // Set scale, set parameters, attach data, and add to parent scaleContainer:
  {
    // We will place all children into a scaleable container object:
    parentNode.scaleContainer.add(nodeMesh)

    // Population specific node info:
    nodeMesh.nodeName = nodeName
    nodeMesh.nodeValue = nodeValue
    nodeMesh.nodeScale = nodeScale
    // Information to display:
    nodeMesh.nodeData = Object.assign({}, nodeData)
    // Set up three.js params:
    nodeMesh.scale.setScalar(nodeScale)
    // nodeMesh.castShadow = true
    // nodeMesh.receiveShadow = true
    // TODO: Maybe move all these non-threejs params into a single object?
    // Non-three.js parameter:
    // nodeMesh.depth = depth
    nodeMesh.radius = radius
    nodeMesh.hittable = true
    nodeMesh.hovering = false
    nodeMesh.showLabel = true
    nodeMesh.shouldAnimate = true
    nodeMesh.animatingCount = 0
    nodeMesh.shouldScale = true
    // Build this node's scaleable children container and label container:
    let scaleContainer = new THREE.Group()
    scaleContainer.name = 'scaleContainer'
    let labelContainer = new THREE.Group()
    labelContainer.name = 'labelContainer'

    // Attach containers and references to node mesh:
    nodeMesh.scaleContainer = scaleContainer
    nodeMesh.add(scaleContainer)
    nodeMesh.labelContainer = labelContainer
    nodeMesh.add(labelContainer)

    // Move the scale container back towards the camera:
    // TODO: Set the z position based on current layer
    scaleContainer.position.z = 5
  }

  // Build and attach physics body to node mesh:
  {
    // Set node's physics body mass relative to the node's size:
    const mass = radius
    // mass = index === 0 ? 0 : mass

    // Physics body parameters:
    nodeMesh.body = new CANNON.Body({
      mass,
      linearDamping: 0.9,
      linearFactor: new CANNON.Vec3(1, 1, 0),
      angularDamping: 0.6,
      angularFactor: new CANNON.Vec3(0, 0, 1),
      fixedRotation: true
    })
    nodeMesh.body.updateMassProperties()
    nodeMesh.shape = new CANNON.Sphere(radius)
    nodeMesh.body.addShape(nodeMesh.shape)

    // Initialize physics body and node mesh to same starting position:
    let initialPosition = calculateInitialPosition(index, parentNode, radius)
    nodeMesh.body.position.x = initialPosition.x
    nodeMesh.body.position.y = initialPosition.y
    nodeMesh.body.position.z = initialPosition.z
    nodeMesh.position.copy(nodeMesh.body.position)
    nodeMesh.quaternion.copy(nodeMesh.body.quaternion)

    // Physics world post step callback - determines force/velocity every frame:
    nodeMesh.postStep = function () {
      if (this.shouldAnimate) {
        let bodyToCenter = new CANNON.Vec3()
        nodeMesh.body.position.negate(bodyToCenter)
        let distance = bodyToCenter.norm()
        bodyToCenter.normalize()
        // This sets the spring force (toward center/origin) - tweak here if needed:
        bodyToCenter.mult(0.5 * distance, nodeMesh.body.force)
        this.body.applyForce(this.body.force, this.body.position)
      }
    }

    // Add physics body to physics world:
    world.addBody(nodeMesh.body)
  }

  // Population node methods:
  // {
  // Slider scaling methods:
  nodeMesh.updateScale = function (scale, normalize = false) {
    if (this.shouldScale) {
      const body = this.body
      // Update scale and physics body if value > 0:
      if (scale > 0) {
        const newScale = Math.max(scale, minScale)
        this.scale.setScalar(newScale)
        // If not normalize, save newScale as nodeScale:
        if (!normalize) {
          // Save newScale as the nodeScale:
          this.nodeScale = newScale
        }
        // Update the physics bodies:
        const scaledRadius = newScale / 2
        const mass = scaledRadius
        this.radius = scaledRadius
        // Update CANNON body's shape:
        const shape = body.shapes[0]
        shape.radius = scaledRadius
        body.updateBoundingRadius()
        body.computeAABB()
        // Update CANNON body's mass:
        body.mass = mass
        body.updateMassProperties()
        // Zero out velocity and inertia of the body to stabilize the population:
        body.velocity.setZero()
        body.initVelocity.setZero()
        body.angularVelocity.setZero()
        body.initAngularVelocity.setZero()
        world.addBody(body)
        // Scale label:
        this.label.scale.copy(this.scale).divideScalar(3)
        // Make visible:
        this.visible = true
        // Otherwise hide and remove physics body (when value <= 0):
      } else {
        world.removeBody(this.body)
        // this.label.visible = false
        this.visible = false
      }
    }
  }

  // Reset the node meshes do their default scale/size:
  nodeMesh.resetScale = function () {
    if (this.shouldScale) {
      this.scale.setScalar(this.nodeScale)
      this.radius = this.nodeScale / 2
      // Scale label:
      // this.label.scale.copy(this.scale).divideScalar(3)
    }
  }

  // Click handler:
  nodeMesh.onClick = function (callback) {
    if (allowUserInput) {
      removePhysics()
      if (dispatchHandler) {
        if (lastPickedNode.scaleContainer.children.includes(this)) {
          clickAction = Types.POPULATION_PUSH
        }
        if (lastPickedNode.parent.children.includes(this)) {
          clickAction = Types.POPULATION_REPLACE
        }
      }
      detachParentLabel()
      lastPickedNode = this
      nonPopulationLayer = false
      allowUserInput = false
      callback && callback()
      const newPosition = new THREE.Vector3()
      const newScale = new THREE.Vector3()
      newPosition.setFromMatrixPosition(lastPickedNode.matrixWorld)
      newScale.setFromMatrixScale(lastPickedNode.matrixWorld)
      const scale = Math.max(newScale.x, newScale.y, newScale.z)

      camera.animateTo(newPosition.x, newPosition.y, scale, afterAnimation)
    }
  }

  // Select/deselect handlers:
  nodeMesh.onSelect = function () {
    if (dispatchHandler) {
      dispatchHandler({
        type: Types.POPULATION_ON_HOVER,
        payload: this.nodeData
      })
    }
    this.highlight()
  }
  nodeMesh.onDeselect = function () {
    if (dispatchHandler) {
      dispatchHandler({
        type: Types.POPULATION_OFF_HOVER,
        payload: this.nodeData
      })
    }
    this.unhighlight()
  }

  // Highlight/unhighlight setters:
  nodeMesh.highlight = function () {
    if (typeof this.label !== 'undefined') {
      this.label.visible = true
    }
    this.material.envMap = sphereHoverTexture
    this.material.needsUpdate = true
    this.hovering = true
  }
  nodeMesh.unhighlight = function () {
    if (
      typeof this.label !== 'undefined' &&
      lastPickedNode !== rootNode &&
      this.parent.parent !== lastPickedNode
    ) {
      // Sibling nodes
      this.label.visible = false
    }
    this.material.envMap = sphereTexture
    this.material.needsUpdate = true
    this.hovering = false
  }
  // }

  // Tell the parent that we're animating:
  parentNode.animatingCount += 1

  // Add to node mesh Object3D storage:
  populationNodes.push(nodeMesh)

  // Let our caller that we've drawn a single node:
  return 1
}

function calculateInitialPosition(index, parent, radius) {
  // Calculate initial position of this node:
  let initialPosition = new THREE.Vector3(0, 0, 0)

  // If the node is not the first node, execute the sphere cast:
  if (index > 0) {
    let nodeAngle = 0
    let parentRadius = rootDiameter / 2
    let rad = parentRadius * 3 // 3 = Circle Radius Scatter factor

    let maxNodesPerScatter = 15
    let step = Math.PI / maxNodesPerScatter
    let offset = (index % maxNodesPerScatter) * (step / maxNodesPerScatter)
    nodeAngle = index * step + offset
    initialPosition.x = Math.cos(nodeAngle) * rad
    initialPosition.y = Math.sin(nodeAngle) * rad
    initialPosition.z = 0

    // Perform the sphere cast:
    initialPosition = runSphereCast(initialPosition, radius, parent)
  }

  return initialPosition
}

function runSphereCast(position, radius, parent) {
  // NOTE: May be able to perform som optimization in the ElasticSearch query itself if
  // all the data is spatial:
  //
  // Inspired by billiard/pool ball example from:
  // http://www.gamasutra.com/view/feature/131424/pool_hall_lessons_fast_accurate_.php?page=2

  // Convert THREE.Vec3 to CANNON.Vec3:
  const A = new CANNON.Vec3(position.x, position.y, position.z)

  // Location is always referenced from parent's world coordinate. So, direction is always towards origin:
  const V = new CANNON.Vec3(0, 0, 0).vsub(A)

  // Hold onto the closest hit:
  let closestHit = 1000000
  let shortestTravelPosition = new THREE.Vector3(0, 0, 0)

  // Loop through current existing nodes and exclude as necessary:
  parent.scaleContainer.children.forEach((node) => {
    // Projection (dot product) of existing node's position with position of node being added
    // Determines if added node is moving toward existing node:
    const B = new CANNON.Vec3(node.position.x, node.position.y, node.position.z)
    const C = B.vsub(A)
    const dotTowards = V.dot(C)

    // Exclude this node if not moving towards it:
    if (dotTowards <= 0) {
      return
    }

    // Determine how close to approach existing node:
    const VNormalized = V.clone()
    VNormalized.normalize()
    const D = VNormalized.dot(C)
    const F = Math.pow(C.length(), 2) - Math.pow(D, 2)
    const combinedRadii = radius + node.radius
    const closest = Math.pow(combinedRadii, 2) // (A.radius + B.radius)^2
    if (F > closest) {
      // Exclude this node if still too far away at the closest approach:
      return
    }

    // Determine actual collision point between the objects
    const T = Math.pow(combinedRadii, 2) - F
    const distanceToHit = D - Math.sqrt(T)
    if (distanceToHit < closestHit && distanceToHit > 0) {
      closestHit = distanceToHit
      shortestTravelPosition = VNormalized.mult(distanceToHit)
    }
  })

  // Initialize first node mesh position at origin when there are no other
  // node meshes in the world. Not sure if this is a good idea.
  position.x += shortestTravelPosition.x
  position.y += shortestTravelPosition.y
  position.z += shortestTravelPosition.z
  return position
}

// Custom bounding sphere calculation that only accounts for the immediate child node meshes in the container:
function getBoundingSphere(container) {
  // Computes the world-axis-aligned bounding box of an object (including its children),
  // accounting for both the object's, and children's, world transforms
  const min = new THREE.Vector3(Infinity, Infinity, Infinity)
  const max = new THREE.Vector3(-Infinity, -Infinity, -Infinity)

  // setFromObject
  const v1 = new THREE.Vector3()
  container.updateMatrixWorld(true)
  container.children.forEach((node) => {
    const geometry = node.geometry
    if (typeof geometry !== 'undefined') {
      if (geometry.isGeometry) {
        const vertices = geometry.vertices
        const vl = vertices.length
        for (let i = 0; i < vl; i++) {
          v1.copy(vertices[i])
          v1.applyMatrix4(node.matrixWorld)
          min.min(v1)
          max.max(v1)
        }
      } else if (geometry.isBufferGeometry) {
        const attribute = geometry.attributes.position
        if (typeof attribute !== 'undefined') {
          let array, offset, stride
          if (attribute.isInterleavedBufferAttribute) {
            array = attribute.data.array
            offset = attribute.offset
            stride = attribute.data.stride
          } else {
            array = attribute.array
            offset = 0
            stride = 3
          }
          const al = array.length
          for (let j = offset; j < al; j += stride) {
            v1.fromArray(array, j)
            v1.applyMatrix4(node.matrixWorld)
            min.min(v1)
            max.max(v1)
          }
        }
      }
    }
  })

  // getSize
  const size = new THREE.Vector3(1, 1, 1)
  size.subVectors(max, min)

  // getCenter
  const center = new THREE.Vector3(1, 1, 1)
  center.addVectors(min, max).multiplyScalar(0.5)

  // revert to (1, 1, 1) if anything isNaN
  if (isNaN(center.x) || isNaN(center.y) || isNaN(center.z)) {
    center.set(1, 1, 1)
  }

  return {
    radius: size.length() * 0.5,
    center: center
  }
}
