import * as THREE from 'three'
import * as Types from './Types'
import histogram from './histogram'

let isLoading = false
export default {
  init,
  get,
  show,
  hide,
  bind,
  update,
  toggle,
  animate,
  destroy,
  attach,
  detach,
  getContainer,
  setIsLoading
}

// Create pie ring container:
const pieRingContainer = new THREE.Group()
pieRingContainer.name = 'pieRingContainer'
// Rotate container so slices grow clockwise from the top:
pieRingContainer.rotateX(Math.PI)
pieRingContainer.rotateZ(Math.PI / -2)

// Pie ring parameters:
let z = 0
const ringColor = '#267C9A'
const ringLoadingColor = '#ff8000'
const ringHoverColor = '#FFFFFF'
const petriRadius = 0.5
const percentPadding = 0.02
const pieRingThickness = 0.02
const pieRingRadius = petriRadius * (1 + percentPadding)

// Pie ring slice geometry segment parameters:
const totalSegments = 1024 // Total segments (adjusts granularity, higher = finer detail)
const spacing = 2 // Number of spacing segments between slices:
const lastSegmentIndex = totalSegments - spacing

// Number of frames for grow animation to complete (adjusts speed, lower = faster):
const frames = 30

let dispatchHandler = null

function init(dispatchCallback) {
  dispatchHandler = dispatchCallback
  return Promise.resolve()
}

function get() {
  return pieRingContainer.children
}

function show() {
  pieRingContainer.visible = true
}

function hide() {
  pieRingContainer.visible = false
}

function attach(object) {
  object.add(pieRingContainer)
  pieRingContainer.position.setZ(9)
}

function detach(object) {
  object.remove(pieRingContainer)
}

function bind(data) {
  const totalSlices = data.length
  let totalHits = 0
  let isLoading = false
  // Calculate total hits based off of how the total buckets cutoff:
  data.forEach((el) => {
    totalHits += el.value
    if (el.type === 'loader' && !isLoading) {
      isLoading = true
    }
  })
  // Initialize draw variables:
  const lastIndex = data.length - 1
  let previousEnd = 0
  // Pointer to current slices:
  let sliceArray = pieRingContainer.children

  // Initialize the slices:
  data.forEach((el, ind) => {
    const nodeName = el.key
    const isLoader = el.type === 'loader'

    const size = Math.ceil(
      (el.value / totalHits) * (totalSegments - totalSlices * spacing)
    )
    const start = ind > 0 ? previousEnd + spacing : 0
    const end = ind < lastIndex ? start + size : lastSegmentIndex
    const slice = isLoader
      ? drawLoaderSlice(el)
      : sliceArray[ind]
      ? sliceArray[ind]
      : drawSlice()
    slice.segmentLength = size
    slice.start = start
    slice.end = end
    slice.startRate = 0
    slice.endRate = Math.ceil(size / frames)
    slice.currentStart = start
    slice.currentEnd = start
    slice.nodeName = nodeName
    slice.visible = true
    slice.nodeData = el
    slice.update = true

    previousEnd = end
  })

  // Hide extra slices:
  if (data.length < pieRingContainer.children.length) {
    pieRingContainer.children.forEach((slice, ind) => {
      if (ind >= data.length && !isLoading) {
        slice.visible = false
      }
    })
  }
  // Return the pie ring container:
  return pieRingContainer
}

// This method is specifically for updating pie ring slice size parameters for real-time live data:
function update(data) {
  // TODO: need some flag to determine whether data passed is for updating the current slices or for a whole new set of
  // TODO: slices in which case some reset functionality will need to be called:
  if (pieRingContainer.children.length === 0) {
    histogram.create(data)
    console.log(
      'Not sure if this is the right function being called: create()',
      'Remove this log if it works..'
    )
  } else {
    // Get necessary data:
    const totalSlices = data.length
    let totalHits = 0
    // Calculate total hits based off of how the total buckets cutoff:
    data.forEach((el, ind) => {
      if (ind < totalSlices) {
        totalHits += el.value
      }
    })
    // Initialize update variables:
    const lastIndex = data.length - 1
    const sliceArray = pieRingContainer.children
    const currentNumberOfSlices = pieRingContainer.children.length
    let previousEnd = 0
    // Initialize slices for update:
    data.forEach((el, ind) => {
      const size = Math.ceil(
        (el.value / totalHits) * (totalSegments - totalSlices * spacing)
      )
      let start = ind > 0 ? previousEnd + spacing : 0
      let end = ind < lastIndex ? start + size : lastSegmentIndex
      start = start > lastSegmentIndex ? lastSegmentIndex : start
      end = end > lastSegmentIndex ? lastSegmentIndex : end
      // Where the magic happens:
      const shouldUpdate = ind < currentNumberOfSlices
      const slice = shouldUpdate ? sliceArray[ind] : drawSlice()
      slice.startRate = shouldUpdate
        ? Math.ceil(Math.abs(start - slice.start) / frames)
        : Math.ceil(Math.abs(lastSegmentIndex + 2 - start) / frames)
      slice.endRate = shouldUpdate
        ? Math.ceil(Math.abs(end - slice.end) / frames)
        : Math.ceil(Math.abs(lastSegmentIndex - end) / frames)
      slice.currentStart = shouldUpdate ? slice.start : lastSegmentIndex + 2
      slice.currentEnd = shouldUpdate ? slice.end : lastSegmentIndex
      slice.start = start
      slice.end = end
      slice.segmentLength = size
      slice.nodeName = el.key
      slice.nodeData = el
      slice.update = true
      slice.visible = true
      previousEnd = end
      // Reset color (in case it was on hover color previous to update):
      slice.material.color.set(ringColor)
    })
    // Hide extra slices:
    if (data.length < pieRingContainer.children.length) {
      pieRingContainer.children.forEach((slice, ind) => {
        if (ind >= data.length) {
          slice.visible = false
        }
      })
    }
  }
  return pieRingContainer
}

// This method toggles pie ring animation - slices will reset to 0 size and grow:
function toggle() {
  pieRingContainer.children.forEach((slice) => {
    slice.startRate = 0
    slice.endRate = Math.ceil(slice.segmentLength / frames)
    slice.currentStart = slice.start
    slice.currentEnd = slice.start
    slice.update = true
  })
}
function animate() {
  pieRingContainer.children.forEach((slice) => {
    if (slice.update) {
      updateSlice(slice)
    } else if (slice.nodeData.type === 'loader') {
      if (slice.updateLoader) {
        updateLoaderSlice(slice)
      }
      if (slice.updateZ) {
        if (z <= 0.15) {
          z = z += 0.01
        }
        slice.rotateZ(z)
      }
    }
  })
}

function destroy() {
  isLoading = false
  pieRingContainer.children.forEach((slice) => {
    slice.geometry.dispose()
    slice.material.dispose()
  })
  pieRingContainer.children.length = 0
}

function getContainer() {
  return pieRingContainer
}

// Private:
function drawSlice() {
  const geometry = customTorusGeometry()
  const material = new THREE.MeshBasicMaterial({
    color: ringColor,
    side: THREE.DoubleSide
  })
  // FOR DEBUGGING if you need to identify a particular slice (gives each slice a random color instead):
  // let material = new THREE.MeshBasicMaterial({color: new THREE.Color(Math.random(), Math.random(), Math.random()), side: THREE.DoubleSide,
  // wireframe: true})
  const slice = new THREE.Mesh(geometry, material)
  slice.name = 'pieRingSlice'
  slice.visible = true
  slice.hittable = true
  // Select/Deselect handler:
  slice.onSelect = function () {
    this.highlight()
    if (dispatchHandler) {
      dispatchHandler({
        type: Types.PIE_RING_ON_HOVER,
        payload: this.nodeData
      })
    }
  }
  slice.onDeselect = function () {
    this.unhighlight()
    if (dispatchHandler) {
      dispatchHandler({
        type: Types.PIE_RING_OFF_HOVER,
        payload: this.nodeData
      })
    }
  }
  // Click handler:
  slice.onClick = function () {
    if (dispatchHandler) {
      dispatchHandler({
        type: Types.PIE_RING_ON_CLICK,
        payload: this.nodeData
      })
    }
  }
  // Highlight/unhighlight setters:
  slice.highlight = function () {
    this.material.color.set(ringHoverColor)
  }
  slice.unhighlight = function () {
    this.material.color.set(ringColor)
  }
  // Add slice to container:
  pieRingContainer.add(slice)
  return slice
  // For ribbon between pie ring and petri node:
  // slice.anglePosition = ((angle / 2) + offset - 90) * MORE.TORAD
  // slice.angleSize = angle * MORE.TORAD
}

function drawLoaderSlice(data) {
  const geometry = customTorusGeometry()

  const material = new THREE.MeshBasicMaterial({
    color: data.color,
    side: THREE.DoubleSide
  })
  // FOR DEBUGGING if you need to identify a particular slice (gives each slice a random color instead):
  // let material = new THREE.MeshBasicMaterial({color: new THREE.Color(Math.random(), Math.random(), Math.random()), side: THREE.DoubleSide,
  // wireframe: true})
  const slice = new THREE.Mesh(geometry, material)
  slice.name = 'pieRingLoaderSlice'
  slice.visible = true
  slice.hittable = false
  slice.updateLoader = true
  slice.updateZ = true
  slice.rotateZ(0)
  slice.rotation.z = 0

  // Add slice to container:
  pieRingContainer.add(slice)
  return slice
  // For ribbon between pie ring and petri node:
  // slice.anglePosition = ((angle / 2) + offset - 90) * MORE.TORAD
  // slice.angleSize = angle * MORE.TORAD
}
function customTorusGeometry() {
  const geo = new THREE.BufferGeometry()
  const innerRadius = pieRingRadius
  const outerRadius = pieRingRadius + pieRingThickness
  const thetaSegments = totalSegments
  const phiSegments = 1
  const thetaStart = 0
  const thetaLength = 2 * Math.PI
  geo.parameters = {
    innerRadius: innerRadius,
    outerRadius: outerRadius,
    thetaSegments: thetaSegments,
    phiSegments: phiSegments,
    thetaStart: thetaStart,
    thetaLength: thetaLength
  }
  // These are used to calculate buffer length:
  const vertexCount = (thetaSegments + 1) * (phiSegments + 1)
  const indexCount = thetaSegments * phiSegments * 2 * 3
  // Buffers:
  const indices = new THREE.BufferAttribute(new Uint16Array(indexCount), 1)
  const vertices = new THREE.BufferAttribute(
    new Float32Array(vertexCount * 3),
    3
  )
  const normals = new THREE.BufferAttribute(
    new Float32Array(vertexCount * 3),
    3
  )
  const uvs = new THREE.BufferAttribute(new Float32Array(vertexCount * 2), 2)
  // Some helper variables:
  let index = 0,
    indexOffset = 0,
    segment
  let radius = innerRadius
  const radiusStep = pieRingThickness
  let j, i
  // Generate vertices, normals and uvs -
  // values are generated from the inside of the ring to the outside:
  for (j = 0; j <= phiSegments; j++) {
    for (i = 0; i <= totalSegments; i++) {
      segment = thetaStart + (i / thetaSegments) * thetaLength
      // Vertex:
      vertices.setXYZ(index, 0, 0, 0)
      // Normal:
      normals.setXYZ(index, 0, 0, 1)
      // UV:
      uvs.setXY(
        index,
        ((radius * Math.cos(segment)) / 0.53 + 1) / 2,
        ((radius * Math.sin(segment)) / 0.53 + 1) / 2
      )
      // Increase index:
      index++
    }
    // Increase the radius for next row of vertices:
    radius += radiusStep
  }
  // Generate indices:
  for (j = 0; j < phiSegments; j++) {
    const thetaSegmentLevel = j * (thetaSegments + 1)
    for (i = 0; i < thetaSegments; i++) {
      segment = i + thetaSegmentLevel
      // Indices:
      const a = segment
      const b = segment + thetaSegments + 1
      const c = segment + thetaSegments + 2
      const d = segment + 1
      // Face one:
      indices.setX(indexOffset++, a)
      indices.setX(indexOffset++, b)
      indices.setX(indexOffset++, c)
      // Face two:
      indices.setX(indexOffset++, a)
      indices.setX(indexOffset++, c)
      indices.setX(indexOffset++, d)
    }
  }
  geo.setIndex(indices)
  geo.setAttribute('position', vertices)
  geo.setAttribute('normal', normals)
  geo.setAttribute('uv', uvs)
  return geo
}

function updateSlice(slice) {
  const vertexPositions = slice.geometry.attributes.position
  const startDirection = slice.currentStart < slice.start ? 1 : -1
  const endDirection = slice.currentEnd < slice.end ? 1 : -1
  let startIndex = slice.currentStart + startDirection * slice.startRate
  startIndex =
    startDirection > 0
      ? Math.min(slice.start, startIndex)
      : Math.max(slice.start, startIndex)
  let endIndex = slice.currentEnd + endDirection * slice.endRate
  endIndex =
    endDirection > 0
      ? Math.min(slice.end, endIndex)
      : Math.max(slice.end, endIndex)
  let index = 0
  let radius = slice.geometry.parameters.innerRadius
  for (let k = 0; k <= 1; k++) {
    for (let i = 0; i <= totalSegments; i++) {
      if (i >= startIndex && i <= endIndex) {
        let segment = (i / totalSegments) * 2 * Math.PI
        vertexPositions.setXYZ(
          index,
          radius * Math.cos(segment),
          radius * Math.sin(segment),
          0
        )
      } else {
        // May have to set undefined instead of 0 to remove strange pixel specks:
        vertexPositions.setXYZ(index, 0, 0, 0)
      }
      index++
    }
    radius += pieRingThickness
  }
  vertexPositions.needsUpdate = true
  // Update bounds for raycasting:
  slice.geometry.computeBoundingBox()
  slice.geometry.computeBoundingSphere()
  // Update current segment indices:
  slice.currentStart = startIndex
  slice.currentEnd = endIndex
  if (startIndex === slice.start && endIndex === slice.end) {
    slice.update = false
  }
}

function updateLoaderSlice(slice) {
  // console.log('updateSlice', slice)
  const vertexPositions = slice.geometry.attributes.position
  const startDirection = slice.currentStart < slice.start ? 1 : -1
  const endDirection = slice.currentEnd < slice.end ? 1 : -1
  let startIndex = slice.currentStart + startDirection * slice.startRate
  startIndex =
    startDirection > 0
      ? Math.min(slice.start, startIndex)
      : Math.max(slice.start, startIndex)
  let endIndex = slice.currentEnd + endDirection * slice.endRate
  endIndex =
    endDirection > 0
      ? Math.min(slice.end, endIndex)
      : Math.max(slice.end, endIndex)
  let index = 0
  let radius = slice.geometry.parameters.innerRadius
  for (let k = 0; k <= 1; k++) {
    for (let i = 0; i <= totalSegments; i++) {
      if (i >= startIndex && i <= endIndex) {
        let segment = (i / totalSegments) * 2 * Math.PI
        vertexPositions.setXYZ(
          index,
          radius * Math.cos(segment),
          radius * Math.sin(segment),
          0
        )
      } else {
        // May have to set undefined instead of 0 to remove strange pixel specks:
        vertexPositions.setXYZ(index, 0, 0, 0)
      }
      index++
    }
    radius += pieRingThickness
  }
  vertexPositions.needsUpdate = true
  // Update bounds for raycasting:
  slice.geometry.computeBoundingBox()
  slice.geometry.computeBoundingSphere()
  // Update current segment indices:
  slice.currentStart = startIndex
  slice.currentEnd = endIndex
  if (startIndex === slice.start && endIndex === slice.end) {
    slice.updateLoader = false
  }
}

function setIsLoading(pickedObject) {
  if (!isLoading) {
    isLoading = true
    z = 0
    destroy()
    show()
    bind([
      { key: 'loader1', type: 'loader', value: 25, color: ringLoadingColor },
      {
        key: 'loader2',
        type: 'loader',
        value: 25,
        color: ringColor
      },
      { key: 'loader3', type: 'loader', value: 25, color: ringLoadingColor },
      {
        key: 'loader4',
        type: 'loader',
        value: 25,
        color: ringColor
      }
    ])
    attach(pickedObject)
  }
}
